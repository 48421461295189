import { ref, Ref, watchEffect } from 'vue';
import { Area } from '@/model/Area';
import { WetlandDatum } from '@/api/wetland';
import { fetchWetlandStatus } from '@/api';

const wetlandStatus = ref<WetlandDatum[]>();
const loaders:{[aoiId:number]: Promise<WetlandDatum[]>} = {};
export default function useWetlandLoader(aoi: Ref<Area | undefined>):Ref<WetlandDatum[] | undefined> {
    const loadData = async (aoiToLoad:Area|undefined) => {
        if (aoiToLoad) {
            if (aoiToLoad && !loaders[aoiToLoad.id]) {
                loaders[aoiToLoad.id] = fetchWetlandStatus(aoiToLoad.id);
            }
            const data = await loaders[aoiToLoad.id];
            wetlandStatus.value = data;
            // console.log('wetlandStatus', data);
        }
    };
    watchEffect(() => loadData(aoi.value));
    return wetlandStatus;
}
