
import { defineComponent, PropType } from 'vue';

type ToggleValue = {
    label: string;
    value: string;
}

export default defineComponent({
    name: 'Toggle',
    props: {
        options: {
            type: Array as PropType<ToggleValue[]>,
            required: true
        },
        selected: {
            type: String,
            default: null
        }
    },
    emits: ['change'],
    setup(props, { emit }) {
        const onToggleClick = (option: string) => {
            emit('change', option);
        };

        return {
            onToggleClick
        };
    }
});
