/* eslint-disable import/no-named-as-default-member */
import abmi from './abmi';
import stub from './stub';

type DataFetchApi = typeof stub;

const checkAbmi: Partial<DataFetchApi> = abmi;
export const fetchAreaOfAnalysis = checkAbmi.fetchAreaOfAnalysis || stub.fetchAreaOfAnalysis;
export const fetchAreasOfInterest = checkAbmi.fetchAreasOfInterest || stub.fetchAreasOfInterest;
export const fetchTaxonomicGroups = checkAbmi.fetchTaxonomicGroups || stub.fetchTaxonomicGroups;
export const fetchAreas = checkAbmi.fetchAreas || stub.fetchAreas;
export const fetchQueriedAreas = checkAbmi.fetchQueriedAreas || stub.fetchQueriedAreas;
export const fetchFootprintTrend = checkAbmi.fetchFootprintTrend || stub.fetchFootprintTrend;
export const fetchFootprintTrendCSV = checkAbmi.fetchFootprintTrendCSV || stub.fetchFootprintTrendCSV;
export const fetchSuitabilityData = checkAbmi.fetchSuitabilityData || stub.fetchSuitabilityData;
export const fetchIntactnessIndex = checkAbmi.fetchIntactnessIndex || stub.fetchIntactnessIndex;
export const fetchFootprintChange = checkAbmi.fetchFootprintChange || stub.fetchFootprintChange;
export const fetchSpeciesInfo = checkAbmi.fetchSpeciesInfo || stub.fetchSpeciesInfo;
export const fetchIntactnessCSV = checkAbmi.fetchIntactnessCSV || stub.fetchIntactnessCSV;
export const fetchWetlandStatus = checkAbmi.fetchWetlandStatus || stub.fetchWetlandStatus;

const fetchData: DataFetchApi = {
    fetchSpeciesInfo,
    fetchFootprintChange,
    fetchAreaOfAnalysis,
    fetchAreasOfInterest,
    fetchTaxonomicGroups,
    fetchAreas,
    fetchQueriedAreas,
    fetchFootprintTrend,
    fetchFootprintTrendCSV,
    fetchSuitabilityData,
    fetchIntactnessIndex,
    fetchWetlandStatus,
    fetchIntactnessCSV,
};

export default fetchData;
