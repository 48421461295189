/**
 * Data Model for Suitability
 *
 * Guild Config:
 * guild image coming from database species_guild.img_src column, (save in db to avoid build)
 */
import { FormatOptions } from '@dha/number-format';
import { fetchSuitabilityData } from '@/api';
import { SuitabilityData, SuitabilityDatum, TaxonomicGroupIO } from '@/api/intactness';
import { LegendItemIO } from '@/api/report';
import { RowFlagIconDatum } from '@/components/RowsFlagNameIcon/types';
import _ from 'lodash';
import { Area } from './Area';
import { getSpeciesNames } from './helpers';
import SuitabilityContent from './report/SuitabilityContent';

/**
  * Wraps Content and Data and provides interface used by UI
  */
export default class SuitabilityModel {
    suitabilityData?: SuitabilityData;
    taxonomicGroups?: Record<string, TaxonomicGroupIO>;
    content: SuitabilityContent;
    constructor(content: SuitabilityContent, taxonomicGroups: Record<string, TaxonomicGroupIO> | undefined) {
        this.content = content;
        this.taxonomicGroups = taxonomicGroups;
    }
    get format(): FormatOptions | undefined {
        return this.content.config.format;
    }
    get flaggedData(): RowFlagIconDatum[] | undefined {
        // if (!this.legend || !this.data) {
        //     return undefined;
        // }
        const taxonomicGroup = this.taxonomicGroups?.[this.content.taxonomy];
        const flags = _.keyBy(this.legend, 'id');
        const flaggedData: RowFlagIconDatum[] = _.map(this.data, (d) => {
            const guildFlags = _.mapValues(flags, (flag) => d.guilds.indexOf(flag.id) >= 0);
            return {
                ...d,
                ...getSpeciesNames(d, taxonomicGroup),
                flags: guildFlags
            };
        });
        return flaggedData;
    }

    get data(): SuitabilityDatum[] | undefined {
        return this.suitabilityData?.data[this.content.taxonomy];
    }
    async load(aoi: Area): Promise<void> {
        (async () => {
            this.suitabilityData = await fetchSuitabilityData(aoi.id, this.content.taxonomy);
        })();
    }

    get legend(): LegendItemIO[] | undefined {
        const guildList = this.suitabilityData?.guildList;
        if (!guildList) {
            return undefined;
        }
        const flags = _.keyBy(guildList, 'id');
        /* DH: get predefined icons from content.legend, and read id from  API content and add to the content.legend object */
        /* return _.map(this.content.legend, (legendItem) => ({
            ...legendItem,
            label: flags[legendItem.id]?.name
        }));
        */

        /* ABMI: get legend from API, and find image, and assign to the legend.
        TODO: add legend icons (as css or json object)
        */
        // const legendConfig = _.keyBy(this.content.legend, 'id');
        return _.map(flags, (legendItem) => ({
            ...legendItem,
            // imageSrc: legendConfig[legendItem.id]?.imageSrc
        }));
    }
}
