
import { defineComponent, computed, inject } from 'vue';
import useApp from '@/composables/state/useApp';

import AppLayout from '@/layouts/AppLayout.vue';
import RegionCategories from '@/modules/areas/RegionCategories.vue';
import SingleLevelRegionCategories from '@/modules/areas/SingleLevelRegionCategories.vue';
import AreaExplorer from '@/modules/areas/AreaExplorer.vue';
import RegionMap from '@/modules/areas/RegionMap.vue';
import Introduction from '@/modules/areas/Introduction.vue';
import SearchHeader from '@/modules/onlinereport/SearchHeader.vue';
// import { Section } from '@/model/report';
import PopUpBox from '@/components/PopUpBox.vue';

export default defineComponent({
    name: 'AreaOfInterest',
    components: {
        AppLayout,
        AreaExplorer,
        RegionCategories,
        SingleLevelRegionCategories,
        RegionMap,
        SearchHeader,
        Introduction,
        PopUpBox,
    },
    setup() {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const screens: any = inject('screens');

        const { state, appInfo, area, togglePopup, showPopup, popupContent, region, viewReport, toggleMenu } = useApp();

        const showIntro = computed(() => !region.value);
        const showTitle = computed(() => screens.w > 768);

        const menuLevel = process.env.VUE_APP_MENU_LEVEL;
        const lg = computed(() => screens.w >= 1024);
        // const showPopup:Ref<boolean> = ref(true);
        const viewReportClicked = () => {
            if (screens.w >= 1024) {
                toggleMenu(true);
            }
            viewReport();
        };
        // const landingContent = computed(() => new Section((appInfo.value?.cmsInfo?.popup ?? { id: 'popup' }), {}));
        const cmsText = computed(() => ({
            title: appInfo.value?.cmsInfo?.title?.title,
            org: appInfo.value?.cmsInfo?.org?.title,
            searchAreaText: appInfo.value?.cmsInfo?.searchAreaText?.description,
            viewReportButton: appInfo.value?.cmsInfo?.viewReportButton?.title,
            selectRegionOnMapText: appInfo.value?.cmsInfo?.selectRegionOnMapText?.description,
        }));

        return { appInfo, menuLevel, state, cmsText, showIntro, area, showPopup, popupContent, togglePopup, viewReportClicked, region, showTitle, lg };
    },

});
