
import { defineComponent, PropType } from 'vue';
import type { Section } from '@/model/report';
import ChapterTitlePage from './ChapterTitlePage.vue';

export default defineComponent({
    name: 'BaseChapter',
    components: {
        ChapterTitlePage
    },
    props: {
        section: {
            type: Object as PropType<Section>,
            default: null,
        }
    },
});
