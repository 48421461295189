import { AreaOfAnalysisIO } from '@/api/aoi';
import { FootprintChangeIO, FootprintCategory } from '@/api/footprint';
import _ from 'lodash';
import { Section } from './report';
import { TemplateProps } from './helpers';
import SectionContentModel from './SectionContent';

// const areaText = computed(() => templateMarkdown(props.section.description, templateProps.value));

export default class FootprintStatusModel extends SectionContentModel {
    // Total Human Footprint Change, includes latest status data
    footprintData?: FootprintChangeIO;
    footprintByCategory?: { [K in FootprintCategory]?: FootprintChangeIO};
    aoiData?: AreaOfAnalysisIO;
    content: Section;
    constructor(content: Section) {
        super(content);
        this.content = content;
    }
    load(aoiData: AreaOfAnalysisIO, footprintData: FootprintChangeIO[]):void {
        this.footprintData = _.find(footprintData, (d) => d.categoryId === 1);
        this.footprintByCategory = _.keyBy(footprintData, 'id');
        this.aoiData = aoiData;
    }
    get templateProps(): TemplateProps {
        return { ...this.footprintData, ...this.aoiData };
    }
}
