
import formatter, { FormatOptions } from '@dha/number-format';
import { computed, defineComponent, PropType, ref, watchEffect, watch } from 'vue';
import observeResize from '@/composables/util/observeResize';
import Tooltip from '@/components/Tooltip.vue';
import { FootprintCategoryIO } from '@/api/report';
import { draw, StackedChartDataItem } from './draw';

type HoverPoint = StackedChartDataItem & {
    X: number,
    Y: number
};

export default defineComponent({
    name: 'StackedBarChart',
    components: {
        Tooltip
    },
    props: {
        data: {
            type: Array as PropType<StackedChartDataItem[]>,
            default: () => []
        },
        subData: {
            type: Array as PropType<StackedChartDataItem[]>,
            default: () => []
        },
        categories: {
            type: Object as PropType<Record<string, FootprintCategoryIO>>,
            default: null
        },
        format: {
            type: Object as PropType<FormatOptions>,
            default: null
        }
    },
    setup(props) {
        const container = ref<HTMLDivElement>();
        const svg = ref<SVGElement>();
        const tooltip = ref<HTMLDivElement>();
        const svgHeight = 100; // stack bar has a fixed height.
        // setup resize observers/computed sizes
        const {
            dimensions
        } = observeResize(container);

        const svgWidth = computed(() => dimensions.value.width);

        // setup tooltip
        const hoveredPoint = ref<HoverPoint>();
        const onMouseOver = function (d: MouseEvent, i: StackedChartDataItem): void {
            const containerBox = container.value?.getBoundingClientRect();
            const targetBox = (d.target as HTMLElement).getBoundingClientRect();

            if (!containerBox) {
                hoveredPoint.value = undefined;
                return;
            }

            hoveredPoint.value = {
                ...i,
                X: targetBox.x + (targetBox.width / 2) - containerBox.x,
                Y: targetBox.y
            };
        };
        const tooltipRow = computed(() => (hoveredPoint.value ? {
            label: hoveredPoint.value.title,
            value: hoveredPoint.value.value,
            description: hoveredPoint.value.description,
            format: props.format
        } : null));

        const onMouseOut = function (): void {
            hoveredPoint.value = undefined;
        };

        // combine main category and Forestry Net together, put forestry net next to the forestry
        const combinedData = computed(() => {
            let result:StackedChartDataItem[] = props.data;
            if (props.subData) {
                const index = props.data.findIndex(x => x.key === 'forestry');
                if (index !== -1) {
                    const wrapData = [...props.data];
                    wrapData.splice(index + 1, 0, props.subData[0]); // cheat it here, only one forestry net here
                    result = wrapData;
                    // return wrapData.filter(d => d.value > 0);
                }
            }
            const finalResult = result.filter(d => d?.value > 0).map(d => ({
                ...d,
                formattedValue: d?.value != null && props.format ? formatter(d.value, props.format) : d.value.toFixed(2)
            }));
            return finalResult;
        });
        // draw on data update
        watchEffect(() => {
            draw(
                svg.value,
                svgWidth.value,
                props.data,
                props.subData,
                onMouseOver,
                onMouseOut,
                props.categories,
                props.format,
            );
        }, { flush: 'post' });

        watch([tooltip], (t) => {
            const containerBox = container.value?.getBoundingClientRect();
            const tooltipBox = tooltip.value?.getBoundingClientRect();
            if (!t || !tooltipBox || !containerBox || !hoveredPoint.value) {
                return;
            }

            if (tooltipBox.x + tooltipBox.width > containerBox.x + containerBox.width) {
                hoveredPoint.value = {
                    ...hoveredPoint.value,
                    X: containerBox.width - (tooltipBox.width / 2),
                };
            } else if (tooltipBox.x < containerBox.x) {
                hoveredPoint.value = {
                    ...hoveredPoint.value,
                    X: 0,
                };
            }
        });

        return {
            svgWidth,
            svgHeight,
            container,
            svg,
            tooltip,
            hoveredPoint,
            tooltipRow,
            combinedData
        };
    },
    methods: {
        formatData(num: number): string {
            return formatter(num, this.format);
        }
    }
});
