import { ref, Ref, watchEffect } from 'vue';
import { Area } from '@/model/Area';
import { fetchFootprintChange } from '@/api';
import { FootprintChangeIO } from '@/api/footprint';

const footprintChanges = ref<FootprintChangeIO[]>();
const loaders:{[aoiId:number]: Promise<FootprintChangeIO[]>} = {};
export default function useFootprintChangeLoader(aoi: Ref<Area | undefined>):Ref<FootprintChangeIO[] | undefined> {
    const loadData = async (aoiToLoad:Area|undefined) => {
        if (aoiToLoad) {
            if (aoiToLoad && !loaders[aoiToLoad.id]) {
                loaders[aoiToLoad.id] = fetchFootprintChange(aoiToLoad.id);
            }
            const data = await loaders[aoiToLoad.id];
            footprintChanges.value = data;
        }
    };
    watchEffect(() => loadData(aoi.value));
    return footprintChanges;
}
