import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-636e33fe"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "species-callout-section content-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionHeader = _resolveComponent("SectionHeader")!
  const _component_species_callout_slider = _resolveComponent("species-callout-slider")!

  return (_ctx.speciesCallout?.hasSpeciesInfo)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_SectionHeader, {
          section: _ctx.speciesCallout,
          "show-title": false
        }, null, 8, ["section"]),
        (_ctx.speciesCallout?.speciesInfo)
          ? (_openBlock(), _createBlock(_component_species_callout_slider, {
              key: 0,
              "species-info": _ctx.speciesCallout?.speciesInfo,
              category: _ctx.speciesCallout?.category
            }, null, 8, ["species-info", "category"]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}