
import { defineComponent, PropType, computed } from 'vue';

import useApp from '@/composables/state/useApp';

import type { Section } from '@/model/report';

import useGeneralRegion from '@/composables/state/report/useGeneralRegion';
import SectionHeader from './SectionHeader.vue';

export default defineComponent({
    name: 'CitationSection',
    components: {
        SectionHeader
    },
    props: {
        section: {
            type: Object as PropType<Section>,
            default: null,
        }
    },
    setup(props) {
        const { aoi } = useApp();

        /* this is not reactive if not witin the computed */
        const content = computed(() => useGeneralRegion(aoi, props.section));
        return {
            content
        };
    }
});
