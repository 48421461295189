import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-57733740"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["info-icon", { invisible: _ctx.hide }])
    }, [
      (_ctx.flag)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: _ctx.flag.imageSrc
          }, null, 8, _hoisted_1))
        : _createCommentVNode("", true)
    ], 2),
    _createElementVNode("div", null, [
      (_ctx.datum.name)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["row-name is-title", { invisible: _ctx.hide }])
          }, _toDisplayString(_ctx.datum.name), 3))
        : _createCommentVNode("", true),
      (_ctx.datum.subtitle)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(["row-subtitle", {
                'is-title': !_ctx.datum.name,
                invisible: _ctx.hide
            }])
          }, _toDisplayString(_ctx.datum.subtitle.replaceAll('/', ' /')), 3))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("img", {
      class: _normalizeClass(["row-image", { invisible: _ctx.hide }]),
      src: _ctx.datum.imageSrc
    }, null, 10, _hoisted_2),
    (_ctx.formattedDatumValue)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["row-value", {
            invisible: _ctx.hide
        }])
        }, _toDisplayString(_ctx.formattedDatumValue), 3))
      : _createCommentVNode("", true)
  ], 64))
}