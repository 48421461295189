// import { SuitabilitySection } from '@/model/ABMIReport';
import type FootprintTrendsContent from '@/model/report/FootprintTrendsContent';
import FootprintTrendsModel from '@/model/FootprintTrends';
import FootprintChangeModel from '@/model/FootprintChange';

import { watchEffect, computed, Ref, reactive } from 'vue';
import { Area } from '@/model/Area';
import { FootprintChangeIO } from '@/api/footprint';
import useFootprintChangeLoader from '../loaders/useFootprintChangeLoader';

export default function useFootprintTrends(aoi: Ref<Area | undefined>, content: FootprintTrendsContent):{ model: FootprintTrendsModel, infoLabels: Ref<Record<string, Record<string, string>>| undefined>} {
    const trends = reactive<FootprintTrendsModel>(new FootprintTrendsModel(content));
    const change = reactive<FootprintChangeModel>(new FootprintChangeModel(content.config));
    const footprintData = useFootprintChangeLoader(aoi);
    const loadFootprintTrendsModel = async (aoiToLoad:Area|undefined, footprintToLoad: FootprintChangeIO[]|undefined) => {
        if (aoiToLoad && footprintToLoad) {
            (async () => { await change.load(footprintToLoad); })();
            (async () => { await trends.load(aoiToLoad); })();
        }
    };
    watchEffect(() => loadFootprintTrendsModel(aoi.value, footprintData.value));
    const infoLabels = computed(() => change.infoLabels ?? {});
    return {
        model: trends,
        infoLabels
    };
}
