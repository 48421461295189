import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0486ef56"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.section)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        id: _ctx.section.id,
        ref: "scrollEl",
        class: "report-section"
      }, [
        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.moduleForSection(_ctx.section)), { section: _ctx.section }, null, 8, ["section"]))
      ], 8, _hoisted_1))
    : _createCommentVNode("", true)
}