
import { ref, defineComponent, PropType } from 'vue';
import type { Section } from '@/model/report';

export default defineComponent({
    name: 'ReportSection',
    props: {
        section: {
            type: Object as PropType<Section>,
            default: null,
        },
        showDescription: {
            type: Boolean,
            default: true,
        },
        showAlternativeDescription: {
            type: Boolean,
            default: false,
        },
        showTitle: {
            type: Boolean,
            default: true,
        },
        showReadMore: {
            type: Boolean,
            default: false,
        },
        showBottomDivider: {
            type: Boolean,
            default: false,
        },
        isChapterTitle: {
            type: Boolean,
            default: false,
        }
    },
    setup() {
        const showAllText = ref(false);
        return { showAllText };
    }
});
