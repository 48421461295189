import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, mergeProps as _mergeProps, withCtx as _withCtx, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-10a754a6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "footprint-trend-section" }
const _hoisted_2 = { class: "section-header content-container mt-10" }
const _hoisted_3 = { class: "mb-2" }
const _hoisted_4 = {
  key: 0,
  class: "toggle-container content-container"
}
const _hoisted_5 = { class: "chart-title" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "flex" }
const _hoisted_8 = { class: "title-title" }
const _hoisted_9 = {
  key: 0,
  class: "title-subtitle"
}
const _hoisted_10 = { class: "tooltip-icon" }
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = { style: { height: '250px' } }
const _hoisted_13 = { class: "chart-title" }
const _hoisted_14 = ["src"]
const _hoisted_15 = { class: "flex" }
const _hoisted_16 = { class: "title-title" }
const _hoisted_17 = {
  key: 0,
  class: "title-subtitle"
}
const _hoisted_18 = { class: "tooltip-icon" }
const _hoisted_19 = ["innerHTML"]
const _hoisted_20 = ["innerHTML"]
const _hoisted_21 = { class: "content-container downloadbutton-container" }
const _hoisted_22 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_toggle = _resolveComponent("toggle")!
  const _component_tooltip = _resolveComponent("tooltip")!
  const _component_line_chart = _resolveComponent("line-chart")!
  const _component_grid_layout = _resolveComponent("grid-layout")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", null, _toDisplayString(_ctx.section.trendsTitle) + ": " + _toDisplayString(_ctx.yearRange), 1),
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.section?.config?.hoverHint?.trendLine), 1)
    ]),
    false
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_toggle, {
            options: _ctx.toggleOptions,
            selected: _ctx.selectedToggle,
            onChange: _ctx.onToggleChange
          }, null, 8, ["options", "selected", "onChange"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.totalTrendData)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "total-chart",
          style: _normalizeStyle({ width: _ctx.totalWidth })
        }, [
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("img", {
                class: "title-icon",
                src: require(`@/assets/img/stackedbar/${_ctx.categories[_ctx.totalTrendData.key].icon}`)
              }, null, 8, _hoisted_6),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.totalTrendData.name), 1),
                (_ctx.totalTrendSubtitle)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.totalTrendSubtitle), 1))
                  : _createCommentVNode("", true),
                _withDirectives(_createElementVNode("span", _hoisted_10, null, 512), [
                  [_directive_tooltip, { html: true, content: (_ctx.categories[_ctx.totalTrendData.key].description) }]
                ])
              ])
            ]),
            (_ctx.infoLabels[_ctx.totalTrendData.key])
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "chart-info cms-text",
                  innerHTML: _ctx.infoLabels[_ctx.totalTrendData.key][_ctx.infoLabelsKey]?? ''
                }, null, 8, _hoisted_11))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_line_chart, {
              class: "pb-10",
              data: [_ctx.totalTrendData.values],
              styles: _ctx.styles,
              "show-area": true,
              "right-margin": 0,
              "x-format": `%Y`,
              "y-format": _ctx.categories[_ctx.totalTrendData.key]?.format
            }, {
              tooltip: _withCtx((tooltipProps) => [
                _createVNode(_component_tooltip, _mergeProps(tooltipProps, {
                  format: _ctx.categories[_ctx.totalTrendData.key]?.format
                }), null, 16, ["format"])
              ]),
              _: 1
            }, 8, ["data", "styles", "y-format"])
          ])
        ], 4))
      : _createCommentVNode("", true),
    _createVNode(_component_grid_layout, {
      class: "content-container",
      style: _normalizeStyle({ height: _ctx.totalHeight }),
      columns: _ctx.numDesktopColumns,
      "mobile-columns": 1,
      flow: 'column',
      "grid-template-rows": `repeat(${_ctx.numLogicalRows}, min-content minmax(0, 1fr)`
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.trendData, (data, i) => {
          return (_openBlock(), _createElementBlock(_Fragment, {
            key: data[0].key
          }, [
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_13, [
                (_ctx.categories[data[0]?.key]?.icon)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      class: "title-icon",
                      src: require(`@/assets/img/stackedbar/${_ctx.categories[data[0].key].icon}`)
                    }, null, 8, _hoisted_14))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("div", _hoisted_16, _toDisplayString(data[0].name), 1),
                  (_ctx.trendSubtitles)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_17, _toDisplayString(_ctx.trendSubtitles[i]), 1))
                    : _createCommentVNode("", true),
                  _withDirectives(_createElementVNode("span", _hoisted_18, null, 512), [
                    [_directive_tooltip, { html: true, content: (_ctx.categories[data[0]?.key].subcategories != null ?
                                    `<p><strong>${ _ctx.categories[data[0].key].name }</strong>: ${_ctx.categories[data[0]?.key].description}
                                    <br/>${_ctx.categories[data[0]?.key]?.subcategories?.map(c =>
                                    '<strong>' + _ctx.categories[c].name + '</strong>: ' + _ctx.categories[c].description)?.join('<br/')}
                                    </p>
                                    `
                                    :
                                    _ctx.categories[data[0]?.key].description) }]
                  ])
                ])
              ]),
              (_ctx.infoLabels[data[0].key])
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "chart-info cms-text",
                    innerHTML: _ctx.infoLabels[data[0].key][_ctx.infoLabelsKey] ?? ''
                  }, null, 8, _hoisted_19))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories[data[0].key].subcategories, (k, index) => {
                return (_openBlock(), _createElementBlock(_Fragment, null, [
                  (_ctx.infoLabels[k])
                    ? (_openBlock(), _createElementBlock("div", {
                        key: index,
                        class: "chart-info cms-text -mt-2",
                        innerHTML: _ctx.infoLabels[k][_ctx.infoLabelsKey] ?? ''
                      }, null, 8, _hoisted_20))
                    : _createCommentVNode("", true)
                ], 64))
              }), 256))
            ]),
            _createElementVNode("div", null, [
              _createVNode(_component_line_chart, {
                class: "pb-10",
                data: data.map(d => d.values),
                styles: _ctx.styles,
                "show-area": true,
                "y-domain": _ctx.fullDomain,
                "x-format": `%Y`,
                "y-format": _ctx.categories[data[0].key]?.format
              }, {
                tooltip: _withCtx((tooltipProps) => [
                  _createVNode(_component_tooltip, _mergeProps(tooltipProps, {
                    format: _ctx.categories[data[0].key]?.format
                  }), null, 16, ["format"])
                ]),
                _: 2
              }, 1032, ["data", "styles", "y-domain", "y-format"])
            ])
          ], 64))
        }), 128))
      ]),
      _: 1
    }, 8, ["style", "columns", "grid-template-rows"]),
    _createElementVNode("div", _hoisted_21, [
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.downloadCSVData && _ctx.downloadCSVData(...args)))
      }, [
        _createElementVNode("img", {
          src: require(`@/assets/icons/download-csv.svg`)
        }, null, 8, _hoisted_22),
        _createTextVNode(" Download Data ")
      ])
    ])
  ]))
}