
import _ from 'lodash';
import { computed, defineComponent, inject, PropType, ref } from 'vue';
import getTailwindColor from '@/getTailwindColor';
import useFootprintTrends from '@/composables/state/report/useFootprintTrends';
import { FootprintTrendsContent } from '@/model/report';
import StackedBarChart from '@/components/StackedBarChart/StackedBarChart.vue';
import PieChart from '@/components/PieChart/PieChart.vue';
import useApp from '@/composables/state/useApp';
import SectionHeader from './SectionHeader.vue';

// reorder data items for inserting into grid in column order instead of row order
function reorder<T>(arr: T[], cols: number) {
    const rows = Math.ceil(arr.length / cols);
    const out = [];
    for (const i of _.range(0, arr.length)) {
        const idx = (cols * (i % (arr.length / cols))) + Math.floor(i / rows);
        out.push(arr[idx]);
    }
    return out;
}

export default defineComponent({
    name: 'FootprintTrendSection',
    components: {
        SectionHeader,
        StackedBarChart,
        PieChart
    },
    props: {
        section: {
            type: Object as PropType<FootprintTrendsContent>,
            required: true,
        }
    },
    setup(props) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any

        const templateItems = ref(2); // 2 divs - titles and chart

        const { aoi } = useApp();
        const model = computed(() => useFootprintTrends(aoi, props.section).model);
        const year = computed(() => model.value.year);
        const fullDomain = computed(() => model.value.fullDomain);
        const stackFormat = computed(() => model.value.format);

        const totalFootprint = computed(() => model.value.totalFootprint);
        const latestSubCategoryData = computed(() => model.value.latestSubCategoryData);
        const latestMainCategoryData = computed(() => model.value.latestMainCategoryData);

        const categories = computed(() => model.value.categories);
        const styles = computed(() => model.value.lineStyles);
        const highlightColor = getTailwindColor(aoi?.value?.regionTypeCode ?? 'green', aoi?.value?.regionTypeCode ? 'main' : 950);
        const pieChartData = computed(() => totalFootprint.value && { color: highlightColor, value: totalFootprint.value });

        return {
            sectionContent: model,
            templateItems,

            aoi,

            year,
            totalFootprint,
            latestSubCategoryData,
            latestMainCategoryData,

            pieChartData,
            styles,

            categories,
            fullDomain,
            stackFormat,

        };
    }
});
