import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-259c54e8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "container",
  class: "pie-chart-wrapper flex justify-center items-center"
}
const _hoisted_2 = ["width", "height"]
const _hoisted_3 = { id: "myClip" }
const _hoisted_4 = ["cx", "cy", "r"]
const _hoisted_5 = ["height", "width", "xlink:href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createElementBlock("svg", {
      ref: "svg",
      width: _ctx.svgSideLength,
      height: _ctx.svgSideLength
    }, [
      _createElementVNode("clipPath", _hoisted_3, [
        _createElementVNode("circle", {
          cx: _ctx.cx,
          cy: _ctx.cy,
          r: _ctx.radius
        }, null, 8, _hoisted_4)
      ]),
      _createElementVNode("image", {
        "clip-path": "url(#myClip)",
        height: _ctx.svgSideLength,
        width: _ctx.svgSideLength,
        "xlink:href": require(`@/assets/img/${_ctx.bgImage}`)
      }, null, 8, _hoisted_5)
    ], 8, _hoisted_2))
  ], 512))
}