
import { computed, defineComponent } from 'vue';
import useApp from '@/composables/state/useApp';
import AcknowledgementsSection from '../report/AcknowledgementsSection.vue';

export default defineComponent({
    name: 'Introduction',
    components: { AcknowledgementsSection },
    setup() {
        const { appInfo, toggleMenu, toggleAreaExplorer } = useApp();

        toggleMenu(false);
        toggleAreaExplorer(false);
        const sponsors = computed(() => [{
            logos: [{
                src: 'innotech_alberta_horizontal.png',
                url: 'https://albertainnovates.ca/',
            },
            {
                src: 'ua.png',
                url: 'https://ualberta.ca/',
            },
            {
                src: 'ucalgary.jpg',
                url: 'https://ucalgary.ca/',
            }
            ],
            header: appInfo.value?.cmsInfo?.partner.description },
        { logos: [
        //     {
        //     src: 'goa.png',
        //     url: 'https://www.alberta.ca/',
        // },
        ],
        header: appInfo.value?.cmsInfo?.sponsor.description
        }
        ]);

        const cmsText = computed(() => ({
            title: appInfo.value?.cmsInfo?.title?.title,
            org: appInfo.value?.cmsInfo?.org?.title,
            welcome: appInfo.value?.cmsInfo?.welcomeTitle?.title,
            generateReport: appInfo.value?.cmsInfo?.generateReportTitle?.title,
            getStartText: appInfo.value?.cmsInfo?.getStartText?.title,
            getStartDescription: appInfo.value?.cmsInfo?.getStartText?.description,
        }));
        return { appInfo, cmsText, sponsors };
    }
});
