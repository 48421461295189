
import { computed, defineComponent } from 'vue';
import TreeMenu from '@/components/TreeMenu.vue';
import useApp from '@/composables/state/useApp';
import useReport from '@/composables/state/report/useReport';
import TermsLinks from '../general/TermsLinks.vue';

export interface Section {
    hideFromMenu: boolean,
    sections?: Section[]
}

export default defineComponent({
    name: 'ReportMenu',
    components: {
        TreeMenu,
        TermsLinks
    },
    props: {
        togglePopup: {
            type: Function, default: null
        },
    },
    setup() {
        const { viewAreas, aoi, isMenuOpen, toggleMenu } = useApp();
        const { report, section, selectSection } = useReport(aoi);

        const chooseNewArea = () => {
            toggleMenu(false);
            viewAreas();
        };

        const regionCss = computed(
            () => ((aoi.value && aoi.value.regionTypeCode) ? `bg-${aoi.value?.regionTypeCode}-main` : 'bg-default-main')
        );
        return { report, section, selectSection, chooseNewArea, isMenuOpen, regionCss };
    }
});
