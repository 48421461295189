import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-09a33bbc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title-content z-20 relative" }
const _hoisted_2 = { class: "titles content-container items-start" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: "report-title-page",
    class: "report-title-page",
    style: _normalizeStyle({
            background: `url(${_ctx.bannerImg})`
        })
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          class: _normalizeClass(["greater-region uppercase font-semibold text-s text-white", [_ctx.regionCss]])
        }, _toDisplayString(_ctx.report.regionType), 3),
        _createElementVNode("h1", null, _toDisplayString(_ctx.report.area), 1),
        _createElementVNode("h5", null, _toDisplayString(_ctx.report.subtitle), 1)
      ])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["photo-credit", [_ctx.regionCss]])
    }, _toDisplayString(_ctx.imageCredit), 3)
  ], 4))
}