
import { defineComponent, computed, inject } from 'vue';

import useApp from '@/composables/state/useApp';
import useRegionMap, { useProvinceMap } from '@/composables/state/areas/useRegionMap';
import { ContentKeys } from '@/api/report';
import RegionMapSvg from '@/components/RegionMapSVG/RegionMapSVG.vue';
import { Feature } from 'geojson';

const OilSandsRegionId = 18;
const OilSandsAreaId = 64;

export default defineComponent({
    name: 'RegionMap',
    components: {
        RegionMapSvg,
    },
    setup() {
        const { region, area, regionAreas, appInfo, selectArea } = useApp();
        const { provinceMap } = useProvinceMap();
        const { regionMap } = useRegionMap(region, area);
        const areaSections = computed(() => area.value?.sections.filter((s) => s.isAvailable) || false);

        const defaultFillColor = '#eeeeee';
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const screens: any = inject('screens');

        const svgWidth = computed(() => {
            if (screens.w < 768) {
                return screens.w - 75;
            }

            return 390;
        });

        const svgHeight = computed(() => {
            if (screens.w < 768) {
                return (screens.w - 75) * 0.898;
            }

            return 350;
        });

        const onMapAreaClick = function (e: never, d: Feature) {
            const foundArea = regionAreas.value?.find((i) => i.aoiId === d.properties?.id);
            if (foundArea) {
                selectArea(foundArea);
            }
        };

        const provinceFeatures = computed(() => provinceMap.value?.regionMap || undefined);
        const regionFeatures = computed(() => {
            // Hackyness for Oil Sands Region
            // it's data is not structured the same way as other subregioned regions
            // removes all other areas from the features array to only draw the main one
            if (regionMap.value?.region.id === OilSandsRegionId && area.value?.aoiId === OilSandsAreaId) {
                return { ...regionMap.value?.regionMap, features: [regionMap.value.regionMap?.features[0]] };
            }
            return regionMap.value?.regionMap || undefined;
        });
        const subregionFeatures = computed(() => (area.value?.subRegion && area.value.id !== area.value.subRegion.id ? regionMap.value?.subregionMap : undefined));
        const areaFeatures = computed(() => (area ? regionMap.value?.areaMap : undefined));

        const sectionKeyToTitle = (key: string): string => {
            const cmsKey = (key === 'intactness') ? 'overallIntactness' : key;
            if (appInfo.value?.cmsInfo) {
                const cmsSection = appInfo.value?.cmsInfo[cmsKey as ContentKeys];
                return cmsSection?.title || key;
            }
            return key;
        };

        return {
            region,
            area,
            areaSections,
            svgWidth,
            svgHeight,
            provinceFeatures,
            subregionFeatures,
            regionFeatures,
            areaFeatures,
            defaultFillColor,
            onMapAreaClick,
            sectionKeyToTitle
        };
    },
});
