import { reactive, Ref, watchEffect } from 'vue';
import { SpeciesCalloutContent } from '@/model/report';
import { Area } from '@/model/Area';
// import SpeciesCalloutModel from '@/model/SpeciesCallout';
import { IntactnessDatum } from '@/api/intactness';
import TaxnomicSpeciesCalloutsModel from '@/model/TaxonomicSpeciesCallouts';
import useIntactnessLoader from '../loaders/useIntactnessLoader';

/**
 * Builds the Model object from the given content using data for the given area.
 * @param aoi The area selected to load data for
 * @param content The section report content
 * @returns A reactive IntactnessModel object for the vue template to use.
 */
export default function useSpeciesCallout(aoi: Ref<Area | undefined>, content: SpeciesCalloutContent):{intactness: any, speciesCallout:TaxnomicSpeciesCalloutsModel} {
    const model = reactive<TaxnomicSpeciesCalloutsModel>(new TaxnomicSpeciesCalloutsModel(content));
    const intactnessData = useIntactnessLoader(aoi);
    const loadIntactnessData = async (dataToLoad: (IntactnessDatum|undefined)[]) => {
        if (dataToLoad) {
            await model.load(dataToLoad);
        }
    };
    // watch(intactnessData, () => {
    //     console.log('load spp call out', intactnessData.value);
    //     loadIntactnessData(intactnessData.value);
    // });
    watchEffect(() => loadIntactnessData(intactnessData.value));

    return {
        speciesCallout: model,
        intactness: intactnessData
    };
}
