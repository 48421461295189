import { reactive, Ref, watchEffect } from 'vue';
import { Section } from '@/model/report';
import { Area } from '@/model/Area';
import AreaOfInterestModel from '@/model/AreaOfInterest';
import { AreaOfAnalysisIO } from '@/api/aoi';
import useAOILoader from '../loaders/useAOILoader';

/**
 * Builds the Model object from the given content using data for the given area.
 * @param aoi The area selected to load data for
 * @param sectionIo The section report content
 * @returns A reactive Model with templated Area of interest content
 */
export default function useAreaOfAnalysis(aoi: Ref<Area | undefined>, content: Section):AreaOfInterestModel {
    const model = reactive<AreaOfInterestModel>(new AreaOfInterestModel(content));
    const aoiData = useAOILoader(aoi);
    const loadAOIData = async (aoiDataToLoad:AreaOfAnalysisIO|undefined) => {
        if (aoiDataToLoad) {
            model.load(aoiDataToLoad);
        }
    };
    watchEffect(() => loadAOIData(aoiData.value));
    return model;
}
