import { ContentIO, WetlandConfigIO } from '@/api/report';

import { Section } from './Content';

export default class WetlandMappedContent extends Section {
    config: WetlandConfigIO;
    constructor(contentIo: ContentIO, config: WetlandConfigIO) {
        super(contentIo, config);
        this.config = config;
    }
}
