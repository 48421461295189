import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-37819f70"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "grey text-right pr-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", {
      id: "scrollToTop",
      alt: "click to go to top of the page",
      class: _normalizeClass(["fixed right-0 bottom-0 g-transparent z-20 cursor-pointer mdi mdi-arrow-up", [_ctx.regionCss]]),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.moveToTop && _ctx.moveToTop(...args)))
    }, "Back to Top", 2)
  ]))
}