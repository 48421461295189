
import { defineComponent, PropType, computed, inject } from 'vue';

import useApp from '@/composables/state/useApp';
import useRegionMap, { useProvinceMap } from '@/composables/state/areas/useRegionMap';
import useAreaOfAnalysis from '@/composables/state/report/useAreaOfAnalysis';

import type { AreaOfAnalysisContent } from '@/model/report';

import RegionMapSvg from '@/components/RegionMapSVG/RegionMapSVG.vue';
import getTailwindColor from '@/getTailwindColor';
// import useABMIMap from '@/composables/state/report/useABMIMap';
import { ABMIMap } from '@/vendor/abmi-map';
import useABMIMap from '@/composables/state/report/useABMIMap';
import SectionHeader from './SectionHeader.vue';

export default defineComponent({
    name: 'AreaOfAnalysis',
    components: {
        ABMIMap,
        SectionHeader,
        RegionMapSvg
    },
    props: {
        section: {
            type: Object as PropType<AreaOfAnalysisContent>,
            default: null,
        }
    },
    setup(props) {
        const { isMenuOpen, region, aoi: area, isMobile } = useApp();
        const { provinceMap } = useProvinceMap();
        const { regionMap } = useRegionMap(region, area);
        /* this is not reactive if not witin the computed */
        const areaContent = computed(() => useAreaOfAnalysis(area, props.section));
        // const areaContent = useAreaOfAnalysis(area, props.section);
        const highlightColor = getTailwindColor(area?.value?.regionTypeCode ?? 'green', area?.value?.regionTypeCode ? 'main' : 950);

        const provinceFeatures = computed(() => provinceMap.value?.regionMap || undefined);
        const regionFeatures = computed(() => regionMap.value?.regionMap || undefined);
        const subregionFeatures = computed(() => (area.value?.subRegion && area.value.id !== area.value.subRegion.id ? regionMap.value?.subregionMap : undefined));
        const areaFeatures = computed(() => (area ? regionMap.value?.areaMap : undefined));

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const screens: any = inject('screens');
        const svgWidth = computed(() => {
            if (screens.w > 1440) {
                return 120;
            }
            if (screens.w >= 1024) { // when < 1024 show in full width, > 1024 side by side with intro text
                if (isMenuOpen) {
                    return Math.min((screens.w - 514) * 0.25, 120);
                }
                return Math.min((screens.w - 164) * 0.25, 120);
            }
            return 150;
        });

        const mapWidth = computed(() => {
            if (screens.w > 1440) {
                return 400;
            }
            if (screens.w >= 1024) { // when < 1024 show in full width, > 1024 side by side with intro text
                if (isMenuOpen) {
                    return Math.min((screens.w - 514) * 0.75, 300);
                }
                return Math.min((screens.w - 164) * 0.75, 300);
            }
            return 350;
        });

        const svgHeight = computed(() => {
            if (screens.w > 540) {
                return 180;
            }
            return 162;
        });

        const { mapData, mapInfo } = useABMIMap(area, 'abmi-site');

        return {
            svgWidth,
            svgHeight,
            provinceFeatures,
            regionFeatures,
            subregionFeatures,
            areaFeatures,
            highlightColor,
            area,
            areaContent,
            mapData,
            mapInfo,
            mapWidth,
            isMobile
        };
    }
});
