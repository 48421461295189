import { ContentIO, FootprintConfigIO } from '@/api/report';

import { Section } from './Content';

export default class FootprintStatusContent extends Section {
    config: FootprintConfigIO;
    // trendsTitle: string;
    constructor(contentIo: ContentIO, config: FootprintConfigIO, trendsContentIo?: ContentIO) {
        super(contentIo, config);
        this.config = config;
        // this.title = trendsContentIo?.title || trendsContentIo?.id || '-';
    }
}
