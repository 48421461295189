import { ContentIO, AreaOfAnalysisConfigIO } from '@/api/report';

import { Section } from './Content';

export default class AreaOfAnalysisContent extends Section {
    config:AreaOfAnalysisConfigIO;
    constructor(contentIo:ContentIO, config:AreaOfAnalysisConfigIO) {
        super(contentIo, config);
        this.config = config;
    }
}
