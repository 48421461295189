import { Section } from './report';
import { TemplateProps } from './helpers';
import SectionContentModel from './SectionContent';
import { Area } from './Area';
// const areaText = computed(() => templateMarkdown(props.section.description, templateProps.value));
export const encodeAOIName = (aoiName:string | undefined):string => {
    if (!aoiName) return '';
    return aoiName.replaceAll('<', '&lts;').replaceAll('>', '&gt;').replaceAll('/', '@').replaceAll('-', '+')
        .replaceAll(' ', '-')
        .toLowerCase();
};

export default class GenearlRegionModel extends SectionContentModel {
    data?: Area;
    content: Section;
    constructor(content: Section) {
        super(content);
        this.content = content;
    }
    load(data: Area):void {
        this.data = data;
    }
    get templateProps(): TemplateProps {
        return { aoiName: this.data?.aoiName ?? '',
            aoiId: String(this.data?.aoiId ?? 0),
            regionId: String(this.data?.region?.id ?? 0),
            regionName: this.data?.region?.name ?? '',
            aoiUrlName: encodeAOIName(String(this.data?.aoiName)),
            today: new Date().toISOString().substring(0, 10),
        };
    }
}
