import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-82e995f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "container",
  class: "container relative"
}
const _hoisted_2 = { class: "titles" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "title-name" }
const _hoisted_5 = { class: "text-sm" }
const _hoisted_6 = { class: "subtitle" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["onMousemove"]
const _hoisted_9 = ["data-key"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      ref: "beeswarms",
      class: "beeswarms",
      style: _normalizeStyle({ 'padding-top': `${_ctx.topMargin}px` })
    }, [
      (_openBlock(), _createElementBlock("svg", {
        ref: "axis",
        class: "axis",
        style: _normalizeStyle({
                    width: `${_ctx.svgWidth}px`,
                    right: '0'
                })
      }, null, 4)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (group, i) => {
        return (_openBlock(), _createElementBlock(_Fragment, {
          key: group.id
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("span", _hoisted_4, _toDisplayString(group.title), 1),
              _createElementVNode("span", _hoisted_5, "(" + _toDisplayString(group.data.length) + ")", 1)
            ]),
            _createElementVNode("div", _hoisted_6, _toDisplayString(group.subtitle), 1),
            (_ctx.clickLabelFunction)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "action",
                  onClick: ($event: any) => (_ctx.onActionClick(group))
                }, _toDisplayString(_ctx.clickLabelFunction(group)), 9, _hoisted_7))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", {
            ref_for: true,
            ref: el => { if (el) _ctx.vforDivs[i] = el; },
            class: "grid-beeswarm",
            onMousemove: e => _ctx.onVForHover(e, i),
            onMouseleave: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onVForMouseout && _ctx.onVForMouseout(...args)))
          }, [
            (_openBlock(), _createElementBlock("svg", {
              class: "beeswarm",
              style: _normalizeStyle({
                            'margin-top': `${_ctx.spaceBetween / 2}px`,
                            'margin-bottom': `${_ctx.spaceBetween / 2}px`
                        }),
              "data-key": group.id
            }, null, 12, _hoisted_9)),
            (_ctx.hoveredPoint && _ctx.hoveredPoint.id === group.id)
              ? _renderSlot(_ctx.$slots, "tooltip", {
                  key: 0,
                  class: "tooltip-slot",
                  style: _normalizeStyle({
                            bottom: `${_ctx.tooltipY}px`,
                            left: `${_ctx.tooltipX}px`,
                            transform: _ctx.hoveredPoint.value >= 0
                                ? `translate(calc(-100% + ${_ctx.radii[i] || 0}px), 0)`
                                : `translate(-${_ctx.radii[i] || 0}px, 0)`
                        }),
                  rows: [_ctx.tooltipRow]
                }, undefined, true)
              : _createCommentVNode("", true)
          ], 40, _hoisted_8)
        ], 64))
      }), 128))
    ], 4)
  ], 512))
}