
import { defineComponent, PropType, computed, nextTick, inject } from 'vue';
import { enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import useApp from '@/composables/state/useApp';

interface Section {
    title: string,
    hideFromMenu?: boolean,
    sections?: Section[]
}

export default defineComponent({
    name: 'TreeMenu',
    props: {
        sections: {
            type: Object as PropType<Section[]>,
            default: null,
        },
        selectedSection: {
            type: Object as PropType<Section>,
            default: null
        },
        parentSection: {
            type: Object as PropType<Section>,
            default: null
        }
    },
    emits: ['section-selected'],
    setup(props) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const screens: any = inject('screens');

        const isDesktop = computed(() => screens.w >= 1024);

        const filteredSections = computed(() => {
            if (!props.sections) return null;
            return props.sections.filter(s => !s.hideFromMenu);
        });

        return { filteredSections, isDesktop };
    },
    data() {
        return {
            expandedSection: this.sections && this.sections[0]
        };
    },
    watch: {
        selectedSection() {
            if (!this.parentSection) {
                this.expandSelectedSection(this.selectedSection, this.sections);
            }
        }
    },
    async mounted() {
        await nextTick();
        if (!this.parentSection) {
            this.expandSelectedSection(this.selectedSection, this.sections);
        }
    },
    beforeUnmount() {
        clearAllBodyScrollLocks();
    },
    methods: {
        selectSection(selectedSection: Section, lg: boolean) {
            const { toggleMenu } = useApp();

            const scrollableMenu = document.querySelector('.menu.scrolling-touch');
            if (scrollableMenu) {
                // console.log('unlocked');
                enableBodyScroll(scrollableMenu);
            }

            this.$emit('section-selected', selectedSection);

            toggleMenu(lg);
        },
        expandSelectedSection(selectedSection: Section, sections: Section[], parent?: Section) {
            // if (selectedSection !== this.expandedSection) { // don't reset menu when it is already opened.
            sections.forEach((s) => {
                if (s === selectedSection) {
                    if (parent) {
                        this.expandedSection = parent;
                    } else {
                        this.expandedSection = s;
                    }
                }

                if (s.sections) {
                    this.expandSelectedSection(selectedSection, s.sections, s);
                }
            });
            // }
        }
    }
});
