
import { computed, defineComponent, inject, PropType } from 'vue';
import SpeciesCalloutModel from '@/model/SpeciesCallout';
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';
import { Pagination, Navigation } from 'swiper';
import SpeciesCalloutIndividual from './SpeciesCalloutIndividual.vue';
// Import Swiper Vue.js components
// Import Swiper styles
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

export default defineComponent({
    name: 'SpeciesCalloutSection',
    components: {
        SpeciesCalloutIndividual,
        Swiper,
        SwiperSlide
    },
    props: {
        speciesInfo: {
            type: Object as PropType<SpeciesCalloutModel[]>,
            default: null,
        },
        category: {
            type: String,
            default: 'default'
        }
    },

    setup() {
        const screens: any = inject('screens');

        const isShowSlider = computed(() => screens.w > 768);
        return { modules: [Pagination, Navigation], isShowSlider };
    }
});
