
import _ from 'lodash';
import { computed, defineComponent, inject, PropType, ref } from 'vue';
import getTailwindColor from '@/getTailwindColor';
import useFootprintTrends from '@/composables/state/report/useFootprintTrends';
import { FootprintTrendsContent } from '@/model/report';
import GridLayout from '@/layouts/GridLayout.vue';
import LineChart from '@/components/LineChart/LineChart.vue';
import Toggle from '@/components/Toggle.vue';
import Tooltip from '@/components/Tooltip.vue';
import useApp from '@/composables/state/useApp';

// reorder data items for inserting into grid in column order instead of row order
function reorder<T>(arr: T[], cols: number) {
    const rows = Math.ceil(arr.length / cols);
    const out = [];
    for (const i of _.range(0, arr.length)) {
        const idx = (cols * (i % (arr.length / cols))) + Math.floor(i / rows);
        out.push(arr[idx]);
    }
    return out;
}

export default defineComponent({
    name: 'FootprintTrendSection',
    components: {
        GridLayout,
        LineChart,
        Toggle,
        Tooltip,
    },
    props: {
        section: {
            type: Object as PropType<FootprintTrendsContent>,
            required: true,
        }
    },
    setup(props) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const screens: any = inject('screens');
        // constants for this app
        const templateItems = ref(2); // 2 divs - titles and chart
        const numDesktopColumns = ref(2);
        const { aoi } = useApp();
        const { model, infoLabels } = useFootprintTrends(aoi, props.section);
        const year = computed(() => model.year);
        const fullDomain = computed(() => model.fullDomain);
        const stackFormat = computed(() => model.format);

        const yearRange = computed(() => model.yearRange);

        // number of rows if titles + chart is considered one row
        const numLogicalRows = computed(
            () => {
                if (screens.mobile) {
                    return model.selectedTrendData.length;
                }

                return Math.ceil(model.selectedTrendData.length / numDesktopColumns.value);
            }
        );

        const totalFootprint = computed(() => model.totalFootprint);
        const latestData = computed(() => model.latestData);
        const latestMainCategoryData = computed(() => model.latestMainCategoryData);
        const trendData = computed(() => {
            if (screens.mobile) {
                return model.selectedTrendData;
            }
            return reorder(model.selectedTrendData, numDesktopColumns.value);
        });
        const totalTrendData = computed(() => model.totalTrendData);
        const trendSubtitles = computed(() => model.lineSubtitles);
        const totalTrendSubtitle = computed(() => model.totalSubtitle);
        const categories = computed(() => model.categories);
        const styles = computed(() => model.lineStyles);

        const pieChartData = computed(() => totalFootprint.value && { color: getTailwindColor('green', 950), value: totalFootprint.value });

        const toggleOptions = computed(() => model.toggleOptions);
        const selectedToggle = computed(
            () => (model.showMagnitude ? 'magnitude' : 'change')
        );

        const infoLabelsKey = computed(
            () => (model.showMagnitude ? 'default' : 'change')
        );

        const onToggleChange = (option: string) => {
            model.setShowMagnitude(option === 'magnitude');
        };

        const downloadCSVData = () => {
            model.downloadCSV()
                .then((csvStr) => {
                    const anchor = document.createElement('a');
                    anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csvStr)}`;
                    anchor.target = '_blank';
                    anchor.download = `${model.areaName}-Human-Footprnt.csv`;
                    anchor.click();
                });
        };

        const totalHeight = computed(() => {
            if (screens.mobile) {
                return '2000px';
            }

            return '1000px';
        });

        const totalWidth = computed(() => {
            if (screens.mobile) {
                // 1rem = 16px ... 1.5rem * 2 = 48px
                return `${screens.w - 48}px`;
            }

            return '550px';
        });

        return {
            sectionContent: model,
            templateItems,
            numDesktopColumns,
            numLogicalRows,
            aoi,
            yearRange,
            year,
            totalFootprint,
            latestData,
            latestMainCategoryData,
            totalTrendData,
            trendData,
            trendSubtitles,
            totalTrendSubtitle,
            infoLabels,
            infoLabelsKey,
            pieChartData,
            styles,

            categories,
            fullDomain,
            stackFormat,

            toggleOptions,
            selectedToggle,
            onToggleChange,
            downloadCSVData,

            totalHeight,
            totalWidth
        };
    }
});
