import _ from 'lodash';
import { select } from 'd3-selection';
import formatter, { FormatOptions } from '@dha/number-format';
import { FootprintCategoryIO } from '@/api/report';
import { appendIfNotExists } from '../helpers';

export type StackedChartDataItem = {
    title: string;
    value: number;
    color: string;
    icon?: string;
    key?: string;
    description?: string;
    formattedValue?: string;
}

type StackedChartWithCoords = StackedChartDataItem & {
    x: number;
    width: number;
}

function drawTotalLine(svg: Element | null, svgWidth: number, total: string) {
    const lineTopYCoordinate = 8; // top y-coordinate of the line
    const lineBottomYCoordinate = 20; // bttom y coordinate of the line
    const lineStrokeWidth = 1;
    const textSpaceWidth = 100; // space of width reserved for width
    const textWidth = 50; // space of width reserved for text
    const textYCoordinate = 14;

    const g = appendIfNotExists(select(svg ?? null), 'g', 'total-line');
    g.selectAll('*').remove();
    // draw top line
    g.append('polyline')
        .attr('points', `0, ${lineBottomYCoordinate} 0, ${lineTopYCoordinate} ${(svgWidth / 2) - (textSpaceWidth / 2)}, ${lineTopYCoordinate}`)
        .attr('fill', 'none')
        .attr('stroke-width', lineStrokeWidth)
        .attr('stroke', 'darkgray');

    g.append('polyline')
        .attr('points', `${(svgWidth / 2) + (textSpaceWidth / 2)}, ${lineTopYCoordinate} ${svgWidth}, ${lineTopYCoordinate} ${svgWidth}, ${lineBottomYCoordinate}`)
        .attr('fill', 'none')
        .attr('stroke-width', lineStrokeWidth)
        .attr('stroke', 'darkgray');

    g.append('text')
        .attr('x', (svgWidth / 2) - (textWidth / 2))
        .attr('y', textYCoordinate)
        .attr('fill', 'darkgray')
        .attr('class', 'total-reading-text')
        .text(total);
}

function drawColorScale(svg: Element | null, svgWidth: number, data: Array<StackedChartWithCoords>, onMouseOver: (d: MouseEvent, i: StackedChartWithCoords) => void, onMouseOut: (d: MouseEvent, i: StackedChartWithCoords) => void): void {
    const rectHeight = 60;
    const yCoordinateForScale = 25;
    const g = appendIfNotExists(select(svg ?? null), 'g', 'chart-items');

    g.attr('width', svgWidth)
        .selectAll('rect.color-item')
        .data(data)
        .join('rect')
        .classed('color-item', true)
        .attr('x', (d) => d.x)
        .attr('y', yCoordinateForScale)
        .attr('width', (d) => d.width)
        .attr('height', rectHeight)
        .attr('fill', (d) => d.color)
        .on('mouseover', onMouseOver)
        .on('mouseout', onMouseOut);
}
function drawOveralyColorScale(svg: Element | null, svgWidth: number, data: Array<StackedChartWithCoords>, onMouseOver: (d: MouseEvent, i: StackedChartWithCoords) => void, onMouseOut: (d: MouseEvent, i: StackedChartWithCoords) => void): void {
    const rectHeight = 60;
    const yCoordinateForScale = 25;
    const g = select(svg ?? null);
    g.select('.chart-items')
        .append('rect')
        .classed('color-item', true)
        .data(data)
        // .join('rect')
        // .classed('color-item', true)
        .attr('x', (d) => d.x)
        .attr('y', yCoordinateForScale)
        .attr('width', (d) => d.width)
        .attr('height', rectHeight)
        .attr('fill', 'url(#diagonalHatch)')
        .on('mouseover', onMouseOver)
        .on('mouseout', onMouseOut);
}

export function draw(
    svg: Element | null | undefined,
    svgWidth: number,
    chartData: Array<StackedChartDataItem>,
    overLayChartData: Array<StackedChartDataItem>,
    onMouseOver: (d: MouseEvent, i: StackedChartWithCoords) => void,
    onMouseOut: (d: MouseEvent, i: StackedChartWithCoords) => void,
    categories: Record<string, FootprintCategoryIO>,
    format?: FormatOptions,
): void {
    let startX = 0;
    const total = _.sumBy(chartData, d => d.value);
    const rectData: Array<StackedChartWithCoords> = [];
    chartData?.forEach((d) => {
        const toRet = {
            ...d,
            x: startX,
            width: (d.value / total * svgWidth) || 0 /* force to be 0 when width is NaN or other null values */
        };
        startX += toRet.width;
        rectData.push(toRet);
    });
    /* create overlay rect data to show as parttern */
    const overlayRectData: Array<StackedChartWithCoords> = [];
    overLayChartData.forEach(d => {
        // get main Category
        const mainCategory = Object.values(categories).find(x => x?.subcategories?.includes(d.key ?? ''))?.name;
        // copy category rect Data
        const matchObj = rectData.find(r => r.title === mainCategory);
        if (matchObj != null) {
            const rect: StackedChartWithCoords = {
                ...matchObj,
                ...d,
                width: (d.value / total * svgWidth) || 0 /* force to be 0 when width is NaN or other null values */
            };
            overlayRectData.push(rect);
        }
    });
    const formattedTotal = format ? formatter(total, format) : `${total.toFixed(2)}%`;
    drawTotalLine(svg ?? null, svgWidth, formattedTotal);

    // draw colors
    drawColorScale(svg ?? null, svgWidth, rectData, onMouseOver, onMouseOut);
    drawOveralyColorScale(svg ?? null, svgWidth, overlayRectData, onMouseOver, onMouseOut);
}
