import { IntactnessCategory, IntactnessDatum, } from '@/api/intactness';
import { Area } from './Area';
import SpeciesCalloutContent from './report/SpeciesCalloutContent';
import SpeciesCallout from './SpeciesCallout';

// this.highlightText = 'As of 2017, the total human footprint across Wheatland County was <span class="font-semibold">10%</span>';
// this.imageSrc = 'https://images.unsplash.com/photo-1568162603664-fcd658421851?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=973&q=80';

/**
  * Wraps Content and Data and provides interface used by UI
  */
export default class TaxnomicSpeciesCalloutsModel {
    content: SpeciesCalloutContent;
    speciesInfo: SpeciesCallout[] = [];
    intactnessData?: (IntactnessDatum | undefined);
    aoi?: Area;
    category?: IntactnessCategory;
    constructor(content: SpeciesCalloutContent) {
        this.content = content;
        this.category = this.content.category;
        this.speciesInfo = [];
    }
    get hasSpeciesInfo(): boolean {
        return !!this.speciesInfo?.length;
    }
    async loadSingleContent(spp:string):Promise<SpeciesCallout> {
        const sppCallout = new SpeciesCallout(this.content);
        await sppCallout.loadContent(spp);
        return new Promise((resolve) => {
            resolve(sppCallout);
        });
    }
    async loadContent(): Promise<void> {
        this.speciesInfo = [];
        // const renderingTasks: any[] = [];
        if (this.intactnessData) {
            this.intactnessData?.sppCallOut?.forEach(async spp => {
                this.speciesInfo.push(await this.loadSingleContent(spp));
            });
        }
    }
    // Species Callout can show Intactness vars
    async load(data: (IntactnessDatum | undefined)[]): Promise<void> {
        const currentGroup = data?.find(x => x?.sppGroup === this.category);
        this.intactnessData = currentGroup;
        await this.loadContent();
    }
}
