// import { SuitabilitySection } from '@/model/ABMIReport';
import type SuitabilityContent from '@/model/report/SuitabilityContent';
import SuitabilityModel from '@/model/Suitability';
import { TaxonomicGroupIO } from '@/api/intactness';
import { computed, Ref, reactive, ComputedRef } from 'vue';
import { Area } from '@/model/Area';
import { IconLegendValue } from '@/components/DotPlot/types';
import { RowFlagIconDatum } from '@/components/RowsFlagNameIcon/types';
import { FormatOptions } from '@dha/number-format';

type SuitabilityModelRefs = {
    flaggedData: ComputedRef<RowFlagIconDatum[] | undefined>,
    legend: ComputedRef<IconLegendValue[] | undefined>,
    format: ComputedRef<FormatOptions | undefined>
}
export default function useSuitabilityModel(aoi: Ref<Area | undefined>, content: SuitabilityContent, taxonomicGroups: Record<string, TaxonomicGroupIO> | undefined): SuitabilityModelRefs {
    /* having model reactive, will triger the loading api twice
    however, if not reactive, the data loaded, but graph not populated.
    so need to look into it. */
    const model = reactive<SuitabilityModel>(new SuitabilityModel(content, taxonomicGroups));
    // const model = new SuitabilityModel(content);
    const flaggedData = computed(() => model.flaggedData);
    const legend = computed(() => model.legend as IconLegendValue[]);
    const format = computed(() => model.format);

    const loadSuitabilityModel = async (aoiToLoad:Area|undefined) => {
        if (aoiToLoad) {
            await model.load(aoiToLoad);
        }
    };
    /*  DH version:  watchEffect(() => loadSuitabilityModel(aoi.value));
            will load data twice, when anything changes.
        ABMI version: loadSuitabilityModel(aoi.value);
            only called when object initalized
    */
    loadSuitabilityModel(aoi.value);
    return {
        flaggedData,
        legend,
        format
    };
}
