import { resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fb61461e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "suitability-section" }
const _hoisted_2 = { class: "overflow-x-auto w-full" }
const _hoisted_3 = { class: "content-container pt-report-margin lg:w-data-plot pb-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionHeader = _resolveComponent("SectionHeader")!
  const _component_rows_flag_name_icon = _resolveComponent("rows-flag-name-icon")!
  const _component_dot_plot = _resolveComponent("dot-plot")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SectionHeader, {
      class: "content-container",
      section: _ctx.intactnessContent
    }, null, 8, ["section"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.dotPlotData)
          ? (_openBlock(), _createBlock(_component_dot_plot, {
              key: 0,
              ref: "dotPlot",
              data: _ctx.dotPlotData,
              "max-collapsed-rows": 15,
              "max-expanded-rows": _ctx.maxExpandedRows,
              "negative-color": _ctx.negativeColor,
              "positive-color": _ctx.positiveColor,
              "negative-text": '⟵ decrease in suitability',
              "positive-text": 'increase in suitability ⟶',
              "search-placeholder": 'find a species',
              "expand-text": 'show full species list',
              "collapse-text": 'hide full species list',
              legend: _ctx.dotPlotLegend,
              format: _ctx.format,
              searched: _ctx.searchedDatum,
              "filter-guild-id": _ctx.filterGuildId,
              "on-click-event": _ctx.openBBProfileUrl,
              onSearch: _ctx.onSearch,
              onUpdateGuild: _ctx.updateGuild
            }, {
              rows: _withCtx((rowsProps) => [
                _createVNode(_component_rows_flag_name_icon, _mergeProps(rowsProps, {
                  format: { ..._ctx.indiviudalIntactnessFormat, addPositiveSign: false }
                }), null, 16, ["format"])
              ]),
              _: 1
            }, 8, ["data", "max-expanded-rows", "negative-color", "positive-color", "legend", "format", "searched", "filter-guild-id", "on-click-event", "onSearch", "onUpdateGuild"]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}