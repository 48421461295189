

import { defineComponent, ref, PropType, computed, watch } from 'vue';
import { FormatOptions } from '@dha/number-format';
import _ from 'lodash';
import observeResize from '@/composables/util/observeResize';
import draw, { PieChartDatum } from './draw';

export default defineComponent({
    name: 'PieChart',
    props: {
        data: {
            type: Object as PropType<PieChartDatum>,
            default: null
        },
        width: {
            type: Number,
            default: 400
        },
        height: {
            type: Number,
            default: 300
        },
        margin: {
            type: Number,
            default: 20
        },
        bgImage: {
            type: String,
            default: 'pieChartBg.svg'
        },
        strokeColor: {
            type: String,
            default: 'white'
        },
        strokeWidth: {
            type: Number,
            default: 8
        },
        readingBgColor: {
            type: String,
            default: 'white'
        },
        format: {
            type: Object as PropType<FormatOptions>,
            default: null
        }
    },
    setup(props) {
        const container = ref<HTMLDivElement>();
        const svg = ref<SVGElement>();

        // setup resize observers/computed sizes
        const {
            dimensions
        } = observeResize(container);

        const svgWidth = computed(() => _.chain([props.width, dimensions.value.width]).compact().min().value());
        const svgHeight = computed(() => _.chain([props.height, dimensions.value.height]).compact().min().value());

        const svgSideLength = computed(() => Math.min(svgHeight.value, svgWidth.value));
        const radius = computed(() => svgSideLength.value / 2 - props.margin);
        const cx = computed(() => svgSideLength.value / 2);
        const cy = computed(() => svgSideLength.value / 2);

        // redraw only when data updated.
        watch(() => props.data, async () => {
            draw(
                svg.value,
                {
                    width: svgSideLength.value,
                    height: svgSideLength.value,
                    radius: radius.value + (props.margin / 2), // making radius or arcs a little bigger
                    strokeColor: props.strokeColor,
                    strokeWidth: props.strokeWidth,
                    readingBgColor: props.readingBgColor
                },
                props.data,
                props.format
            );
        });
        // // draw on data update
        // watchEffect(() => {
        //     console.log('redraw');
        //     draw(
        //         svg.value,
        //         {
        //             width: svgSideLength.value,
        //             height: svgSideLength.value,
        //             radius: radius.value + (props.margin / 2), // making radius or arcs a little bigger
        //             strokeColor: props.strokeColor,
        //             strokeWidth: props.strokeWidth,
        //             readingBgColor: props.readingBgColor
        //         },
        //         props.data,
        //         props.format
        //     );
        // }, { flush: 'post' });

        return {
            svgSideLength,
            container,
            radius,
            cx,
            cy,
            svg
        };
    }
});
