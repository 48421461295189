import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { RouteNames } from '@/composables/state/useApp';
import AreasComponent from '../views/AreaOfInterest.vue';
import ReportComponent from '../views/Report.vue';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/areas/:region?/:area?',
        name: RouteNames.Areas,
        component: AreasComponent,
    },
    {
        path: '/report-by-id/:region/:aoi', // Section is hashed
        name: RouteNames.Report,
        component: ReportComponent,
    },
    {
        path: '/region/:aoiName', // Section is hashed
        name: RouteNames.ReportByName,
        component: ReportComponent,
    },
    // For now, the Area view is the Home Page, redirect
    {
        path: '/',
        name: RouteNames.Home,
        redirect: { name: RouteNames.Areas }
    },
    // For now, All routes redirect home,
    // TODO: 404 for invalid routes
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        redirect: { name: 'Home' }
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;
