import { DirectiveBinding } from 'vue';

type ClickOutsideElement = Element & {
    clickOutsideEvent?:(event: Event) => void
}
export const clickOutside = {
    beforeMount: (el: ClickOutsideElement, binding: DirectiveBinding<()=>void>):void => {
        // eslint-disable-next-line no-param-reassign
        el.clickOutsideEvent = (event: Event) => {
            if (!(el === event.target || el.contains(event.target as Element))) {
                binding.value();
            }
        };
        document.addEventListener('click', el.clickOutsideEvent);
    },
    unmounted: (el: ClickOutsideElement):void => {
        if (el.clickOutsideEvent) document.removeEventListener('click', el.clickOutsideEvent);
    },
};

export default clickOutside;
