import { reactive, Ref, watchEffect } from 'vue';
import { Section } from '@/model/report';
import { Area } from '@/model/Area';
import FootprintStatusModel from '@/model/FootprintStatus';
import { AreaOfAnalysisIO } from '@/api/aoi';
import { FootprintChangeIO } from '@/api/footprint';
import useAOILoader from '../loaders/useAOILoader';
import useFootprintChangeLoader from '../loaders/useFootprintChangeLoader';

/**
 * Builds the Model object from the given content using data for the given area.
 * @param aoi The area selected to load data for
 * @param sectionIo The section report content
 * @returns A reactive Model with templated Area of interest content
 */
export default function useFootprintStatus(aoi: Ref<Area | undefined>, content: Section):FootprintStatusModel {
    const model = reactive<FootprintStatusModel>(new FootprintStatusModel(content));
    const aoiData = useAOILoader(aoi);
    const footprintData = useFootprintChangeLoader(aoi);
    const loadFootprintData = async (aoiDataToLoad:AreaOfAnalysisIO|undefined, footprintToLoad:FootprintChangeIO[]|undefined) => {
        if (aoiDataToLoad && footprintToLoad) {
            model.load(aoiDataToLoad, footprintToLoad);
        }
    };
    watchEffect(() => loadFootprintData(aoiData.value, footprintData.value));
    return model;
}
