
import { defineComponent, PropType, computed } from 'vue';
// import useApp from '@/composables/state/useApp';
import type { Section } from '@/model/report';
// import useGeneralRegion from '@/composables/state/report/useGeneralRegion';

export default defineComponent({
    name: 'PopupBox',
    props: {
        section: {
            type: Object as PropType<Section>,
            default: null,
        },
        showPopup: {
            type: Boolean, default: false
        },
        togglePopup: {
            type: Function, default: null
        },
    },
    setup(props) {
        // const { aoi } = useApp();

        /* this is not reactive if not witin the computed */
        const content = computed(() => props.section);
        return {
            content
        };
    }
});
