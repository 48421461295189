import { WetlandDatum } from '@/api/wetland';
import { reactive, Ref, watchEffect } from 'vue';
// import WetlandStatusContent from '@/model/report/WetlandStatusContent';
import { Area } from '@/model/Area';
import WetlandStatusModel from '@/model/WetlandStatus';
import { AreaOfAnalysisIO } from '@/api/aoi';

import { Section } from '@/model/report';
import useAOILoader from '../loaders/useAOILoader';
import useWetlandLoader from '../loaders/useWetlandLoader';

/**
 * Builds the Model object from the given content using data for the given area.
 * @param aoi The area selected to load data for
 * @param sectionIo The section report content
 * @returns A reactive Model with templated Area of interest content
 */
export default function useWetlandStatus(aoi: Ref<Area | undefined>, content: Section):WetlandStatusModel {
    const model = reactive<WetlandStatusModel>(new WetlandStatusModel(content));
    const aoiData = useAOILoader(aoi);
    const wetlandData = useWetlandLoader(aoi);
    const loadWetlandData = async (aoiDataToLoad:AreaOfAnalysisIO|undefined, wetlandToLoad:WetlandDatum[]|undefined) => {
        if (aoiDataToLoad && wetlandToLoad) {
            model.load(aoiDataToLoad, wetlandToLoad);
        }
    };
    watchEffect(() => loadWetlandData(aoiData.value, wetlandData.value));
    return model;
}
