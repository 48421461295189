import type { AreasOfInterestIO, AreaIO, RegionIO } from '@/api/aoi';
import _ from 'lodash';

import { fetchAreasOfInterest, fetchAreas, fetchQueriedAreas } from '@/api';
import { QueriedAreaIO } from '@/api/aoi';
import { ReportIO } from '@/api/report';
import { Area, Areas, AreasOfInterest, Region } from './Area';

export interface CreateReportParams {
    area: string;
}

// Ensure AreasOfInterestIO only loaded once as singleton
let asyncAreasOfInterestData: Promise<AreasOfInterestIO>;
let _aois: AreasOfInterest;

export class AppInfo {
    title = 'Status of Land Cover & Biodiversity';
    org = 'Reporting by: Alberta Biodiversity Monitoring Institute';
    cmsInfo: (ReportIO | null) = null;
    constructor(reportIo: ReportIO | null) {
        if (reportIo) {
            this.title = reportIo.report.title || this.title;
            this.cmsInfo = reportIo;
        }
    }
}

/**
 * Model factory for App and Area Model objects.
 */
export default class Factory {
    static async getAppInfo(appInfo:Promise<ReportIO> | null): Promise<AppInfo> {
        // For now, app info uses the report title, but could get its own key
        if (appInfo) {
            const appContent = await appInfo;
            return new AppInfo(appContent);
        }
        return new AppInfo(null);
    }

    static async getAreasOfInterest(api = fetchAreasOfInterest):Promise<AreasOfInterest> {
        if (!asyncAreasOfInterestData) {
            asyncAreasOfInterestData = api();
            _aois = new AreasOfInterest(await asyncAreasOfInterestData);
        }
        return _aois;
    }
    static async getRegionAreas(region: RegionIO, api = fetchAreas): Promise<Areas> {
        const areaIos = await api(region);

        const areas = _.map(areaIos, (areaIo: AreaIO) => {
            const area = new Area(areaIo);
            return area;
        });
        return new Areas(...areas);
    }
    static async getRegion(regionId?: number): Promise<Region | undefined> {
        const aois = await Factory.getAreasOfInterest();
        return aois.getRegion(regionId);
    }
    static async getArea(aoiId: number, regionId:number): Promise<Area | undefined> {
        const region = await Factory.getRegion(regionId);
        if (!region) {
            return undefined;
        }
        const areas = await Factory.getRegionAreas(region);
        return areas.getArea(aoiId);
    }
    static async queryAreas(query: string, api = fetchQueriedAreas): Promise<QueriedAreaIO[]> {
        const areaIos = await api(query);
        return areaIos;
    }
}
