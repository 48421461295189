
import { computed, defineComponent } from 'vue';
import useApp from '@/composables/state/useApp';

export default defineComponent({
    name: 'OnlineReport',
    setup() {
        const { appInfo, showPopup, togglePopup } = useApp();
        const contactUs = computed(() => appInfo.value?.cmsInfo?.contactUs?.title);
        const terms = computed(() => appInfo.value?.cmsInfo?.terms?.title);
        return { showPopup, togglePopup, contactUs, terms };
    }
});
