
// don't show categories, only listing the areas
/*
use this to close menu
@click="setSelectedCategory( (selectedCategory && category.id === selectedCategory.id) ? 0 : category.id)"

However need to consider it sub menu close. so leave it for now.
*/
import { defineComponent, computed } from 'vue';
import useApp from '@/composables/state/useApp';
import useRegionCategories from '@/composables/state/areas/useRegionCategories';
import { Region } from '@/model/Area';

export default defineComponent({
    name: 'RegionCategories',
    setup() {
        const { region, selectRegion, toggleAreaExplorer, toggleMenu } = useApp();
        const { categories } = useRegionCategories();

        const allRegions = computed(() => {
            const firstCategory = (categories.value ?? []);
            return firstCategory[0]?.regions?.sort((a, b) => (a.name > b.name ? 1 : -1));
        });
        // categories.value?.reduce((prev: Array<Region>, category) => [...prev, ...category.regions], []));

        const regionClicked = (selectedRegion: Region) => {
            toggleMenu(false);
            toggleAreaExplorer(true);
            selectRegion(selectedRegion);
        };

        return {
            selectedRegion: region,
            allRegions,
            regionClicked
        };
    },
});
