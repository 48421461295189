import * as t from 'io-ts';
import { parse } from '@dha/io-ts-parse';

import type dataFetchApi from '@/services/data';

const SuitabilityTS = t.type({
    id: t.number,
    name: t.union([t.string, t.null]),
    scientificName: t.union([t.string, t.null, t.undefined]),
    value: t.number,
    intactness: t.union([t.number, t.undefined]),
    imageSrc: t.string,
    guilds: t.array(t.number)
});
export type SuitabilityDatum = t.TypeOf<typeof SuitabilityTS>;

const IntactnessTS = t.type({
    // value: t.number,
    year: t.number,
    sppCount: t.number,
    sppGroup: t.string,
    sppGroupId: t.number,
    intactnessYear: t.number,
    intactnessIndex: t.number,
    sppCallOut: t.union([t.array(t.string), t.null, t.undefined])
});
export type IntactnessDatum = t.TypeOf<typeof IntactnessTS>;

const SpeciesInfoTS = t.type({
    name: t.string,
    cname: t.union([t.string, t.null]),
    title: t.union([t.string, t.null]),
    imageText: t.union([t.string, t.null]),
    description: t.string,
    imageSrc: t.string,
    photoCredit: t.string,
});
export type SpeciesInfoIO = t.TypeOf<typeof SpeciesInfoTS>;

const TaxonomicGroupTS = t.type({
    id: t.union([t.number, t.null]),
    code: t.string,
    name: t.string,
    showNames: t.union([t.array(t.string), t.null])
});
export type TaxonomicGroupIO = t.TypeOf<typeof TaxonomicGroupTS>;

export type Taxonomy = 'mammals' | 'vplants' | 'birds';
export const taxonomies:Array<Taxonomy> = ['birds', 'mammals', 'vplants'];
export type IntactnessCategory = Taxonomy | 'biodiversity';

export type SuitabilityData = {
    guildList: Array<{ id: number, label: string, description: string, imageSrc: string}>;
    data: Record<Taxonomy, SuitabilityDatum[] | undefined>;
}

type DataApi = Pick<typeof dataFetchApi, 'fetchSuitabilityData' | 'fetchIntactnessCSV' | 'fetchIntactnessIndex' | 'fetchSpeciesInfo' | 'fetchTaxonomicGroups'>;
type IntactnessApi = {
    fetchSuitabilityData: (aoi: number, category?: IntactnessCategory) => Promise<SuitabilityData>;
    fetchIntactnessIndex: (aoi: number) => Promise<IntactnessDatum[]>;
    fetchSpeciesInfo: (aoi: number, category: IntactnessCategory) => Promise<SpeciesInfoIO | null>;
    fetchTaxonomicGroups: () => Promise<TaxonomicGroupIO[]>;
    fetchIntactnessCSV: (aoi: number) => Promise<string>;
}

export default (data: DataApi): IntactnessApi => ({
    fetchSuitabilityData: async (aoiId: number, category?: IntactnessCategory): Promise<SuitabilityData> => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const apiData: any = await data.fetchSuitabilityData(aoiId, category);
        // Only the 3 for now
        const { mammals, vplants, birds } = apiData;
        const parsedData = {
            guildList: apiData.guildList,
            data: { mammals, vplants, birds }
        };
        return parsedData as SuitabilityData;
    },
    /* get intactness for all groups */
    fetchIntactnessIndex: async (aoiId: number):Promise<IntactnessDatum[]> => {
        const apiData = await data.fetchIntactnessIndex(aoiId);
        const parsedData = parse(t.array(IntactnessTS), apiData);
        return parsedData;
    },
    fetchSpeciesInfo: async (aoiId: number, category?: IntactnessCategory): Promise<SpeciesInfoIO | null> => {
        const apiData = await data.fetchSpeciesInfo(aoiId, category);
        if (!apiData) {
            return null;
        }
        const parsedData = parse(SpeciesInfoTS, apiData);
        return parsedData;
    },
    fetchTaxonomicGroups: async (): Promise<TaxonomicGroupIO[]> => {
        const apiData = await data.fetchTaxonomicGroups();
        const parsedData = parse(t.array(TaxonomicGroupTS), apiData);
        return parsedData;
    },
    fetchIntactnessCSV: async (aoiId: number): Promise<string> => {
        const apiData = await data.fetchIntactnessCSV(aoiId);
        return apiData as string;
    }
});
