import { createApp } from 'vue';
import 'ts-replace-all';
import state from '@/composables/state';
import clickOutsideFn from '@/directivess/clickOutside';
import collapsibleFn from '@/directivess/collapsible';
import FloatingVue from 'floating-vue';
import App from './App.vue';
import router from './router';
import screens from './screens';
import 'floating-vue/dist/style.css';

const app = createApp(App)
    .directive('click-outside', clickOutsideFn)
    .directive('collapsible', collapsibleFn);

// AppState wired in as plugin to wire in the router reactivity
app.use(state, { router });

app.use(screens);
app.use(FloatingVue);

app.mount('#app');
