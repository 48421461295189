/**
 * Data Model for FootprintChange
 */
import { FootprintChangeIO } from '@/api/footprint';
import { FootprintConfigIO } from '@/api/report';
// import { IntactnessCategory } from '@/api/intactness';
import _ from 'lodash';

import { templateMarkdown } from './helpers';

/**
  * FootprintChange Model is a data only model that is needed by FootprintTrends
  */
export default class FootprintChangeModel {
    data?: FootprintChangeIO[];
    config: FootprintConfigIO;
    constructor(config: FootprintConfigIO) {
        this.config = config;
    }
    get dataByCategory(): Record<string, FootprintChangeIO> | undefined {
        if (!this.data) {
            return this.data;
        }
        return _.keyBy(this.data, 'id');
    }
    get infoLabels():Record<string, Record<string, string>> | undefined {
        if (!this.dataByCategory) {
            return this.dataByCategory;
        }
        return _.mapValues(this.config.categories, (v, k) => {
            const props = this.dataByCategory?.[k];
            if (!props) {
                return;
            }
            const infoLabel = v?.infoLabel ?? { default: { default: '--' } };
            const diffAdjective = (props.endArea > props.startArea) ? 'increased' : (props.endArea < props.startArea) ? 'decreased' : 'unchanged';
            const changePct = Math.abs(((props.endArea - props.startArea) / props.startArea) * 100);
            let infoLabelType = 'default';
            if (diffAdjective === 'unchanged') {
                infoLabelType = 'unchanged';
            }
            const mappedProps = {
                ...props,
                categoryName: props.categoryName.replace(' Footprint', ''), // .toLowerCase(), // remove footprint from total human footprint
                startYear: String(props.startYear),
                changePct,
                diffAdjective
            };

            const result:Record<string, string> = {};
            Object.keys(infoLabel).forEach(t => {
                result[t] = templateMarkdown(infoLabel[t][infoLabelType], mappedProps, this.config.changeFormat);
            });
            // eslint-disable-next-line consistent-return
            return result;
        });
    }

    async load(data: FootprintChangeIO[]): Promise<void> {
        this.data = data;
    }
}
