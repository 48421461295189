import { AreaOfAnalysisIO } from '@/api/aoi';
import { Section } from './report';
import { TemplateProps } from './helpers';
import SectionContentModel from './SectionContent';

// const areaText = computed(() => templateMarkdown(props.section.description, templateProps.value));

export default class AreaOfInterestModel extends SectionContentModel {
    data?: AreaOfAnalysisIO;
    content: Section;
    constructor(content: Section) {
        super(content);
        this.content = content;
    }
    load(data: AreaOfAnalysisIO):void {
        this.data = data;
    }
    get templateProps(): TemplateProps {
        return this.data || {};
    }
}
