
import { defineComponent, PropType, computed } from 'vue';
import SpeciesCalloutModel from '@/model/SpeciesCallout';
import { openBBProfileUrl } from '@/util/util';
import useApp from '@/composables/state/useApp';

export default defineComponent({
    name: 'SpeciesCalloutIndividual',
    props: {
        speciesCallout: {
            type: Object as PropType<SpeciesCalloutModel>,
            default: null,
        },
        category: {
            type: String,
            default: 'default'
        }
    },
    setup(props) {
        const { aoi } = useApp();

        const abmiProfileUrl = process.env.VUE_APP_ABMI_LARGE_PROFILE_LINK;
        const name = computed(() => props.speciesCallout?.data?.name);
        const cname = computed(() => props.speciesCallout?.data?.cname);
        const title = computed(() => props.speciesCallout?.data?.title);
        const imageText = computed(() => props.speciesCallout?.data?.imageText);
        const imageSrc = computed(() => `${abmiProfileUrl}${props.speciesCallout?.data?.imageSrc}`);
        const description = computed(() => props.speciesCallout?.data?.description);
        const photoCredit = computed(() => props.speciesCallout?.data?.photoCredit);
        const hasSpeciesInfo = computed(() => props.speciesCallout?.data?.imageText != null || props.speciesCallout?.data?.title != null);

        const regionCss = computed(
            () => ((aoi.value && aoi.value.regionTypeCode) ? `bg-${aoi.value?.regionTypeCode}-main` : 'text-default-main')
        );
        return {
            cname, name, title, imageText, imageSrc, description, hasSpeciesInfo, openBBProfileUrl, regionCss, photoCredit
        };
    }
});
