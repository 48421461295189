
import { defineComponent, PropType, computed } from 'vue';
import type { Section } from '@/model/report';

import useFootprintStatus from '@/composables/state/report/useFootprintStatus';
import useApp from '@/composables/state/useApp';
import ChapterTitlePage from './ChapterTitlePage.vue';

export default defineComponent({
    name: 'FootprintChapter',
    components: {
        ChapterTitlePage
    },
    props: {
        section: {
            type: Object as PropType<Section>,
            default: null,
        }
    },
    setup(props) {
        const { aoi } = useApp();
        const footprintContent = computed(() => useFootprintStatus(aoi, props.section));
        return { footprintContent };
    }
});
