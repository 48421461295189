import { fetchContent, fetchConfig, contentKeys } from '@/services/content';
import type { ContentKeys } from '@/services/content';
import type { Taxonomy } from '@/api/intactness';
import type { FootprintCategory } from '@/api/footprint';
import { footprintCategoryById } from '@/api/footprint';
// import { fetchContent } from '@/services/content';
import { FormatOptions } from '@dha/number-format';
import _ from 'lodash';
import { WetlandCategory } from './wetland';

export { contentKeys, ContentKeys };

/**
 * Defines the fields that can be populated for content objects
 * id and name may be same.  But id may need to be a pascal case version of name, for example.
 */
export type ContentMetaIO = {
    name?: string;
    title?: string;
    subtitle?: string;
    description?: string;
    color?: string;
    imageSrc?: string;
    bottomDivider?: boolean;
}

/**
 * ContentIO are keyed to ContentKeys from config
 */
export type ContentIO = ContentMetaIO & {
    id: ContentKeys;
}

export type ReportIO = Record<ContentKeys | string, ContentIO>;

// export type PartialReportIO = Partial<Record<ContentKeys, ContentIO>>;

/**
 * Properties needed by the specific Sections for creating the viz
 */
export type ConfigIO = {
    format?: FormatOptions;
    imageSrc?: string;
    hoverHint?: Record<string, string>;
}
export type LegendItemIO = {
    id: number;
    label: string;
    imageSrc: string;
}

export type AreaOfAnalysisConfigIO = ConfigIO;

export type FootprintCategoryIO = ConfigIO & ContentMetaIO & {
    id: number;
    key: FootprintCategory;
    infoLabel: Record<string, Record<string, string>>;
    icon: string;
    isMainCategory: boolean;
    isTotalCategory: boolean;
    subcategories?: string[];
    dottedLineStyle?: boolean; // control line display style
    tooltip?: string;
}
export type FootprintConfigIO = ConfigIO & {
    changeFormat?: FormatOptions
    categories: Partial<Record<FootprintCategory, FootprintCategoryIO>>;
};

export type WetlandCategoryIO = ConfigIO & ContentMetaIO & {
    id: number;
    key: WetlandCategory;
    label: string,
    color: string,
}
export type WetlandConfigIO = ConfigIO & {
    categories: Partial<Record<WetlandCategory, WetlandCategoryIO>>;
};
export type IntactnessConfigIO = ConfigIO & {
    explanation: string;
}
export type SuitabilityConfigIO = ConfigIO & IntactnessConfigIO & {
    legend: Array<LegendItemIO>;
}

export type OverallSuitabilityConfigIO = ConfigIO & IntactnessConfigIO & {
    colors: Record<Taxonomy, string>;
}

export type SectionConfigsIO = Record<ContentKeys, ConfigIO> & {
    areaOfAnalysis: AreaOfAnalysisConfigIO,
    footprintTrends: FootprintConfigIO,
    footprintMapped: FootprintConfigIO,
    mammalsSuitability: SuitabilityConfigIO,
    vplantsSuitability: SuitabilityConfigIO,
    birdsSuitability: SuitabilityConfigIO,
    overallSuitability: OverallSuitabilityConfigIO,
    mammals: IntactnessConfigIO,
    vplants: IntactnessConfigIO,
    birds: IntactnessConfigIO,
    introductionIntactness: IntactnessConfigIO,
    mammalsIntactness: IntactnessConfigIO,
    vplantsIntactness: IntactnessConfigIO,
    birdsIntactness: IntactnessConfigIO,
    overallIntactness: IntactnessConfigIO
    overallIntactnessMapped: IntactnessConfigIO,

    wetlandStatus: WetlandConfigIO,
    wetlandMapped: WetlandConfigIO,
};

type ReportApi = {
    // content: Promise<ReportIO>,
    config: Promise<SectionConfigsIO>,
    getContent: (regionType:string) => Promise<ReportIO>
};
function mapFootprintID(category: FootprintCategoryIO) {
    return {
        ...category,
        id: Number.parseInt(_.findKey(footprintCategoryById, (cat) => cat === category.key) || '', 10)
    };
}
async function validateConfig(configFetcher = fetchConfig):Promise<SectionConfigsIO> {
    const asyncConfig = configFetcher();
    const config = (await asyncConfig) as Record<ContentKeys, ConfigIO>;
    const parsed = config as SectionConfigsIO;
    parsed.footprintTrends.categories = _.mapValues(parsed.footprintTrends.categories, mapFootprintID);
    parsed.footprintMapped.categories = _.mapValues(parsed.footprintMapped.categories, mapFootprintID);
    return parsed;
}

async function validateContent(contentFetcher = fetchContent):Promise<ReportIO> {
    const asyncConfig = contentFetcher();
    const content = (await asyncConfig) as Record<ContentKeys, ContentIO>;
    const parsed = content as ReportIO;
    return parsed;
}

export default (contentFetcher = fetchContent, configFetcher = fetchConfig): ReportApi => (
    {
        /*
        content: get default content which is 'default' folder at report content app. but each region has different type, so this will be a wasted call
        instead:
        add getContent function, which allow users to pass in the 'type' as a parameter
        */
        // content: validateContent(contentFetcher),
        config: validateConfig(configFetcher),
        getContent: async (regionType:string) => validateContent(async () => contentFetcher(regionType))
    });
