import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ReportMenu = _resolveComponent("ReportMenu")!
  const _component_ReportTitlePage = _resolveComponent("ReportTitlePage")!
  const _component_ScrollableSection = _resolveComponent("ScrollableSection")!
  const _component_ChapterBottomSection = _resolveComponent("ChapterBottomSection")!
  const _component_FooterSection = _resolveComponent("FooterSection")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["scroll-report", {'is-menu-active': _ctx.isMenuOpen}])
  }, [
    (!_ctx.lg)
      ? (_openBlock(), _createBlock(_component_ReportMenu, {
          key: 0,
          "toggle-popup": _ctx.togglePopup
        }, null, 8, ["toggle-popup"]))
      : _createCommentVNode("", true),
    (_ctx.report)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["report-content", [(_ctx.aoi?.regionTypeCode ?? 'default') + '-color']])
        }, [
          _createVNode(_component_ReportTitlePage, { report: _ctx.report }, null, 8, ["report"]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.report.sequenced, (section) => {
            return (_openBlock(), _createBlock(_component_ScrollableSection, {
              id: section.id,
              key: section.id,
              section: section,
              class: _normalizeClass(_ctx.getSectionClasses(section))
            }, null, 8, ["id", "section", "class"]))
          }), 128))
        ], 2))
      : _createCommentVNode("", true),
    _createVNode(_component_ChapterBottomSection, { link: '#area' }),
    _createVNode(_component_FooterSection)
  ], 2))
}