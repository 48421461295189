
import { defineComponent, PropType, watchEffect, ref, inject, computed } from 'vue';
import DotPlot from '@/components/DotPlot/DotPlot.vue';
import type { SuitabilityContent } from '@/model/report';
import RowsFlagNameIcon from '@/components/RowsFlagNameIcon/RowsFlagNameIcon.vue';
import { RowFlagIconDatum } from '@/components/RowsFlagNameIcon/types';
import useSuitability from '@/composables/state/report/useSuitability';
import useIntactness from '@/composables/state/report/useIntactness';
import useApp from '@/composables/state/useApp';
import useReport from '@/composables/state/report/useReport';
import { openBBProfileUrl } from '@/util/util';
import { indiviudalIntactnessFormat } from '@/services/content/config';
import SectionHeader from './SectionHeader.vue';

export default defineComponent({
    name: 'SuitabilitySection',
    components: {
        DotPlot,
        RowsFlagNameIcon,
        SectionHeader
    },
    props: {
        section: {
            type: Object as PropType<SuitabilityContent>,
            required: true,
        }
    },
    setup(props) {
        const { aoi, taxonomicGroups } = useApp();
        const { section: currentReportSection } = useReport(aoi);
        const { flaggedData, legend, format } = useSuitability(aoi, props.section, taxonomicGroups.value);
        const intactnessContent = computed(() => useIntactness(aoi, props.section).model);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const screens: any = inject('screens');

        const dotPlot = ref<typeof DotPlot>();

        const filterGuildId = ref<number | null>(null);

        // change number of dot plot rows based on screen height
        // use this to control initial collapse and expand status based on row numbers
        const maxExpandedRows = computed(() => {
            if (screens.h < 1000) return 20;
            if (screens.h < 1200) return 25;
            return 16;
        });

        // focus the search box when this section is focused
        watchEffect(() => {
            if (props.section === currentReportSection.value) {
                dotPlot.value?.focusSearch();
            }
        });

        const searchedDatum = ref<RowFlagIconDatum>();
        const onSearch = (data: RowFlagIconDatum[], value: string) => {
            if (value.length < 3) {
                searchedDatum.value = undefined;
            } else {
                /*
                    Search with the following priority:

                        - name starts with value
                        - subtitle starts with value
                        - name matches value anywhere
                        - subtitle matches value anywhere
                */
                searchedDatum.value = data.find(d => d.name?.toLowerCase().startsWith(value.toLowerCase()))
                    ?? data.find(d => d.subtitle?.toLowerCase().startsWith(value.toLowerCase()))
                    ?? data.find(d => d.name?.toLowerCase().match(value.toLowerCase()))
                    ?? data.find(d => d.subtitle?.toLowerCase().match(value.toLowerCase()));
            }
        };

        const updateGuild = (guildId: number | null) => {
            if (guildId == null || guildId === filterGuildId.value) {
                filterGuildId.value = null;
            } else {
                filterGuildId.value = guildId;
            }
        };
        const filterData = computed(() => {
            if (filterGuildId.value == null || filterGuildId.value < 0) {
                return flaggedData.value;
            }
            return (flaggedData.value ?? []).filter(d => d.flags[filterGuildId.value ?? -1]);
        });

        return {
            dotPlotData: filterData,
            dotPlotLegend: legend,
            negativeColor: '#72539C',
            positiveColor: '#478A2B',
            maxExpandedRows,

            dotPlot,
            format,
            intactnessContent,

            onSearch,
            updateGuild,
            openBBProfileUrl,

            filterGuildId,
            searchedDatum,

            indiviudalIntactnessFormat
        };
    }
});
