/**
 * Install any plugins needed for state here.
 */
import { App } from 'vue';
import type { Router } from 'vue-router';
import { useAppRouter } from './useApp';

export default {
    install: async (app: App, options: { router: Router }): Promise<App> => {
        // App State plugin uses same router as the App
        app.use(options.router);
        // Top level route views routed to based on appState.
        const router = options.router;
        useAppRouter(router);
        return app;
    }
};
