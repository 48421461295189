import type dataFetchApi from '@/services/data';
import _ from 'lodash';
import * as t from 'io-ts';
import { parse } from '@dha/io-ts-parse';

// TODO: replace this type with Zod typing AND convert all id: string types to this type
export type FootprintCategory =
    'total-human-footprint' |
    'agriculture' |
    'energy' |
    'forestry' |
    'transportation' |
    'human-created-waterbodies' |
    'urban-industrial' |
    'forestry-recovered' |
    'total-human-footprint-recovered';

export const footprintCategoryById: { [x: number]: FootprintCategory } = {
    1: 'total-human-footprint',
    2: 'agriculture',
    3: 'energy',
    4: 'forestry',
    5: 'human-created-waterbodies',
    6: 'transportation',
    7: 'urban-industrial',
    8: 'forestry-recovered',
    // 9: 'total-human-footprint-recovered'
};
export type FootprintDatum = {
    id: number;
    name: string;
    value: number;
    upperConfidenceInterval: number | null;
    lowerConfidenceInterval: number | null;
}
export type FootprintKeyedDatum = FootprintDatum & {
    key: string;
}

export type FootprintByYearDatum = {
    year: number;
    values: FootprintKeyedDatum[];
}

const FootprintChangeTS = t.type({
    endPct: t.number,
    diffPct: t.number,
    endArea: t.number,
    endYear: t.number,
    diffArea: t.number,
    startPct: t.number,
    startArea: t.number,
    startYear: t.number,
    categoryId: t.number,
    categoryName: t.string,
});
export type FootprintChangeIO = t.TypeOf<typeof FootprintChangeTS> & {
    id: FootprintCategory
};

type DataApi = Pick<typeof dataFetchApi, 'fetchFootprintTrend' | 'fetchFootprintTrendCSV' | 'fetchFootprintChange'>;
type FootprintApi = {
    fetchFootprintTrend: (aoi: number) => Promise<FootprintByYearDatum[]>;
    fetchFootprintTrendCSV: (aoi: number) => Promise<string>;
    fetchFootprintChange: (aoi: number) => Promise<FootprintChangeIO[]>;
}

function keyValuesByCategory(values: FootprintDatum[]): FootprintKeyedDatum[] {
    return _(values).filter(v => footprintCategoryById[v.id] !== undefined).map(v => ({
        ...v,
        key: footprintCategoryById[v.id] || v.id,
        value: v.value / 100,
        lowerConfidenceInterval: v.lowerConfidenceInterval && v.lowerConfidenceInterval / 100,
        upperConfidenceInterval: v.upperConfidenceInterval && v.upperConfidenceInterval / 100
    })).value();
}

export default (data: DataApi): FootprintApi => ({
    fetchFootprintChange: async (aoiId: number): Promise<FootprintChangeIO[]> => {
        const apiData = await data.fetchFootprintChange(aoiId);
        const parsedData = parse(t.array(FootprintChangeTS), apiData);
        const addCatId = _.map(parsedData, (d: t.TypeOf<typeof FootprintChangeTS>) => ({
            ...d,
            id: footprintCategoryById[d.categoryId]
        }));
        return addCatId;
    },
    fetchFootprintTrend: async (aoiId: number): Promise<FootprintByYearDatum[]> => {
        const apiData = await data.fetchFootprintTrend(aoiId);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const parsedData = apiData as Array<any>;
        // TODO: Use IO-TS for parsing/mapping/validating
        const mappedData = _.map(parsedData, (d) => ({
            ...d,
            values: keyValuesByCategory(d.values),
        }));
        return mappedData as FootprintByYearDatum[];
    },
    fetchFootprintTrendCSV: async (aoiId: number): Promise<string> => {
        const apiData = await data.fetchFootprintTrendCSV(aoiId);
        return apiData as string;
    }
});
