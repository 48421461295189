import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d463e31a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "container",
  class: "container"
}
const _hoisted_2 = {
  ref: "drawGroup",
  class: "draw-group"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = ["cx", "cy", "fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createElementBlock("svg", {
      ref: "svg",
      style: _normalizeStyle({
                width: `${_ctx.svgWidth}px`,
                height: `${_ctx.svgHeight}px`
            })
    }, [
      _createElementVNode("g", _hoisted_2, null, 512),
      (_ctx.hoveredPoints)
        ? (_openBlock(), _createElementBlock("g", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.hoveredPoints, (point) => {
              return (_openBlock(), _createElementBlock("circle", {
                key: point.key,
                cx: _ctx.xScale(point.date),
                cy: _ctx.yScale(point.value),
                r: "5",
                fill: _ctx.styles[point.key].color || 'black'
              }, null, 8, _hoisted_4))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ], 4)),
    _createElementVNode("div", {
      ref: "overlay",
      class: "base-overlay",
      style: _normalizeStyle({
                width: `${_ctx.svgWidth}px`,
                height: `${_ctx.svgHeight}px`
            }),
      onMousemove: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onMousemove && _ctx.onMousemove(...args))),
      onMouseout: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onMouseout && _ctx.onMouseout(...args)))
    }, null, 36),
    (_ctx.hoveredPoints)
      ? _renderSlot(_ctx.$slots, "tooltip", {
          key: 0,
          class: "tooltip-slot",
          style: _normalizeStyle({
                bottom: `calc(4rem + ${_ctx.tooltipY}px)`,
                left: `${_ctx.xScale(_ctx.hoveredPoints[0].date)}px`,
                transform: 'translate(-50%, 0)'
            }),
          title: _ctx.hoveredTitle,
          rows: _ctx.hoveredRows
        }, undefined, true)
      : _createCommentVNode("", true)
  ], 512))
}