<template>
    <!-- Views must specify the app layout, for now -->
    <router-view />
</template>

<style lang="postcss">
@import './assets/styles/collapsible.css';
@import './assets/styles/hamburger.css';
@import './assets/styles/tailwind.css';
@import './assets/styles/custom.css';
@import './vendor/abmi-map.css';
@import './assets/fonts/fonts.css';
@import url('https://fonts.googleapis.com/css2?family=Domine:wght@400;600;700&family=Montserrat:wght@100;300;400;500;600;700&display=swap');
@import url('../node_modules/@mdi/font/css/materialdesignicons.css');

#app {
    @apply font-montserrat text-text text-size-paragraph h-full w-full mx-auto min-h-app;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.side-panel {
    @apply z-50 flex-grow-0 flex-shrink-0;
    .titles {
        @apply mt-5;
    }

    img {
        @apply cursor-pointer inline-block;
    }
}

h1, h2, h3 {
    @apply font-montserrat font-semibold;
}

</style>
