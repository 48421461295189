import { IntactnessCategory } from '@/api/intactness';

import { ContentIO, ConfigIO } from '@/api/report';

import { Section } from './Content';

export default class SpeciesCalloutContent extends Section {
    category:IntactnessCategory;
    config:ConfigIO;
    constructor(contentIo:ContentIO, config:ConfigIO, category:IntactnessCategory) {
        super(contentIo, config);
        this.category = category;
        this.config = config;
        this.hideFromMenu = false;
    }
}
