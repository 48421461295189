import { IntactnessCategory, Taxonomy } from '@/api/intactness';
import { ContentIO, OverallSuitabilityConfigIO } from '@/api/report';

import { Section } from './Content';

export default class OverallSuitabilityContent extends Section {
    config:OverallSuitabilityConfigIO;
    taxonomies:Record<Taxonomy, ContentIO>;
    category:IntactnessCategory = 'biodiversity';
    constructor(contentIo:ContentIO, config:OverallSuitabilityConfigIO, taxonomiesContent: Record<Taxonomy, ContentIO>) {
        super(contentIo, config);
        this.config = config;
        this.taxonomies = taxonomiesContent;
    }
}
