import { select } from 'd3-selection';
import _ from 'lodash';

type ValueType = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
    value: number;
}

type NameValueType = ValueType & {
    name: string;
}

export function getNLowestAndHighest<T extends ValueType>(
    sortedData: T[],
    n: number
): T[] {
    if (sortedData.length <= n) return sortedData;
    if (n <= 0) return [];

    const [negative, positive] = _.partition(sortedData, d => d.value < 0);
    if (positive.length < n / 2) {
        return [
            ...negative.slice(0, n - positive.length),
            ...positive
        ];
    }
    if (negative.length < n / 2) {
        return [
            ...negative,
            ...positive.slice(-(n - negative.length))
        ];
    }
    return [
        ...negative.slice(0, Math.ceil(n / 2)),
        ...positive.slice(-n / 2)
    ];
}

export function mustInclude<T extends NameValueType>(
    sortedData: T[],
    datum: T
): T[] {
    if (sortedData.find(d => d.name === datum.name)) return sortedData;

    const cloneData = _.cloneDeep(sortedData);
    const idx = _.sortedIndexBy(cloneData, datum, 'value');
    if (idx >= cloneData.length) {
        cloneData.splice(cloneData.length - 1, 1, datum);
    } else if (datum.value >= 0 || cloneData[idx].value === datum.value) {
        cloneData.splice(idx, 1, datum);
    } else {
        cloneData.splice(Math.max(idx - 1, 0), 1, datum);
    }

    return cloneData;
}

export function newDefsID(prefix: string): string {
    let idCount = 0;
    let gradientID = `${prefix}-${idCount}`;
    let gradientExistsSelection = select(`#${prefix}-${idCount}`);
    while (!gradientExistsSelection.empty() && idCount < 1000) {
        idCount += 1;
        gradientID = `${prefix}-${idCount}`;
        gradientExistsSelection = select(`#${gradientID}`);
    }
    return gradientID;
}
