
import { defineComponent, ref, watchEffect, PropType } from 'vue';
import _ from 'lodash';

import { draw, FeaturesOptions, DrawOptions, ABMIGeo } from './draw';

export default defineComponent({
    name: 'RegionMapSVG',
    props: {
        width: {
            type: Number,
            required: true
        },
        height: {
            type: Number,
            required: true
        },
        provinceFeatures: {
            type: Object as PropType<ABMIGeo>,
            default: () => undefined,
            required: false,
        },
        regionFeatures: {
            type: Object as PropType<ABMIGeo>,
            default: () => undefined,
            required: false,
        },
        areaFeatures: {
            type: Object as PropType<ABMIGeo>,
            default: () => undefined,
            required: false,
        },
        flyoutPosition: {
            type: Array,
            default: () => ([]),
            validator: (val: string[]): boolean => {
                if (!_.isArray(val) || val.length !== 2) {
                    return false;
                }

                if (!['left', 'right'].includes(val[0]) || !['top', 'center', 'bottom'].includes(val[1])) {
                    return false;
                }

                return true;
            }
        },
        showAreas: {
            type: Boolean,
            default: true,
        },
        highlightColor: {
            type: String,
            default: undefined,
        },
        fillColor: {
            type: String,
            default: undefined,
        },
        onAreaClick: {
            type: Function,
            default: undefined,
        },
        showZoominMap: {
            type: Boolean,
            default: true,
        },
    },
    setup(props) {
        const svgMapRegion = ref<SVGElement>();
        watchEffect(() => {
            draw(
                svgMapRegion.value ?? null,
                { width: props.width, height: props.height },
                {
                    province: props.provinceFeatures,
                    region: props.regionFeatures,
                    area: props.areaFeatures,
                } as FeaturesOptions,
                {
                    flyoutPosition: props.flyoutPosition,
                    showAreas: props.showAreas,
                    highlightColor: props.highlightColor,
                    fillColor: props.fillColor,
                    onAreaClick: props.onAreaClick,
                    showZoominMap: props.showZoominMap,
                } as DrawOptions
            );
        });

        return {
            svgMapRegion,
        };
    }
});

