
import { computed, defineComponent, PropType } from 'vue';
import useApp from '@/composables/state/useApp';
import useReport from '@/composables/state/report/useReport';
import useOverallSuitability from '@/composables/state/report/useOverallSuitability';
import Beeswarm from '@/components/Beeswarm/Beeswarm.vue';
import Tooltip from '@/components/Tooltip.vue';
import SectionHeader from '@/modules/report/SectionHeader.vue';
import { OverallSuitabilityContent } from '@/model/report';
import { BeeswarmGroup } from '@/components/Beeswarm/types';
import formatter from '@dha/number-format';
import { indiviudalIntactnessFormat, indiviudalIntactnessNoSignFormat } from '@/services/content/config';

export default defineComponent({
    name: 'OverallSuitabilitySection',
    components: {
        Beeswarm,
        SectionHeader,
        Tooltip
    },
    props: {
        section: {
            type: Object as PropType<OverallSuitabilityContent>,
            required: true,
        }
    },
    setup(props) {
        const { aoi, taxonomicGroups } = useApp();
        const { selectSection, section } = useReport(aoi);
        const suitabilityContent = computed(() => useOverallSuitability(aoi, props.section, taxonomicGroups.value).model);
        // const { model: suitabilityContent, overallData: beeswarmData }
        const clickLabelFunction = (d: BeeswarmGroup) => `Go to list of ${d.title.toLowerCase()}`;
        const beeswarmData = computed(() => suitabilityContent.value.overallData);
        const onSearch = (id: string) => {
            selectSection(`${id}Suitability`);
        };
        const downloadCSVData = () => {
            suitabilityContent.value.downloadCSV()
                .then((csvStr) => {
                    const anchor = document.createElement('a');
                    anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csvStr)}`;
                    anchor.target = '_blank';
                    anchor.download = `${suitabilityContent.value.areaName}_Intactness.csv`;
                    anchor.click();
                });
        };

        const format = computed(() => section.value?.config.format);

        const customTooltipFunc = (value:string | number) => {
            /* values are in -100, to 100 range, and need to use pct format, so need to scale back to -1 to 1 */
            const valueInPct = (Number(value ?? 0));
            const intactness1 = formatter(valueInPct, indiviudalIntactnessFormat);
            const intactness2 = formatter(1 - Math.abs(valueInPct), indiviudalIntactnessNoSignFormat);

            return `${intactness1} (${intactness2} intact)`;
        };
        return {
            beeswarmData,
            clickLabelFunction,
            onSearch,
            format,
            suitabilityContent,
            downloadCSVData,
            indiviudalIntactnessFormat,
            customTooltipFunc
        };
    }
});
