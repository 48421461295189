import { reactive, Ref, watchEffect } from 'vue';
// import WetlandStatusContent from '@/model/report/WetlandStatusContent';
import { Area } from '@/model/Area';
import { Section } from '@/model/report';
import GenearlRegionModel from '@/model/GeneralRegionInfo';

/**
 * Builds the Model object from the given content using data for the given area.
 * @param aoi The area selected to load data for
 * @param sectionIo The section report content
 * @returns A reactive Model with templated Area of interest content
 */
export default function useGeneralRegion(aoi: Ref<Area | undefined>, content: Section):GenearlRegionModel {
    const model = reactive<GenearlRegionModel>(new GenearlRegionModel(content));
    const loadWetlandData = async () => {
        if (aoi.value) {
            model.load(aoi.value);
        }
    };
    watchEffect(() => loadWetlandData());
    return model;
}
