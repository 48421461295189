
import { computed, defineComponent, PropType } from 'vue';
import useApp from '@/composables/state/useApp';
import BaseSection from './BaseSection.vue';
// Define internal interface needed, impelmentable by different models.
interface ChapterInfo {
    title: string;
    description: string;
    imageSrc?: string;
    imageCredit?: string;
}
export default defineComponent({
    name: 'ChapterTitlePage',
    components: {
        BaseSection
    },
    props: {
        section: {
            type: Object as PropType<ChapterInfo>,
            default: null,
        }
    },
    setup(props) {
        const { aoi } = useApp();
        const regionCss = computed(
            () => ((aoi.value && aoi.value.regionTypeCode) ? `bg-${aoi.value?.regionTypeCode}-main` : 'text-default-main')
        );
        const imageUrlPrefix = process.env.VUE_APP_ABMI_CMS_IMAGE_URL;
        // eslint-disable-next-line global-require
        const chapterImg = computed(() => (props.section.imageSrc ? `${imageUrlPrefix}${props.section.imageSrc}` : null)); // require('@/assets/img/chapterTitle.png')));
        return { chapterImg, regionCss };
    }
});
