
import _ from 'lodash';
import { computed, defineComponent, PropType, ref, watchEffect } from 'vue';
import { utcFormat } from 'd3-time-format';
import { ScaleLinear, ScaleTime } from 'd3-scale';
import observeResize from '@/composables/util/observeResize';
import { FormatOptions } from '@dha/number-format';

import { LineChartDatum, LineStyle } from './types';
import draw, { DEFAULT_DRAW_OPTIONS } from './draw';
import { getHoverPoints } from './helpers';

export default defineComponent({
    name: 'LineChart',
    props: {
        data: {
            type: Array as PropType<LineChartDatum[][]>,
            default: () => []
        },
        styles: {
            type: Object as PropType<Record<string, LineStyle>>,
            default: () => ({})
        },
        showArea: {
            type: Boolean,
            default: false
        },
        yDomain: {
            type: Array as PropType<number[]>,
            default: null
        },
        leftMargin: {
            type: Number,
            default: DEFAULT_DRAW_OPTIONS.leftMargin
        },
        rightMargin: {
            type: Number,
            default: DEFAULT_DRAW_OPTIONS.rightMargin
        },
        yMargin: {
            type: Number,
            default: DEFAULT_DRAW_OPTIONS.yMargin
        },
        xFormat: {
            type: String,
            default: DEFAULT_DRAW_OPTIONS.xFormat
        },
        yFormat: {
            type: Object as PropType<FormatOptions>,
            default: DEFAULT_DRAW_OPTIONS.yFormat
        }
    },
    setup(props) {
        const container = ref<HTMLDivElement>();
        const svg = ref<SVGElement>();
        const drawGroup = ref<SVGGElement>();

        // setup resize observers/computed sizes
        const {
            dimensions
        } = observeResize(container);

        const svgWidth = computed(() => dimensions.value.width);
        const svgHeight = computed(() => dimensions.value.height);

        // draw on data update
        const xScale = ref<ScaleTime<number, number>>();
        const yScale = ref<ScaleLinear<number, number>>();
        const dataByDate = computed(() => _(props.data)
            .flatten()
            .sortBy('date')
            .reduce<LineChartDatum[][]>((acc, d) => {
                const lastArr = acc.slice(-1)[0];
                if (!lastArr) {
                    acc.push([d]);
                } else if (lastArr[0].date.getTime() === d.date.getTime()) {
                    lastArr.push(d);
                } else {
                    acc.push([d]);
                }
                return acc;
            }, []));

        watchEffect(() => {
            const { xScale: x, yScale: y } = draw(
                drawGroup.value,
                props.data,
                {
                    width: svgWidth.value,
                    height: svgHeight.value,
                    styles: props.styles,
                    yDomain: props.yDomain,
                    leftMargin: props.leftMargin,
                    rightMargin: props.rightMargin,
                    yMargin: props.yMargin,
                    xFormat: props.xFormat,
                    yFormat: props.yFormat,
                }
            );
            xScale.value = x;
            yScale.value = y;
        }, { flush: 'post' });

        // setup tooltip
        const hoveredPoints = ref<LineChartDatum[]>();
        const hoveredTitle = computed(
            () => (hoveredPoints.value ? utcFormat(props.xFormat)(hoveredPoints.value[0].date) : '')
        );
        const hoveredRows = computed(
            () => (hoveredPoints.value
                ? _(hoveredPoints.value)
                    .map(d => ({
                        label: props.styles[d.key].label ?? '',
                        value: d.value,
                        format: props.yFormat
                    }))
                    .sortBy('value')
                    .reverse()
                    .value()
                : null)
        );
        const tooltipY = computed(() => {
            if (!yScale.value || !hoveredPoints.value) return 0;
            return props.yMargin * 2
                + yScale.value.range()[0]
                - yScale.value.range()[1]
                - yScale.value(_.maxBy(hoveredPoints.value, d => d.value)?.value as number);
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const onMousemove = _.throttle(function (this: any, event: MouseEvent) {
            if (xScale.value && yScale.value) {
                const {
                    left
                } = this.$refs.overlay.getBoundingClientRect();

                hoveredPoints.value = getHoverPoints(dataByDate.value, event.clientX - left, xScale.value);
            }
        }, 15, { trailing: false });
        const onMouseout = () => {
            hoveredPoints.value = undefined;
        };

        return {
            // resize
            svgWidth,
            svgHeight,

            // scales
            xScale,
            yScale,

            // tooltip
            dataByDate,
            hoveredPoints,
            hoveredTitle,
            hoveredRows,
            tooltipY,
            onMousemove,
            onMouseout,

            // template refs
            container,
            svg,
            drawGroup
        };
    }
});
