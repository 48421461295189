import { Component } from 'vue';
import { Section, Chapter } from '@/model/report/Content';
import {
    AreaOfAnalysisContent,
    SuitabilityContent,
    OverallSuitabilityContent,
    FootprintStatusContent,
    // FootprintTrendsContent,
    FootprintMappedContent,
    SpeciesCalloutContent,
    IntactnessContent,
    IntactnessChapter as IntactnessChapterContent
} from '@/model/report';
import WetlandStatusContent from '@/model/report/WetlandStatusContent';
import WetlandMappedContent from '@/model/report/WetlandMappedContent';
import AreaOfAnalysisSection from '../report/AreaOfAnalysisSection.vue';
import FootprintTrendSection from '../report/FootprintTrendSection.vue';
import FootprintStatusSection from '../report/FootprintStatusSection.vue';
import FootprintMappedSection from '../report/FootprintMappedSection.vue';
import WetlandStatusSection from '../report/WetlandStatusSection.vue';
import CitationSection from '../report/CitationSection.vue';
import WetlandMappedSection from '../report/WetlandMappedSection.vue';
import SuitabilitySection from '../report/SuitabilitySection.vue';
import OverallSuitabilitySection from '../report/OverallSuitabilitySection.vue';
import IntactnessSection from '../report/IntactnessSection.vue';
import SpeciesCalloutSection from '../report/SpeciesCalloutSection.vue';
import MethodsSection from '../report/MethodsSection.vue';
import IntactnessMapped from '../report/IntactnessMapped.vue';
import BaseSection from '../report/BaseSection.vue';
import BaseChapter from '../report/BaseChapter.vue';
import FootprintChapter from '../report/FootprintChapter.vue';
import WetlandChapter from '../report/WetlandChapter.vue';
import IntactnessChapter from '../report/IntactnessChapter.vue';

// Mapping of Report Rendering Module to the Section Model to Render
const reportModuleSelect = (section: Section): Component | string => {
    if (section.id === 'methods') {
        return BaseChapter;
    }
    if (section.id.startsWith('subMethod')) {
        return MethodsSection;
    }
    if (section.id === 'overallIntactnessMapped') {
        return IntactnessMapped;
    }
    if (section.id === 'footprint') {
        return FootprintChapter;
    }
    if (section instanceof IntactnessChapterContent) {
        return IntactnessChapter;
    }
    if (section instanceof AreaOfAnalysisContent) {
        return AreaOfAnalysisSection;
    }
    if (section instanceof SuitabilityContent) {
        return SuitabilitySection;
    }
    if (section instanceof OverallSuitabilityContent) {
        return OverallSuitabilitySection;
    }
    if (section.id === 'footprintTrends') {
        return FootprintTrendSection;
    }
    if (section instanceof FootprintStatusContent) {
        return FootprintStatusSection;
    }
    if (section instanceof FootprintMappedContent) {
        return FootprintMappedSection;
    }
    if (section.id === 'wetland') {
        return WetlandChapter;
    }
    if (section.id === 'citation') {
        return CitationSection;
    }
    if (section instanceof WetlandStatusContent) {
        return WetlandStatusSection;
    }
    if (section instanceof WetlandMappedContent) {
        return WetlandMappedSection;
    }

    if (section instanceof IntactnessContent) {
        return IntactnessSection;
    }
    if (section instanceof SpeciesCalloutContent) {
        return SpeciesCalloutSection;
    }
    if (section instanceof Chapter) {
        return BaseChapter;
    }
    return BaseSection;
};

export default reportModuleSelect;
