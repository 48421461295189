import _ from 'lodash';
import type { AreaIO, AreaSectionIO, AreasOfInterestIO, RegionsCategoryIO, RegionIO, UpperChar } from '@/api/aoi';
import type { Geometry, GeoJsonProperties, FeatureCollection } from 'geojson';

/**
 * Defines the type of Geojson to expect from the geojson files
 */
export type ABMIGeo = FeatureCollection<Geometry, GeoJsonProperties>;
class GeoJson {
    static prefix? : string;
}
export class Region implements RegionIO {
    regionId = 0;
    name = '';
    aoiCount = 0;
    constructor(data: RegionIO) {
        Object.assign(this, data);
    }
    get id(): number {
        return this.regionId;
    }
    get geojsonUrl(): string {
        return GeoJson.prefix?.startsWith('http') ? `${GeoJson.prefix}${this.regionId}` : `${GeoJson.prefix}${this.regionId}.geojson`;
    }
}

/**
 * 2nd Level grouping of Regions Category
 * Counties & Municipalities,
 * First Nations Reserves & Metis Settlements, etc
 */
export class RegionsCategory implements RegionsCategoryIO {
    category: RegionsCategoryIO
    regions: Region[];
    constructor(regionsCatIO: RegionsCategoryIO) {
        this.category = regionsCatIO;
        this.regions = _.map(regionsCatIO.regions, (region) => new Region(region));
    }
    get name(): string {
        return this.category.name;
    }
    get id(): number {
        return this.category.id;
    }
}

export class AreaSection {
    id: number;
    isAvailable: boolean;
    key: string;
    constructor(sectionIO: AreaSectionIO) {
        this.id = sectionIO.id;
        this.isAvailable = sectionIO.isAvailable;
        this.key = sectionIO.key;
    }
}

export class SppHighlightCountType {
    taxonomy?: string;
    count?: number;
}

export class Area {
    aoiName = '';
    aoiId = 0;
    regionType?: string;
    regionTypeCode?: string;
    region?: Region;
    sections: AreaSection[];
    sppHighlightCount?: SppHighlightCountType[];
    initial?: UpperChar;
    hasHfTrend?: boolean;
    // An area can belong to a sub-region area
    subRegion?: Area;
    constructor(aoi: AreaIO) {
        Object.assign(this, aoi);
        this.sections = _.map(aoi.sections, s => new AreaSection(s));
        this.region = aoi.region && new Region(aoi.region);
        this.subRegion = aoi.subRegion && new Area(aoi.subRegion);
        this.sppHighlightCount = aoi.sppHighlightCount;
    }
    get name(): string {
        return this.aoiName;
    }
    get id(): number {
        return this.aoiId;
    }
    get geojsonUrl(): string {
        return GeoJson.prefix?.startsWith('http') ? `${GeoJson.prefix}${this.id}` : `${GeoJson.prefix}${this.id}.geojson`;
    }
    get subregionGeojsonUrl(): string {
        return GeoJson.prefix?.startsWith('http') ? `${GeoJson.prefix}subregion/${this.id}` : `${GeoJson.prefix}${this.id}.geojson`;
    }
}

// Any groupof areas.
// Used by seacrh for all Areas, groupings in menu, etc
export class Areas extends Array<Area> {
    name = '';

    get areasById(): Record<number, Area> {
        return _.keyBy(this, 'id');
    }
    getArea(area?: AreaIO | number):Area | undefined {
        const id = (area as AreaIO).aoiId || area as number;
        if (id) {
            return this.areasById[id];
        }
        return undefined;
    }
}

/**
 * The Top-level Factory methods used by state to create the model objects.
 */
export class AreasOfInterest implements AreasOfInterestIO {
    data: RegionsCategory[];
    geojsonUrlPrefix = '';
    regions: Region[];
    constructor(aoiIO: AreasOfInterestIO) {
        Object.assign(this, aoiIO);
        this.data = _.map(aoiIO.data, (cat) => new RegionsCategory(cat));
        GeoJson.prefix = this.geojsonUrlPrefix;
        this.regions = _(this.data)
            .map((cat) => cat.regions)
            .flatten().value();
    }
    get regionsById(): Record<number, Region> {
        return _.keyBy(this.regions, 'id');
    }
    getRegion(regionId?: number): Region | undefined {
        if (!regionId) {
            return undefined;
        }
        return this.regionsById[regionId];
    }
}
