import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6ab2843a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "section partnerSize partnerLogos" }
const _hoisted_2 = { class: "container has-text-centered" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "logoGrid" }
const _hoisted_5 = ["href"]
const _hoisted_6 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h4", {
        innerHTML: _ctx.sponsor.header
      }, null, 8, _hoisted_3),
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sponsor.logos, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "logoBox bg-white"
          }, [
            _createElementVNode("a", {
              href: item.url,
              target: "_blank",
              class: "btn btn-link"
            }, [
              _createElementVNode("img", {
                src: require(`@/assets/partner-logos/${item.src}`),
                class: "mg-responsive",
                alt: "image"
              }, null, 8, _hoisted_6)
            ], 8, _hoisted_5)
          ]))
        }), 128))
      ])
    ])
  ]))
}