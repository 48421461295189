import { IntactnessCategory, IntactnessDatum, SpeciesInfoIO } from '@/api/intactness';
import { fetchSpeciesContent } from '@/services/content/abmi-profile';
import { Area } from './Area';
import { TemplateProps, templateText } from './helpers';
import SpeciesCalloutContent from './report/SpeciesCalloutContent';
import SectionContentModel from './SectionContent';

// this.highlightText = 'As of 2017, the total human footprint across Wheatland County was <span class="font-semibold">10%</span>';
// this.imageSrc = 'https://images.unsplash.com/photo-1568162603664-fcd658421851?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=973&q=80';

/**
  * Wraps Content and Data and provides interface used by UI
  */
export default class SpeciesCalloutModel extends SectionContentModel {
    content: SpeciesCalloutContent;
    data?: (SpeciesInfoIO | null);
    // intactnessData?: IntactnessDatum;
    aoi?: Area;
    category?: IntactnessCategory;
    scientificName = '';
    constructor(content: SpeciesCalloutContent) {
        super(content);
        this.content = content;
    }
    get title(): string {
        return this.data?.title ?? '';
    }
    get imageSrc(): string | undefined {
        return this.data?.imageSrc;
    }
    get highlightText(): string | undefined {
        if (!this.data?.imageText) {
            return undefined;
        }
        return templateText(this.data?.imageText, this.templateProps, this.content.config.format);
    }
    get photoCredit(): string | undefined {
        return this.data?.photoCredit;
    }
    get description(): string | undefined {
        return this.data?.description;
    }
    get hasSpeciesInfo(): boolean {
        return this.data != null;
    }
    async loadContent(scientificName: string): Promise<void> {
        this.scientificName = scientificName;
        this.data = await fetchSpeciesContent(scientificName);
    }
    // // Species Callout can show Intactness vars
    // loadIntactness(data: (IntactnessDatum | undefined)[]): void {
    //     const currentGroup = data.find(x => x?.sppGroup === this.category);
    //     this.intactnessData = currentGroup;
    // }

    get templateProps(): TemplateProps {
        return {
            category: this.category || null,
            areaName: this.aoi?.name || null,
            // intactnessValue: this.intactnessData?.intactnessIndex || null,
            // ...localData
        };
    }
}
