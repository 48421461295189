/* eslint-disable import/no-named-as-default-member */
import _ from 'lodash';
import { fetchContent as abmiCms } from './abmi';
import { fetchSpeciesContent } from './abmi-profile';
import { fetchConfig as defaultConfig } from './config';

// Define the expected keys coming from this API
export const contentKeys = {
    area: 'area',
    methods: 'methods',
    footprint: 'footprint',
    footprintTrends: 'footprintTrends',
    footprintMapped: 'footprintMapped',
    // footprintDetailed: 'footprintDetailed',
    footprintStatus: 'footprintStatus',

    wetland: 'wetland',
    wetlandStatus: 'wetlandStatus',
    wetlandMapped: 'wetlandMapped',

    introductionIntactness: 'introductionIntactness',
    overallIntactness: 'overallIntactness',
    overallIntactnessMapped: 'overallIntactnessMapped',
    overallSuitability: 'overallSuitability',
    overallSpecies: 'overallSpecies',
    mammals: 'mammals',
    mammalsIntactness: 'mammalsIntactness',
    mammalsSuitability: 'mammalsSuitability',
    mammalsSpecies: 'mammalsSpecies',
    birds: 'birds',
    birdsIntactness: 'birdsIntactness',
    birdsSuitability: 'birdsSuitability',
    birdsSpecies: 'birdsSpecies',
    vplants: 'vplants',
    vplantsIntactness: 'vplantsIntactness',
    vplantsSuitability: 'vplantsSuitability',
    vplantsSpecies: 'vplantsSpecies',
    reference: 'reference',
    citation: 'citation',
    terms: 'terms',
    contactUs: 'contactUs',
    report: 'report',
    // // show on landing page
    popup: 'popup',
    introduction: 'introduction',
};
export type ContentKeys = keyof typeof contentKeys;
/**
 * Keys that are not in the ABMI CMS are stubbed here for now.
 */
const abmiStub = {
    report: {
        id: 'report',
        title: 'Status of Land Cover & Biodiversity',
        subtitle: 'Assessment of human footprint, species, and habitat'
    }
};
export const fetchContent = async (name = 'default', lang?: string | undefined): Promise<unknown> => {
    const abmiContent = await abmiCms(name, lang) as { report: typeof abmiStub.report };
    abmiContent.report = abmiContent.report || abmiStub.report;
    // Map the id to the contentKey
    const keyedContent = _.mapValues(abmiContent, (c, id) => ({ ...c, id: (id as ContentKeys) }));
    return keyedContent;
};

export async function fetchConfig(): Promise<unknown> {
    return defaultConfig(contentKeys);
}

const fetchContentApi = {
    fetchContent,
    fetchSpeciesContent,
    fetchConfig
};

export default fetchContentApi;
