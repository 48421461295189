import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-67c8a434"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "section-header" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = {
  key: 3,
  class: "mt-4"
}
const _hoisted_7 = {
  key: 4,
  class: "mt-6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showTitle)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.isChapterTitle)
            ? (_openBlock(), _createElementBlock("h1", _hoisted_2, _toDisplayString(_ctx.section.title), 1))
            : (_openBlock(), _createElementBlock("h2", _hoisted_3, _toDisplayString(_ctx.section.title), 1))
        ], 64))
      : _createCommentVNode("", true),
    (_ctx.showDescription)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["cms-text", { 'compact-text': _ctx.showReadMore && !_ctx.showAllText }]),
          innerHTML: _ctx.section.description
        }, null, 10, _hoisted_4))
      : _createCommentVNode("", true),
    (_ctx.showAlternativeDescription)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: _normalizeClass([{ 'compact-text': _ctx.showReadMore && !_ctx.showAllText }, "cms-text"]),
          innerHTML: _ctx.section.descriptionAlternative
        }, null, 10, _hoisted_5))
      : _createCommentVNode("", true),
    (_ctx.showReadMore)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("a", {
            class: "read-more font-semibold cursor-pointer",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showAllText = !_ctx.showAllText))
          }, _toDisplayString(_ctx.showAllText ? 'Read Less' : 'Read More'), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.showBottomDivider)
      ? (_openBlock(), _createElementBlock("hr", _hoisted_7))
      : _createCommentVNode("", true)
  ]))
}