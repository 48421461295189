import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-27f9262c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["width", "height"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    ref: "svgMapRegion",
    class: _normalizeClass(["svgMapRegion", { withAreas: _ctx.showAreas }]),
    width: _ctx.width,
    height: _ctx.height
  }, null, 10, _hoisted_1))
}