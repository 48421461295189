
import { defineComponent, PropType, computed } from 'vue';
import type { IntactnessChapter as IntactnessChapterContent } from '@/model/report';

import useIntactness from '@/composables/state/report/useIntactness';
import useApp from '@/composables/state/useApp';
import ChapterTitlePage from './ChapterTitlePage.vue';

export default defineComponent({
    name: 'IntactnessChapter',
    components: {
        ChapterTitlePage
    },
    props: {
        section: {
            type: Object as PropType<IntactnessChapterContent>,
            default: null,
        }
    },
    setup(props) {
        const { aoi } = useApp();
        const intactnessContent = computed(() => useIntactness(aoi, props.section).model);
        return { intactnessContent };
    }
});
