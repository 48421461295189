import { Report, Section } from '@/model/report/Content';
import { createReport } from '@/model/report';
import { Area } from '@/model/Area';
import { ref, watchEffect, reactive, computed, Ref, onMounted, ComputedRef } from 'vue';

import { useRoute, useRouter } from 'vue-router';

const reportState: {
    section: string
} = reactive({
    section: ''
});
export type ReportState = typeof reportState;
export function useReportState() : ReportState {
    return reportState;
}
export function useReportRouter():void {
    const router = useRouter();
    const route = useRoute();

    const updateHashFromSection = (section: string) => {
        const hash = section ? `#${section}` : '';
        if (route.name === 'Report') {
            router.replace({ hash });
        }
    };
    const updateSectionFromHash = (hash: string) => {
        reportState.section = hash.replace('#', '');
    };

    watchEffect(() => updateHashFromSection(reportState.section));
    onMounted(() => updateSectionFromHash(route.hash));
}

type ReportRefs = {
    report: Ref<Report | undefined>;
    loadReport: (aoiToLoad?: Area | undefined) => Promise<void>;
    state:{ section: string; };
    selectSection: (selectedSection?: Section | string | undefined) => void,
    section: ComputedRef<Section | undefined>
};

// the actual report ref is singleton.  Only 1 report is ever loaded at a time
// TODO: We could cache these by AOI
const report = ref<Report>();
const reports: Record<number, Promise<Report>> = {};
const currentAoi = ref();
export default function useReport(aoi: Ref<Area | undefined>): ReportRefs {
    const state = useReportState();
    const loadReport = async (aoiToLoad?: Area | undefined) => {
        if (aoiToLoad && currentAoi?.value !== aoiToLoad) {
            currentAoi.value = aoi.value;
            if (!reports[aoiToLoad.id]) {
                reports[aoiToLoad.id] = createReport(aoiToLoad);
            }
            const cachedReport = await reports[aoiToLoad.id];
            if (cachedReport !== report.value) {
                report.value = cachedReport;
            }
        }
    };
    const section = computed(() => report.value?.getSectionById(state.section));
    const selectSection = (selectedSection?: Section | string | undefined) => {
        if (typeof selectedSection === 'string') {
            state.section = selectedSection;
        } else {
            state.section = selectedSection?.id || '';
        }
    };

    watchEffect(() => loadReport(aoi.value));
    return {
        report,
        loadReport,
        state,
        selectSection,
        section
    };
}
