import { select } from 'd3-selection';
import { appendIfNotExists } from '../helpers';

export type ThermometerDrawOptions = {
    height: number;
    itemHeight: number;
    itemWidth: number;
    currentValue: number | null;
    colors: Array<string>
}

type ThermometerColor = {
    color: string,
    isSelected: boolean,
    index: number
}

function drawThermometer(
    svg: Element | null,
    data: Array<ThermometerColor>,
    itemHeight: number,
    itemWidth: number
) {
    function getRectPath(itemData: ThermometerColor) {
        /*
        * using quadratic bezier curve for first and last rectangle corners.
        * This tutorial helped - // this helped - https://medium.com/@dennismphil/one-side-rounded-rectangle-using-svg-fb31cf318d90
        */
        const SELECTED_RECT_INCREMENT = 4;
        const w = itemData.isSelected ? itemWidth : itemWidth - SELECTED_RECT_INCREMENT;
        const h = itemHeight;
        const startX = (itemData.isSelected ? 0 : (SELECTED_RECT_INCREMENT / 2));
        const startY = (itemHeight * itemData.index);
        const curveRadius = 0.4 * itemHeight; // used for arcs in top and bottom elements

        if (itemData.index === 0) {
            return `M${startX} ${startY + curveRadius} q0,${-curveRadius} ${(w / 2)},${-curveRadius} q${w / 2},0 ${w / 2},${curveRadius} v${h - curveRadius} h${-w}  Z`;
        } if (itemData.index === 9) {
            return `M${startX} ${startY} h${w} v${h - curveRadius} q0,${curveRadius} ${-(w / 2)},${curveRadius} q${-(w / 2)},0 ${-(w / 2)},${-curveRadius} Z`;
        }
        return `M${startX} ${startY} h${w} v${h} h${w * -1} Z`;
    }

    const g = appendIfNotExists<SVGGElement>(select(svg ?? null), 'g', 'color-scale-container');
    g.selectAll('*').remove();
    g.attr('width', itemWidth)
        .selectAll('rect.color-scale-item')
        .data(data)
        .enter()
        .append('path')
        .classed('color-scale-item', true)
        .classed('selected', (d) => d.isSelected)
        .attr('d', (d) => getRectPath(d))
        .attr('fill', (d) => d.color);
}

function drawThermometerReading(
    readingcontainer: Element | null,
    currentValue: number,
    currentValueIndex: number,
    height: number,
    itemHeight: number
) {
    const div = appendIfNotExists<HTMLDivElement>(select(readingcontainer ?? null), 'div', 'readingcontainer');

    if (div.select('.reading').empty()) {
        div.append('div').classed('reading', true)
            .style('line-height', `${itemHeight}px`)
            .style('height', `${itemHeight}px`)
            .style('margin-bottom', `${(9 - currentValueIndex) * itemHeight}px`)
            .text(`${currentValue.toFixed(1)}%`);
    } else {
        div.select('.reading')
            .style('line-height', `${itemHeight}px`)
            .style('height', `${itemHeight}px`)
            .style('margin-bottom', `${(9 - currentValueIndex) * itemHeight}px`)
            .text(`${currentValue.toFixed(1)}%`);
    }
}

export default function draw(
    svg: Element | null | undefined,
    readingcontainer: Element | null | undefined,
    drawOptions: ThermometerDrawOptions
): void {
    const { height, itemHeight, itemWidth, colors, currentValue } = drawOptions;

    function isSelected(index:number) {
        if (!currentValue) {
            return false;
        }
        const currentValueIndex = 10 - Math.floor(currentValue / 10);
        if (currentValueIndex >= 10) {
            return index === 9;
        } if (currentValueIndex <= 0) {
            return index === 0;
        }
        return (currentValueIndex - 1) === index;
    }

    const data:ThermometerColor[] = colors.map((c, i) => ({
        color: c,
        isSelected: isSelected(i),
        index: i
    }));

    const currentValueIndex = data.findIndex(v => v.isSelected);

    drawThermometer(svg ?? null, data, itemHeight, itemWidth);
    if (currentValue) {
        drawThermometerReading(readingcontainer ?? null, currentValue, currentValueIndex, height, itemHeight);
    }
}
