import Model from '@/model';
import type { AppInfo } from '@/model';
import { TaxonomicGroupIO } from '@/api/intactness';
import { AreasOfInterest, Region, Area, Areas } from '@/model/Area';
import { ref, reactive, computed, watchEffect, watch, Ref } from 'vue';
import { fetchTaxonomicGroups, getContent } from '@/api';
import { RouteParams, Router } from 'vue-router';
import _ from 'lodash';
import { fetachAOIByName } from '@/services/data/abmi';
import { encodeAOIName } from '@/model/GeneralRegionInfo';
import { Section } from '@/model/report';
import { ContentKeys } from '@/api/report';
import { useCookies } from 'vue3-cookies';

export const RouteNames = {
    Areas: 'Areas',
    Report: 'Report',
    ReportByName: 'ReportByName',
    Home: 'Home'
};

const pageTitle:Record<string, string> = {
    Areas: 'Online Reporting for Biodiversity in Alberta (ORB) | orb.abmi.ca',
    Report: 'ORB Report Search',
    ReportByName: 'ORB Report: ',
    Home: 'Online Reporting for Biodiversity in Alberta (ORB) | orb.abmi.ca'
};

const appState: {
    view: string,
    region: number;
    area: number;
    aoi: number;
    aoiName: string;
} = reactive({
    view: 'Areas',
    region: 0,
    area: 0,
    aoi: 0,
    aoiName: '',
});
export type AppState = typeof appState;
export function useAppState() : AppState {
    return appState;
}

// App router added by plugin, so only added once
// NOTE: If we need access to thecoposables setup hooks,
// then this would need to be called from teh App.vue component.
export function useAppRouter(router: Router):void {
    const route = computed(() => router.currentRoute.value);
    const name = computed(() => route.value?.name);
    const params = computed(() => route.value?.params);
    // Set the route based on app state changes
    watch(appState, () => {
        const stripUnused = _.pickBy(appState, (s) => s);
        // console.log({ stripUnused }, params.value);
        const newRoute = { name: appState.view, params: stripUnused };
        // Only push onto history when change page view
        if (appState.view !== name.value) {
            router.push(newRoute);
        } else {
            router.replace(newRoute);
        }
    });
    // When route changes (browser history, reload links) update state from route
    // If view is changes, call the view action
    const updateStateFromParams = async (newParams: RouteParams) => {
        if (!newParams || !name.value) {
            return;
        }

        let aoi;
        let aoiName;
        let region;
        const view = name.value;
        aoiName = newParams.aoiName ?? '';
        aoi = newParams.aoi && +newParams.aoi || 0;
        region = newParams.region && +newParams.region || 0;

        if (view === RouteNames.ReportByName && aoi === 0 && aoiName !== '') {
            aoiName = String(newParams.aoiName);
            const data = await fetachAOIByName(aoiName);
            aoi = data.aoi ?? 0;
            region = data.region ?? 0;
            // view = RouteNames.Report;
        }

        const area = newParams.area && +newParams.area || 0;
        // console.log('updateStatefromParams', { area, view, aoi, aoiName, region });
        Object.assign(appState, { area, view, aoi, aoiName, region });
    };
    watchEffect(() => updateStateFromParams(params.value));
}

function app() {
    const state = useAppState();
    // Refs can be updated async
    const aois = ref<AreasOfInterest>();
    const appInfo = ref<AppInfo>();
    const regionAreas = ref<Areas>();

    const menuOpen = ref<boolean>(false);
    const isMenuOpen = computed<boolean>(() => menuOpen.value);
    const toggleMenu = (value: undefined | boolean) => {
        if (value === undefined) {
            menuOpen.value = !menuOpen.value;
        } else {
            menuOpen.value = value;
        }
    };

    const areaExplorerOpen = ref<boolean>(false);
    const isAreaExplorerOpen = computed<boolean>(() => areaExplorerOpen.value);
    const toggleAreaExplorer = (value: undefined | boolean) => {
        if (value === undefined) {
            areaExplorerOpen.value = !areaExplorerOpen.value;
        } else {
            areaExplorerOpen.value = value;
        }
    };

    // Computeds based on state and refs
    const region = computed<Region | undefined>(() => aois.value?.getRegion(state.region));
    const area = computed<Area | undefined>(() => {
        const found = regionAreas.value?.getArea(state.area);
        return found;
    });
    const aoi = computed<Area | undefined>(() => regionAreas.value?.getArea(state.aoi));
    const areaName = computed(() => area.value?.name || aoi.value?.name || '');

    // added by ABMI
    const taxonomicGroups = ref<Record<string, TaxonomicGroupIO>>();
    watchEffect(() => {
        document.title = `${pageTitle[state.view]} ${areaName.value}`;
    });

    // Internal functions called by watchers to load async ref values
    const loadAreas = async (regionToLoad: Region | undefined) => {
        if (!aois.value) {
            return;
        }
        if (regionToLoad) {
            regionAreas.value = await Model.getRegionAreas(regionToLoad);
            state.aoiName = encodeAOIName(regionAreas.value.find(x => x.aoiId === state.area)?.aoiName);
            areaExplorerOpen.value = true;
        }
    };
    watchEffect(() => loadAreas(region.value));
    // State mutator methods
    const selectRegion = (regionToSelect: Region | undefined) => {
        state.region = regionToSelect?.regionId || 0;
    };
    const selectRegionById = (regionId: number) => {
        state.region = regionId;
        state.aoi = 0;
        toggleAreaExplorer(true);
    };
    const selectArea = (areaToSelect: Area | undefined) => {
        state.area = areaToSelect?.id || 0;
        state.aoiName = encodeAOIName(String(areaToSelect?.aoiName));
    };
    const selectAreaById = (areaId: number, name: string | undefined) => {
        state.area = areaId;
        if (name != null) {
            state.aoiName = encodeAOIName(String(name));
        }
    };
    const selectRegionArea = (regionId: number, areaId: number) => {
        state.region = regionId;
        state.area = areaId;
    };
    // Page view navigation methods
    const viewReport = () => {
        state.aoi = state.area;
        // console.log('viewReport', { state });
        state.view = RouteNames.ReportByName;
    };
    const viewAreas = () => {
        state.area = state.aoi;
        state.view = RouteNames.Areas;
    };

    const isMobile = computed(() => {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            return true;
        }
        return false;
    });

    /* global popup */
    const showPopup:Ref<boolean> = ref(false);
    const popupContent:Ref<any> = ref(null);
    const togglePopup = (popupType: ContentKeys) => {
        popupContent.value = new Section((appInfo.value?.cmsInfo?.[popupType] ?? { id: popupType }), {});
        showPopup.value = true;
    };

    // Init loads anything needed at startup
    const init = async () => {
        (async () => { aois.value = await Model.getAreasOfInterest(); })();
        (async () => {
            appInfo.value = await Model.getAppInfo(getContent('default'));
            if (state.view === 'Areas') {
                const { cookies } = useCookies();
                const cookieName = 'is_new_user';
                const initialLanding = cookies.get(cookieName);
                if (initialLanding == null) { // don't show popuo when return users
                    cookies.set(cookieName, 'yes', 60 * 60 * 2);
                    togglePopup('popup');
                }

                // window.addEventListener('beforeunload', () => {
                //     cookies.remove(cookieName);
                // });
            }
        })();
        (async () => { taxonomicGroups.value = _.keyBy(await fetchTaxonomicGroups(), 'code'); })();
    };
    init();
    return {
        state,
        appInfo,
        aois,
        region,
        area,
        aoi,
        isMenuOpen,
        toggleMenu,
        isAreaExplorerOpen,
        toggleAreaExplorer,
        regionAreas,
        selectRegion,
        selectRegionById,
        selectArea,
        selectAreaById,
        selectRegionArea,
        viewReport,
        viewAreas,
        queryAreas: Model.queryAreas,
        init,
        taxonomicGroups,
        isMobile,

        popupContent,
        togglePopup,
        showPopup
    };
}
const appSingleton = app();
export default function useApp(): typeof appSingleton {
    // NOTE: Any setup hooks must be called here.
    // This gets called from every setup() that uses the app.

    return appSingleton;
}
