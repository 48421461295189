import axios from 'axios';
import _ from 'lodash';

if (!process.env.VUE_APP_ABMI_CMS_BASE_URL) {
    throw new Error('ABMI CMS Env not set: VUE_APP_ABMI_CMS_BASE_URL');
}

const abmiCmsUrl = process.env.VUE_APP_ABMI_CMS_BASE_URL;
const getContent = 'get-content';
// const getSpecies = 'get-species';

const abmiCmsAxios = axios.create({
    baseURL: abmiCmsUrl
});

const stubbedContent = {
    birdsSpecies: {},
    mammalsSpecies: {},
    vplantsSpecies: {},
    overallSpecies: {},
    footprintStatus: {
        title: 'Status of Human Footprint'
    }
};

// export async function fetchSpeciesContentFromCR(category: string, species: string): Promise<unknown> {
//     try {
//         const sppName = `${category}/${species}`;
//         const response = await abmiCmsAxios.get(getSpecies, { params: { sppName } });
//         const data = response.data;
//         const speciesInfo = data.species[0];
//         if (!speciesInfo || !speciesInfo.name) {
//             console.info(`${sppName} not found in CMS`);
//             return null;
//         }
//         return {
//             ...speciesInfo,
//             imageSrc: `${data.imagePrefix}${speciesInfo.imageLink}`
//         };
//     } catch (error) {
//         console.warn(error);
//     }
//     return null;
// }

// export async function fetchSpeciesContent(category: string, species: string): Promise<unknown> {
//     try {
//         const sppName = `${category}/${species}`;
//         const response = await abmiCmsAxios.get(getSpecies, { params: { sppName } });
//         const data = response.data;
//         const speciesInfo = data.species[0];
//         if (!speciesInfo || !speciesInfo.name) {
//             console.info(`${sppName} not found in CMS`);
//             return null;
//         }
//         return {
//             ...speciesInfo,
//             imageSrc: `${data.imagePrefix}${speciesInfo.imageLink}`
//         };
//     } catch (error) {
//         console.warn(error);
//     }
//     return null;
// }

export async function fetchContent(name = 'default', lang?: string | undefined): Promise<unknown> {
    try {
        const response = await abmiCmsAxios.get(getContent, { params: { name, lang } });
        const data = response.data;
        // Content keys that need to be mapped / renamed here.
        const mappedKeys = _.mapKeys(data, (v, k:string) => {
            if (k.startsWith('plants')) {
                return `v${k}`;
            }
            return k;
        });
        // Content values that needs to be reshaped
        const mappedValues = _.mapValues(mappedKeys, (v) => {
            // Turns the jcr image link into imageSrc URL property with the prefix
            let result = { ...v };
            if (v.imageLink) {
                result = {
                    imageSrc: `${data.imagePrefix}${v.imageLink}`,
                    imageCredit: `${v.imageCredit ?? ''}`,
                    ...v
                };
            }
            if (v.description2) {
                result = {
                    descriptionAlternative: v.description2,
                    ...v
                };
            }
            return result;
        });
        return {
            ...stubbedContent,
            ...mappedValues
        };
    } catch (error) {
        console.error(error);
    }
    return null;
}
