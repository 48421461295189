/* eslint-disable @typescript-eslint/ban-ts-comment */
/**
 * Provides a reactive check for the tailwind screen type.
 *
 * When window resizes, the screens will change reactively using Vue observable.
 *
 * Contains the width and height of the window, the current tailwind screen class
 * AND a flag for each class that is false if that class is not used.
 *
 */

import { reactive } from 'vue';
import type Vue from 'vue';
import resolveConfig from 'tailwindcss/resolveConfig';
import _ from 'lodash';
// @ts-ignore: Importing config file as relative path
import tailwindConfig from '../tailwind.config';

interface Screen {
    min?: number;
    max?: number;
}

// @ts-ignore: Invalid augmentation if using Vue 3
declare module 'vue/types/vue' {
    // eslint-disable-next-line
    interface Vue {
        $screens: {
            w: number;
            h: number;
            mobile: false | Screen;
            sm: false | Screen;
            md: false | Screen;
            lg: false | Screen;
            xl: false | Screen;
            '2xl': false | Screen;
        };
    }
}

const fullConfig = resolveConfig(tailwindConfig);
const tailwindScreens = _.mapValues(fullConfig.theme.screens, (tailwindScreen) => {
    const screen = { min: 0, max: Infinity };
    if (tailwindScreen.max) {
        screen.max = parseInt(tailwindScreen.max, 10);
    }
    if (tailwindScreen.min) {
        screen.min = parseInt(tailwindScreen.min, 10);
    }
    if (!tailwindScreen.min && !tailwindScreen.max) {
        screen.min = parseInt(tailwindScreen, 10);
    }
    return screen;
});

// Calculates which screen is currently used based on the width, and sets the flags for each
// breakpoint.
function currentScreens() {
    const width = window.innerWidth;
    const current = _.mapValues(tailwindScreens, (screen) => screen.max >= width && screen.min <= width && screen);
    return current;
}

// @ts-ignore: Vue 2 vs Vue 3 properties
const screens = reactive({
    w: window.innerWidth,
    h: window.innerHeight,
    install: (() => {}) as ((app: Vue.App) => void),
    ...(currentScreens())
});

function updateScreens() {
    screens.w = window.innerWidth;
    screens.h = window.innerHeight;
    _.assign(screens, currentScreens());
}
updateScreens();

window.addEventListener('resize', _.debounce(updateScreens, 150));

screens.install = (app: Vue.App) => {
    /* eslint-disable-next-line no-param-reassign */
    app.config.globalProperties.$screens = screens;
    app.provide('screens', screens);
};

export default screens;
