import resolveConfig from 'tailwindcss/resolveConfig';
// @ts-ignore: Importing config file as relative path
import tailwindConfig from '../tailwind.config';

const resolvedConfig = resolveConfig(tailwindConfig);

const getTailwindColor = (color: string, shade: string | number): string => {
    const colors = resolvedConfig.theme.colors;
    if (colors[color] && colors[color][shade]) {
        return colors[color][shade];
    }
    return '';
};

export default getTailwindColor;
