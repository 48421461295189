
import _ from 'lodash';
import { Delaunay } from 'd3-delaunay';
import { ScaleLinear } from 'd3-scale';
import { select } from 'd3-selection';
import { computed, defineComponent, onBeforeUpdate, PropType, ref, watchEffect } from 'vue';
import observeResize from '@/composables/util/observeResize';
import { FormatOptions } from '@dha/number-format';
import { DEFAULT_DRAW_OPTIONS, drawBeeswarm, drawAxis, getXScale, BeeswarmReturnInfo, highlightCircle, getCircleSizing, HoverPoint } from './draw';
import { BeeswarmGroup } from './types';

export default defineComponent({
    name: 'Beeswarm',
    props: {
        data: {
            type: Array as PropType<BeeswarmGroup[]>,
            default: () => []
        },
        xMargin: {
            type: Number,
            default: 0
        },
        yMargin: {
            type: Number,
            default: DEFAULT_DRAW_OPTIONS.yMargin
        },
        spaceBetween: {
            type: Number,
            default: 100
        },
        maxRadius: {
            type: Number,
            default: DEFAULT_DRAW_OPTIONS.maxRadius
        },
        negativeText: {
            type: String,
            default: DEFAULT_DRAW_OPTIONS.negativeText
        },
        positiveText: {
            type: String,
            default: DEFAULT_DRAW_OPTIONS.negativeText
        },
        clickLabelFunction: {
            type: Function as PropType<(datum: BeeswarmGroup) => string>,
            default: null
        },
        format: {
            type: Object as PropType<FormatOptions>,
            default: null
        },
        customTooltipFunc: {
            type: Function,
            default: null,
        },
    },
    emits: ['action'],
    setup(props, { emit }) {
        const container = ref<HTMLDivElement>();
        const beeswarms = ref<HTMLDivElement>();
        const axis = ref<SVGElement>();

        const vforDivs = ref<HTMLDivElement[]>([]);
        onBeforeUpdate(() => {
            vforDivs.value = [];
        });
        const firstBeeswarm = computed(() => vforDivs.value?.[0]);

        const dataByID = computed(() => _.keyBy(props.data, 'id'));
        const dataKeys = computed(() => _.keys(dataByID.value));

        // setup resize observers/computed sizes
        const {
            dimensions: beeswarmsDimensions
        } = observeResize(beeswarms);
        const {
            dimensions: singleBeeswarmDimensions
        } = observeResize(firstBeeswarm);

        const svgWidth = computed(() => singleBeeswarmDimensions.value.width);
        const axisHeight = computed(() => beeswarmsDimensions.value.height);
        const topMargin = computed(() => DEFAULT_DRAW_OPTIONS.axisSpace + props.yMargin);

        const xScale = ref<ScaleLinear<number, number>>();
        watchEffect(() => {
            xScale.value = getXScale(props.data, svgWidth.value, props.xMargin);
        });

        // set constant radii based on beeswarm with most data points
        const constantRadiusAndPadding = computed(() => {
            const sizings = props.data.map(d => getCircleSizing(d, {
                maxRadius: props.maxRadius,
                overlap: true
            }));
            return _.minBy(sizings, 'radius') ?? null;
        });

        // draw on data update, and set up return data for tooltips
        const returnCircleData = ref<(BeeswarmReturnInfo | null)[] | null>();
        const radii = computed(() => returnCircleData.value?.map(d => d?.radius));
        watchEffect(() => {
            const data = props.data;
            const outData: (BeeswarmReturnInfo | null)[] = [];
            select(container.value ?? null)
                .selectAll<SVGElement, null>('svg.beeswarm')
                .each((d, i, els) => {
                    const key = select(els[i]).attr('data-key');
                    const beeswarm = drawBeeswarm(
                        els[i],
                        data,
                        key,
                        xScale.value,
                        {
                            negativeText: props.negativeText,
                            positiveText: props.positiveText,
                            yMargin: props.yMargin,
                            maxRadius: props.maxRadius,
                            radius: constantRadiusAndPadding.value?.radius,
                            padding: constantRadiusAndPadding.value?.padding,
                            overlap: true
                        }
                    );
                    outData.push(beeswarm);
                });
            returnCircleData.value = outData;
        }, { flush: 'post' });

        watchEffect(() => {
            drawAxis(
                axis.value,
                xScale.value,
                axisHeight.value,
                {
                    negativeText: props.negativeText,
                    positiveText: props.positiveText,
                    yMargin: props.yMargin,
                    maxRadius: props.maxRadius,
                    formatOptions: props.format
                },
            );
        });

        // setup delaunay
        const hoveredPoint = ref<HoverPoint>();
        const delaunays = computed(() => returnCircleData.value?.map(
            c => (c ? Delaunay.from(c.circles.map(({ x, y }) => [x, y])) : null)
        ));
        // setup hover events to get hovered point from delaunay
        const onVForHover = (e: MouseEvent, i: number) => {
            const containerBox = vforDivs.value[i].getBoundingClientRect();
            const delaunayIndex = delaunays.value?.[i]?.find(
                e.clientX - containerBox.x,
                e.clientY - containerBox.y - containerBox.height / 2
            );
            const circleData = returnCircleData.value?.[i];
            if (!_.isNil(delaunayIndex) && circleData) {
                hoveredPoint.value = {
                    ...circleData.circles[delaunayIndex],
                    id: circleData.key,
                    height: circleData.height,
                    radius: circleData.radius
                };
            } else {
                hoveredPoint.value = undefined;
            }
        };
        const onVForMouseout = () => {
            hoveredPoint.value = undefined;
        };
        watchEffect(() => {
            const point = hoveredPoint.value;
            select(container.value ?? null)
                .selectAll<SVGElement, null>('svg.beeswarm')
                .each((d, i, els) => {
                    const key = select(els[i]).attr('data-key');
                    highlightCircle(
                        els[i],
                        point ?? null,
                        key,
                        radii.value?.[i] ?? null,
                        props.data[i]?.color
                    );
                });
        });

        // setup tooltip positioning
        const tooltipX = computed(() => {
            if (!hoveredPoint.value) return 0;
            return hoveredPoint.value.x;
        });
        const tooltipY = computed(() => {
            if (!hoveredPoint.value) return 0;
            const point = hoveredPoint.value;
            return (point.height + props.spaceBetween) / 2 - point.y + point.radius * 2;
        });
        const tooltipRow = computed(() => (hoveredPoint.value ? {
            label: hoveredPoint.value.name,
            value: props.customTooltipFunc ? props.customTooltipFunc(hoveredPoint.value.value) : hoveredPoint.value.value,
            format: props.customTooltipFunc ? null : props.format, // when customTooltip func used, simply use the value, otherwise use format
        } : null));

        const onActionClick = (d: BeeswarmGroup) => {
            emit('action', d.id);
        };

        return {
            dataByID,
            dataKeys,

            svgWidth,
            topMargin,
            xScale,

            hoveredPoint,
            tooltipRow,
            tooltipX,
            tooltipY,
            radii,

            onVForHover,
            onVForMouseout,
            onActionClick,

            container,
            beeswarms,
            axis,
            vforDivs
        };
    }
});
