import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    ref: "parent",
    class: "grid-layout",
    style: _normalizeStyle({
            'grid-template-columns': _ctx.templateColumns,
            'grid-template-rows': _ctx.templateRows,
            'grid-auto-flow': _ctx.gridFlow
        })
  }, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 4))
}