import _ from 'lodash';
import { FormatOptions } from '@dha/number-format';
import type { FootprintCategoryIO } from '@/api/report';
import { fetchFootprintTrend, fetchFootprintTrendCSV } from '@/api';
import {
    FootprintByYearDatum
} from '@/api/footprint';
import FootprintTrendsContent from './report/FootprintTrendsContent';
import { Area } from './Area';
import SectionContentModel from './SectionContent';
import { TemplateProps } from './helpers';

type ToggleValue = {
    label: string;
    value: string;
}

type LineStyle = {
    color: string | undefined;
    dotted: boolean;
    label: string | undefined;
}

export type FootprintByCategoryDatum = {
    key: string;
    name: string;
    date: Date;
    value: number;
    areaHigh: number | null;
    areaLow: number | null;
}
export type FootprintByCategoryArrayItem = {
    key: string;
    name: string;
    values: FootprintByCategoryDatum[];
}

export type FootprintLatestDatum = {
    key: string;
    color: string;
    title: string;
    icon: string;
    value: number;
}

// generate the data for each main category
function dataCategoriesToArray(
    data: Record<string, FootprintByCategoryDatum[]>,
    categories: Record<string, FootprintCategoryIO>
): FootprintByCategoryArrayItem[] {
    return _(data)
        .entries()
        .filter(([key]) => categories[key].isMainCategory && !categories[key].isTotalCategory)
        .map(([key, values]) => ({ key, name: values[0].name, values }))
        .sortBy(({ values }) => values.slice(-1)[0].value)
        .reverse()
        .value();
}

// generate the data for each line chart in the section. each line is a
// FootprintByCategoryArrayItem and each chart can have multiple lines, hence [][]
function dataCategoriesToNestedArray(
    data: Record<string, FootprintByCategoryDatum[]>,
    categories: Record<string, FootprintCategoryIO>
): FootprintByCategoryArrayItem[][] {
    return _(data)
        .entries()
        .filter(([key]) => categories[key].isMainCategory && !categories[key].isTotalCategory)
        .map(([key, values]) => {
            const subcategories = categories[key].subcategories;
            if (subcategories) {
                // add the data for each listed subcategory into the array for this chart
                const availableSubcategories = subcategories.filter(cat => data[cat]);
                return [
                    { key, name: values[0].name, values },
                    ...availableSubcategories.map(cat => ({
                        key: cat,
                        name: data[cat][0].name,
                        values: data[cat]
                    }))
                ];
            }
            return [{ key, name: values[0].name, values }];
        })
        .sortBy(arrs => arrs[0].values.slice(-1)[0].value)
        .reverse()
        .value();
}

export default class FootprintTrends extends SectionContentModel {
    data?: FootprintByYearDatum[];
    aoi?: Area;
    categories: Record<string, FootprintCategoryIO>;
    infoLabels: Record<string, unknown>;
    showMagnitude = true;
    toggleOptions: ToggleValue[];
    content: FootprintTrendsContent;
    constructor(content: FootprintTrendsContent) {
        super(content);
        this.content = content;
        this.categories = content.config.categories;
        this.infoLabels = _.mapValues(this.categories, 'infoLabel');
        this.toggleOptions = [
            { label: 'Magnitude', value: 'magnitude' },
            { label: 'Change', value: 'change' }
        ];
    }
    get templateProps(): TemplateProps {
        return {
            endYear: String(this.year) || null,
            areaName: this.areaName ?? null,
            endPct: this.totalFootprint ?? null
        };
    }

    async load(aoi: Area):Promise<void> {
        this.data = await fetchFootprintTrend(aoi.id);
        this.aoi = aoi;
    }

    get areaName(): string | undefined {
        return this.aoi?.name;
    }
    get aoiId(): number | undefined {
        return this.aoi?.id;
    }

    get magnitudeDataByCategory(): Record<string, FootprintByCategoryDatum[]> {
        return _(this.data)
            .flatMap(({ year, values }) => values.map(
                d => ({
                    key: d.key,
                    name: d.name,
                    value: d.value,
                    date: new Date(Date.UTC(year, 0)),
                    // any nulls in the confidence intervals will be skipped when drawing
                    areaLow: _.isNil(d.lowerConfidenceInterval)
                        ? null
                        : d.value - d.lowerConfidenceInterval,
                    areaHigh: _.isNil(d.upperConfidenceInterval)
                        ? null
                        : d.value + d.upperConfidenceInterval
                })
            ))
            .groupBy('key')
            .mapValues(arr => _.sortBy(arr, 'date'))
            .value();
    }

    get percentDataByCategory(): Record<string, FootprintByCategoryDatum[]> {
        return _.mapValues(this.magnitudeDataByCategory, values => {
            const removeStartingZeros = _.dropWhile(values, d => d.value < 0);
            const first = removeStartingZeros[0];
            const lowest = first.areaLow || first.value;
            const highest = first.areaHigh ?? first.value;
            return removeStartingZeros.map((d, i) => {
                const changeValue = (d.value - first.value) / first.value;

                return {
                    ...d,
                    value: changeValue,
                    // the smallest % change is between the lowest possible current value and the highest possible first value
                    areaLow: i < 0 ? changeValue : (d.areaLow && (d.areaLow - highest) / highest),
                    // the largest % change is between the highest possible current value and the lowest possible (non-zero) first value
                    areaHigh: i < 0 ? changeValue : (d.areaHigh && (d.areaHigh - lowest) / lowest)
                };
            });
        });
    }

    get selectedTrendData(): FootprintByCategoryArrayItem[][] {
        const selectedData = this.showMagnitude ? this.magnitudeDataByCategory : this.percentDataByCategory;
        return dataCategoriesToNestedArray(selectedData, this.categories);
    }

    get totalTrendData(): FootprintByCategoryArrayItem | null {
        const selectedData = this.showMagnitude ? this.magnitudeDataByCategory : this.percentDataByCategory;
        const totalKey = Object.values(this.categories).find(d => d.isTotalCategory)?.key;
        return (totalKey && selectedData[totalKey]) ? {
            key: totalKey,
            name: selectedData[totalKey][0].name,
            values: selectedData[totalKey]
        } : null;
    }

    get latestData(): FootprintLatestDatum[] {
        return dataCategoriesToArray(this.magnitudeDataByCategory, this.categories)
            .map(({ key, values }) => {
                const latest = values.slice(-1)[0];
                return {
                    key,
                    description: this.categories[latest.key]?.description,
                    color: this.categories[latest.key]?.color ?? '',
                    title: latest.name,
                    value: latest.value,
                    icon: this.categories[latest.key]?.icon
                };
            });
    }

    get latestSubCategoryData(): FootprintLatestDatum[] {
        const data = Object.keys(this.magnitudeDataByCategory).filter(x => !this.categories[x].isMainCategory && !this.categories[x].isTotalCategory).map(
            key => {
                const latest = this.magnitudeDataByCategory[key].slice(-1)[0];
                return {
                    key,
                    description: this.categories[latest.key]?.description,
                    color: this.categories[latest.key]?.color ?? '',
                    title: latest.name,
                    value: latest.value,
                    icon: this.categories[latest.key]?.icon
                };
            }
        );

        return data;
    }
    get latestMainCategoryData(): FootprintLatestDatum[] {
        return this.latestData.filter(d => this.categories[d.key].isMainCategory);
    }

    get year(): number | undefined {
        if (!this.data) {
            return this.data;
        }
        return _.max(_.map(this.data, 'year'));
    }
    get startYear(): number | undefined {
        if (!this.data) {
            return this.data;
        }
        return _.min(_.map(this.data, 'year'));
    }
    get yearRange() : string {
        return `${this.startYear || ''}-${this.year || ''}`;
    }
    // get yearGap(): number {
    //     return (this.year ?? 0) - (this.startYear ?? 0);
    // }
    get fullDomain(): [number, number] {
        const allValues = this.selectedTrendData.flatMap(
            categoryArrs => categoryArrs.flatMap(arr => arr.values.flatMap(d => [d.value, d.areaHigh]))
        ).filter(d => d !== null) as number[];

        return [
            Math.min(0, Math.min(...allValues)),
            Math.max(0, Math.max(...allValues))
        ];
    }

    get totalFootprint(): number | undefined {
        if (!this.data || !this.data.slice(-1)[0]) {
            return undefined;
        }
        return this.data.slice(-1)[0].values
            .find(d => d.key === 'total-human-footprint')
            ?.value;
    }

    get totalSubtitle(): string | null {
        if (!this.totalTrendData || this.showMagnitude) return null;
        return `% change since ${this.totalTrendData?.values[0].date.getUTCFullYear()}`;
    }

    get lineSubtitles(): string[] | null {
        if (this.showMagnitude) return null;
        return this.selectedTrendData.map(d => `% change since ${d[0].values[0].date.getUTCFullYear()}`);
    }

    get format(): FormatOptions | undefined {
        return this.content.config.format;
    }

    get lineStyles():{ [x: string]: LineStyle } {
        return _.mapValues(
            this.categories,
            cat => ({
                color: cat.color,
                dotted: (cat.dottedLineStyle == null ? (!cat.isMainCategory && !cat.isTotalCategory) : !!cat.dottedLineStyle),
                label: cat.name
            })
        );
    }

    async downloadCSV(): Promise<string> {
        if (!this.aoiId) { return ''; }
        const csvData = await fetchFootprintTrendCSV(this.aoiId);
        return csvData;
    }

    setShowMagnitude(show: boolean): void {
        this.showMagnitude = show;
    }
}
