
import { defineComponent, PropType, ref, watchEffect } from 'vue';
import observeResize from '@/composables/util/observeResize';
import { FormatOptions } from '@dha/number-format';
import RowFlagNameIcon from './RowFlagNameIcon.vue';
import { RowFlagIconDatum, FlagIcon } from './types';

export default defineComponent({
    name: 'RowsFlagNameIcon',
    components: {
        RowFlagNameIcon
    },
    props: {
        allData: {
            type: Array as PropType<RowFlagIconDatum[]>,
            default: () => []
        },
        filteredData: {
            type: Array as PropType<RowFlagIconDatum[]>,
            default: () => []
        },
        rowHeight: {
            type: Number,
            required: true
        },
        rowGap: {
            type: Number,
            required: true
        },
        chartExpanded: {
            type: Boolean,
            default: false
        },
        flags: {
            type: Array as PropType<FlagIcon[]>,
            default: () => []
        },
        format: {
            type: Object as PropType<FormatOptions>,
            default: null
        },
    },
    emits: ['resize'],
    setup(props, { emit }) {
        const rows = ref<HTMLDivElement>();

        const {
            dimensions
        } = observeResize(rows);

        watchEffect(() => {
            emit('resize', dimensions.value);
        });

        return {
            rows
        };
    },
});
