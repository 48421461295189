
import { defineComponent, ref, watchEffect } from 'vue';
import { paths } from './constants';

export default defineComponent({
    props: {
        placeholder: {
            type: String,
            required: true,
        },
        value: {
            type: String,
            default: ''
        }
    },
    emits: ['change'],
    setup(props, { emit }) {
        const searchInput = ref<HTMLInputElement>();

        const isClearable = ref(false);
        const clearSearchInput = () => {
            isClearable.value = false;
        };
        const onSearchTextChange = async (changeEvent: InputEvent) => {
            const value = (changeEvent.target as HTMLInputElement).value;
            emit('change', value);
            isClearable.value = !!value;
        };

        watchEffect(() => {
            if (!isClearable.value) {
                if (searchInput.value) {
                    searchInput.value.value = '';
                    emit('change', '');
                }
            }
        }, { flush: 'post' });

        const focus = () => {
            searchInput.value?.focus({ preventScroll: true });
        };

        return {
            searchInput,
            isClearable,

            clearSearchInput,
            onSearchTextChange,
            focus
        };
    },
    data() {
        return {
            paths
        };
    }
});
