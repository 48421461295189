export const openMapPortal = (url:string | undefined):void => {
    if (url != null) {
        window.open(url, '_blank');
    }
};

export const openBBProfileUrl = (sppName:string): void => {
    const bbProfileUrlPrefix = process.env.VUE_APP_BB_PROFILE_LINK;
    // eslint-disable-next-line no-console
    // copied from https://bitbucket.org/abmi-ic/web-ui-vue-profile/src/development/src/config/profile-config.js
    const urlName = sppName?.replaceAll('-', '+').replaceAll('.', '$').replaceAll(' ', '-').replaceAll('/', '@')
        .replaceAll('>', '&gt;')
        .replaceAll('<', '&lt;')
        ?.toLowerCase();

    window.open(bbProfileUrlPrefix + urlName, '_blank');
};
