import { AreaOfAnalysisIO } from '@/api/aoi';
import { WetlandDatum, WetlandDescription } from '@/api/wetland';
import _ from 'lodash';
import { LineStyle } from '@/components/LineChart/types';
// import { WetlandCategoryIO } from '@/api/report';
// import { StackedChartDataItem } from '@/components/StackedBarChart/draw';
import { FormatOptions } from '@dha/number-format';
import { Section } from './report';
import { TemplateProps } from './helpers';
import SectionContentModel from './SectionContent';
// import WetlandStatusContent from './report/WetlandStatusContent';
// const areaText = computed(() => templateMarkdown(props.section.description, templateProps.value));

export default class WetlandStatusModel extends SectionContentModel {
    // Total Human Wetland Status, includes latest status data
    // categories: Record<string, WetlandCategoryIO>;
    wetlandTotal?: number;
    wetlandDataList?: WetlandDatum[];
    wetlandByCategoryId?: { number?: WetlandDatum};
    aoiData?: AreaOfAnalysisIO;
    content: Section;

    constructor(content: Section) {
        super(content);
        this.content = content;
        // this.categories = content.config.categories;
    }

    load(aoiData: AreaOfAnalysisIO, wetlandDataList: WetlandDatum[]):void {
        let tmpList = wetlandDataList.sort((a, b) => b.value - a.value).map(x => ({ ...x, title: x.name }));
        /* if parkland or grassland, hide 0 bog, fen categories.  */
        if (['parkland', 'prairie'].includes(`${aoiData.regionTypeCode}`)) {
            this.wetlandDataList = tmpList.filter(x => !(['Bog', 'Fen'].includes(x.name) && x.value === 0));
        }

        tmpList = tmpList.map(wetland => ({
            ...wetland,
            description: WetlandDescription[wetland.name]
        }));

        this.wetlandDataList = tmpList;

        this.wetlandTotal = wetlandDataList.reduce((sum, cur) => sum + cur.value, 0);
        this.wetlandByCategoryId = _.keyBy(wetlandDataList, 'id');
        this.aoiData = aoiData;
    }
    get templateProps(): TemplateProps {
        return { ...this.aoiData, totalPct: this.wetlandTotal ?? '' };
    }
    get format(): FormatOptions | undefined {
        return this.content.config.format;
    }
    get lineStyles():{ [x: string]: LineStyle } {
        return _.mapValues(
            this.wetlandByCategoryId || {},
            cat => ({
                color: cat?.color,
                label: cat?.name
            })
        );
    }
}
