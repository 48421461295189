
import { defineComponent, PropType, computed } from 'vue';
import type { Section } from '@/model/report';
import useFootprintStatus from '@/composables/state/report/useFootprintStatus';
import useApp from '@/composables/state/useApp';
import SectionHeader from './SectionHeader.vue';

export default defineComponent({
    name: 'MethodsSection',
    components: {
        // ABMIMap,
        SectionHeader
    },
    props: {
        section: {
            type: Object as PropType<Section>,
            default: null,
        }
    },
    setup(props) {
        const { aoi } = useApp();
        /* use this to udpate methods, otherwsie not updating */
        const methodsContent = computed(() => useFootprintStatus(aoi, props.section));
        // const { mapData, mapInfo } = useABMIMap(aoi, 'abmi-site');
        // const aoiId = aoi?.value?.aoiId;
        // const occurrenceMapUrl = process.env.VUE_APP_ABMI_STATIC_OCCURRENCE_MAP_URL?.replace('{{aoiId}}', aoiId);
        return { methodsContent };
        // return { methodsContent, occurrenceMapUrl };
    }
});
