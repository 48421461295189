import _ from 'lodash';
import { axisTop } from 'd3-axis';
import { ScaleLinear, scaleLinear } from 'd3-scale';
import { select } from 'd3-selection';
import { FormatOptions } from '@dha/number-format';
import { appendIfNotExists, twoSidedTickFormat } from '../helpers';
import { BeeswarmGroup } from './types';
import { generateSwarm, BeeswarmQueueItem } from './helpers';

export type BeeswarmReturnInfo = {
    height: number;
    circles: BeeswarmQueueItem[];
    key: string;
    radius: number;
}

export type HoverPoint = BeeswarmQueueItem & {
    id: string;
    height: number;
    radius: number;
}

export type BeeswarmDrawOptions = {
    axisSpace: number;
    negativeText: string;
    positiveText: string;
    yMargin: number;
    maxRadius: number;
    maxPadding: number;
    radius?: number;
    padding?: number;
    overlap: boolean;
    formatOptions?: FormatOptions;
}

export const DEFAULT_DRAW_OPTIONS = {
    axisSpace: 20,
    negativeText: '',
    positiveText: '',
    yMargin: 30,
    maxRadius: 10,
    maxPadding: 5,
    overlap: false
};

const INDICATOR_PADDING = 10;
// the amount that the circles decrease in radius per data point. determined by inspection
const SHRINK_PERCENT_PER_FACTOR = 0.996;

function mergeOptions(
    drawOptions?: Partial<BeeswarmDrawOptions>
): BeeswarmDrawOptions {
    return {
        ...DEFAULT_DRAW_OPTIONS,
        ..._.pickBy(drawOptions, o => !_.isNil(o))
    };
}

/* set default min and max */
export function getXScale(
    data: BeeswarmGroup[],
    width: number,
    xMargin: number
): ScaleLinear<number, number> {
    const allValues = _(data).flatMap(d => d.data).value();
    const domain = [
        Math.min(_.minBy(allValues, 'value')?.value ?? 0, -0.05),
        Math.max(_.maxBy(allValues, 'value')?.value ?? 0, 0.05)
    ];

    return scaleLinear()
        .domain(domain)
        .range([xMargin, width - xMargin])
        .nice();
}

function drawAxisIndicator(
    indicatorSvg: Element | null,
    xScale: ScaleLinear<number, number>,
    drawOptions: BeeswarmDrawOptions
) {
    const { negativeText, positiveText } = drawOptions;

    if (xScale.domain()[0] < 0) {
        appendIfNotExists<SVGTextElement>(
            select(indicatorSvg),
            'text',
            'left'
        )
            .attr('x', xScale(0) - INDICATOR_PADDING)
            .attr('y', 0)
            .attr('text-anchor', 'end')
            .text(negativeText);
    } else {
        select(indicatorSvg).select('text.left').remove();
    }

    if (xScale.domain()[1] > 0) {
        appendIfNotExists<SVGTextElement>(
            select(indicatorSvg),
            'text',
            'right'
        )
            .attr('x', xScale(0) + INDICATOR_PADDING)
            .attr('y', 0)
            .text(positiveText);
    } else {
        select(indicatorSvg).select('text.right').remove();
    }
}

export function drawAxis(
    svg: Element | null | undefined,
    xScale: ScaleLinear<number, number> | undefined,
    height: number,
    drawOptions: Partial<BeeswarmDrawOptions>,
): void {
    if (!xScale || !xScale(1)) return;

    const options = mergeOptions(drawOptions);

    drawAxisIndicator(
        svg ?? null,
        xScale,
        options
    );

    const { axisSpace, yMargin, formatOptions } = options;

    const axis = axisTop(xScale)
        .ticks(7)
        .tickSize(-height)
        .tickPadding(10)
        .tickFormat(n => twoSidedTickFormat(
            n,
            formatOptions ?? {},
            xScale.ticks(7)
        ));

    appendIfNotExists<SVGGElement>(select(svg ?? null), 'g', 'axis')
        .style('transform', `translate(0, ${yMargin + axisSpace}px)`)
        .call(axis)
        .selectAll('.tick line')
        .filter(d => d === 0)
        .classed('zero-line', true);
}

export function getCircleSizing(
    group: BeeswarmGroup,
    drawOptions: Partial<BeeswarmDrawOptions>
): { radius: number; padding: number } | null {
    const { maxRadius, maxPadding, overlap } = mergeOptions(drawOptions);
    const radius = maxRadius * (SHRINK_PERCENT_PER_FACTOR ** group.data.length);
    const padding = maxPadding * radius / maxRadius;
    return {
        radius,
        padding: overlap ? -padding : padding
    };
}

export function drawBeeswarm(
    svg: Element | null | undefined,
    data: BeeswarmGroup[],
    key: string,
    xScale: ScaleLinear<number, number> | undefined,
    drawOptions: Partial<BeeswarmDrawOptions>
): BeeswarmReturnInfo | null {
    const group = data.find(d => d.id === key);
    if (!group || !xScale) return null;

    const options = mergeOptions(drawOptions);
    const { maxRadius, maxPadding, radius, padding } = options;

    const relativeSizing = getCircleSizing(group, options);
    const r = !_.isNil(radius) ? radius : relativeSizing?.radius ?? maxRadius;
    const p = !_.isNil(padding) ? padding : relativeSizing?.padding ?? maxPadding;

    const drawGroup = appendIfNotExists<SVGGElement>(select(svg ?? null), 'g', 'circles');

    const circleData = generateSwarm(group.data, xScale, r, p);

    drawGroup.selectAll<SVGCircleElement, BeeswarmQueueItem>('circle.value')
        .data(circleData, d => d.name)
        .join('circle')
        .classed('value', true)
        .attr('cx', d => d.x)
        .attr('cy', d => d.y)
        .attr('r', r)
        .attr('fill', group.color ?? 'black');

    const height = drawGroup.node()?.getBoundingClientRect().height ?? 0;
    select(svg ?? null)
        .style('height', `${height}px`)
        .select('g.circles')
        .style('transform', `translate(0, ${(height - r) / 2}px)`);
    appendIfNotExists<SVGGElement>(select(svg ?? null), 'g', 'highlight-group')
        .style('transform', `translate(0, ${(height - r) / 2}px)`);

    return {
        height,
        circles: circleData,
        key,
        radius: r
    };
}

export function highlightCircle(
    svg: Element | null | undefined,
    datum: HoverPoint | null,
    key: string,
    radius: number | null,
    color: string | null
): void {
    const highlightGroup = select(svg ?? null).select('g.highlight-group');
    const circle = appendIfNotExists<SVGCircleElement>(highlightGroup, 'circle', 'highlight-circle');
    if (!datum || key !== datum.id) {
        circle.attr('cx', 0)
            .attr('cy', 0)
            .attr('r', 0)
            .attr('fill', 'none')
            .classed('selected', false);
    } else {
        circle.attr('cx', datum.x)
            .attr('cy', datum.y)
            .attr('r', radius ?? 0)
            .attr('fill', color ?? 'black')
            .classed('selected', true);
    }
}
