
import { defineComponent, ref } from 'vue';
import _ from 'lodash';
import useApp from '@/composables/state/useApp';
import useRegionCategories from '@/composables/state/areas/useRegionCategories';

type SearchResult = {
    aoiId: number,
    aoiName: string,
    regionId: number,
    regionName: string,
    categoryId: number;
    categoryName: string;
}

type SearchState = 'idle' | 'searching' | 'withResults' | 'noResults';

const MAX_SEARCH_RESULTS = 15;
const SEARCH_DELAY_TIME = 750;

export default defineComponent({
    name: 'SearchHeader',
    setup() {
        const { queryAreas, selectRegionById, selectAreaById } = useApp();
        const { setSelectedCategory } = useRegionCategories();

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const searchResults = ref<any[]>([]);
        const searchInput = ref<HTMLInputElement>();
        const searchState = ref<SearchState>('idle');

        const clearSearch = () => {
            if (!searchInput.value) {
                return;
            }
            searchResults.value = [];
            searchInput.value.value = '';
            searchState.value = 'idle';
        };

        const onClickSearchIcon = () => {
            searchInput.value?.focus();
        };

        const onClickClearSearch = () => {
            clearSearch();
        };

        const onSearchInput = _.debounce(async (event: KeyboardEvent) => {
            event.preventDefault();
            const value = (event.target as HTMLInputElement).value;
            if (!value) {
                clearSearch();
                return;
            }

            searchState.value = 'searching';
            const results: SearchResult[] = await queryAreas(value);

            searchResults.value = results.splice(0, MAX_SEARCH_RESULTS);
            searchState.value = searchResults.value.length ? 'withResults' : 'noResults';
        }, SEARCH_DELAY_TIME);

        const onSearchBlur = () => {
            clearSearch();
        };

        const onSearchKeydown = (event: KeyboardEvent) => {
            if (event.key.toLowerCase() === 'escape') {
                clearSearch();
            }
        };

        const onSearchClick = (result: SearchResult) => {
            setSelectedCategory(result.categoryId);
            if (result.regionId) {
                selectRegionById(result.regionId);
            }
            if (result.aoiId) {
                selectAreaById(result.aoiId, result.aoiName);
            }

            clearSearch();
        };

        return {
            searchInput,
            searchState,
            searchResults,
            onClickClearSearch,
            onClickSearchIcon,
            onSearchInput,
            onSearchBlur,
            onSearchKeydown,
            onSearchClick,
        };
    },
});
