import { ref, Ref, watchEffect } from 'vue';
import { Area } from '@/model/Area';
import { fetchAreaOfAnalysis } from '@/api';
import { AreaOfAnalysisIO } from '@/api/aoi';

const aoiData = ref<AreaOfAnalysisIO>();
const loaders:{[aoiId:number]: Promise<AreaOfAnalysisIO>} = {};
/**
 * Data loader of the key-value global variables that can be used by all
 * models.
 */
export default function useAOILoader(aoi: Ref<Area | undefined>):Ref<AreaOfAnalysisIO | undefined> {
    const loadData = async (aoiToLoad:Area|undefined) => {
        if (!aoiToLoad) {
            return;
        }
        if (!loaders[aoiToLoad.id]) {
            loaders[aoiToLoad.id] = fetchAreaOfAnalysis(aoiToLoad.id);
        }
        const data = await loaders[aoiToLoad.id];
        data.regional_area_name = aoiToLoad.region?.name || '';
        aoiData.value = data;
    };
    watchEffect(() => loadData(aoi.value));
    return aoiData;
}
