import _ from 'lodash';
import { axisBottom, axisLeft } from 'd3-axis';
import { scaleLinear, ScaleLinear, ScaleTime, scaleUtc } from 'd3-scale';
import { select } from 'd3-selection';
import { line, area } from 'd3-shape';
import { utcYears } from 'd3-time';
import { utcFormat } from 'd3-time-format';
import formatter, { FormatOptions } from '@dha/number-format';

import { BaseValue, LineChartDatum, LineStyle } from './types';
import { appendIfNotExists } from '../helpers';

const DEFAULT_AXIS_MARGIN = 10;

export type LineChartDrawOptions = {
    width: number;
    height: number;
    leftMargin: number;
    rightMargin: number;
    yMargin: number;
    styles: Record<string, LineStyle>;
    yDomain?: number[];
    xFormat?: string;
    yFormat?: FormatOptions;
}

export const DEFAULT_DRAW_OPTIONS = {
    width: 400,
    height: 300,
    leftMargin: 40,
    rightMargin: 40,
    yMargin: 10,
    styles: {},
    xFormat: '%Y',
    yFormat: { type: 'default' } as FormatOptions,
};

function mergeOptions(
    drawOptions?: Partial<LineChartDrawOptions>
): LineChartDrawOptions {
    return {
        ...DEFAULT_DRAW_OPTIONS,
        ..._.pickBy(drawOptions, o => !_.isNil(o))
    };
}

function getTimeScale(points: BaseValue[], width: number, leftMargin: number, rightMargin: number) {
    if (points.length < 2) return undefined;

    const domain = [
        _.minBy(points, 'date')?.date,
        _.maxBy(points, 'date')?.date
    ];

    return scaleUtc()
        .domain(domain as Date[])
        .range([leftMargin + DEFAULT_AXIS_MARGIN, width - rightMargin]);
}

function getTimeTicks(
    xScale: ScaleTime<number, number>,
    // spacePerTick = 40
) {
    const start = xScale.domain()[0];
    const end = xScale.domain()[1];
    // const width = xScale.range()[1] - xScale.range()[0] + spacePerTick;
    const allYears = utcYears(start, end, 1).concat(end);
    const years = allYears;

    // const mod = 1;
    /* while (
        // must have at least two items
        years.length > 2
        && (
            // each tick must be allowed at least spacePerTick px
            width / (years.length * spacePerTick) < 1
            // start and end years must be present
            || (years.length - 1) % mod !== 0
        )
    ) {
        // increase interval between years until both conditions met
        mod += 1;
        /* eslint-disable-next-line no-loop-func *
        years = allYears.filter((y, i) => i % mod === 0);
    }
    */
    if (years.length <= 1) {
        return xScale.domain();
    }

    /* if last year is not included, replace last item with last year
    if (years[years.length - 1] !== end) {
        years.pop();
        years.push(end);
    }
    */
    return years;
}

function getYScale(
    points: BaseValue[],
    height: number,
    yMargin: number,
    customDomain?: number[]
) {
    const domain = customDomain ?? [
        Math.min(_.minBy(points, 'value')?.value ?? 0, 0),
        Math.max(_.maxBy(points, 'value')?.value ?? 0, 0)
    ];

    return scaleLinear()
        .domain(domain)
        .range([height - yMargin - DEFAULT_AXIS_MARGIN, yMargin])
        .nice();
}

function drawAxes(
    svg: Element | null,
    xScale: ScaleTime<number, number>,
    yScale: ScaleLinear<number, number>,
    leftMargin: number,
    rightMargin: number,
    yMargin: number,
    height: number,
    xFormat?: string,
    yFormat?: FormatOptions
) {
    const xAxis = axisBottom(xScale)
        .tickValues(getTimeTicks(xScale))
        .tickSize(0);

    if (xFormat) {
        xAxis.tickFormat(t => utcFormat(xFormat)(t as Date));
    }

    const numYTicks = 5;
    appendIfNotExists<SVGGElement>(select(svg), 'g', 'x-axis')
        .attr('transform', `translate(0, ${height - yMargin})`)
        .call(xAxis).selectAll('text')
        .style('text-anchor', 'end')
        .attr('dx', '-.8em')
        .attr('dy', '.15em')
        .attr('transform', 'rotate(-65)');

    const yAxis = axisLeft(yScale)
        .ticks(numYTicks)
        .tickSize(-(xScale.range()[1] - xScale.range()[0] + DEFAULT_AXIS_MARGIN));

    if (yFormat) {
        yAxis.tickFormat(
            t => formatter(
                t as number,
                {
                    ...yFormat,
                    matchFormatList: yScale.ticks(numYTicks)
                }
            )
        );
    }

    appendIfNotExists<SVGGElement>(select(svg), 'g', 'y-axis')
        .attr('transform', `translate(${leftMargin}, 0)`)
        .call(yAxis)
        .selectAll('.tick line')
        .style('transform', `translate(${DEFAULT_AXIS_MARGIN / 2}px, 0)`);
}

function checkNullInterval(d: LineChartDatum) {
    return _.isNil(d.areaHigh) || _.isNil(d.areaLow);
}

function drawArea(
    svg: Element | null,
    data: LineChartDatum[],
    xScale: ScaleTime<number, number>,
    yScale: ScaleLinear<number, number>,
    styles: Record<string, LineStyle>
) {
    const [min, max] = yScale.domain();
    const interval = area<LineChartDatum>()
        .x(d => xScale(d.date))
        // @types/d3-shape has these types wrong so we cast
        .y0(d => (checkNullInterval(d) ? null : yScale(Math.max(min, d.areaLow as number))) as number)
        .y1(d => (checkNullInterval(d) ? null : yScale(Math.min(max, d.areaHigh as number))) as number)
        .defined(d => !checkNullInterval(d));

    appendIfNotExists<SVGGElement>(select(svg), 'g', 'area-group')
        .selectAll<SVGPathElement, LineChartDatum[]>('path.area')
        .data([data])
        .join('path')
        .classed('area', true)
        .attr('fill', d => styles[d[0].key].color ?? 'black')
        .attr('d', d => interval(d));
}

function drawLines(
    svg: Element | null,
    data: LineChartDatum[][],
    xScale: ScaleTime<number, number>,
    yScale: ScaleLinear<number, number>,
    styles: Record<string, LineStyle>
) {
    const path = line<LineChartDatum>()
        .x(d => xScale(d.date))
        .y(d => yScale(d.value));

    select(svg)
        .selectAll<SVGGElement, LineChartDatum[]>('g.line-group')
        .data(data, d => d[0].key)
        .join(
            enter => {
                const groups = enter.append('g')
                    .classed('line-group', true);

                groups.append('path')
                    .classed('line', true);

                return groups;
            },
            update => update
        )
        .select('path.line')
        .attr('stroke', d => styles[d[0].key].color ?? 'black')
        .attr('d', d => path(d))
        .style('stroke-dasharray', d => (styles[d[0].key].dotted ? '10 10' : null));
}

export default function draw(
    svg: Element | null | undefined,
    data: LineChartDatum[][],
    drawOptions?: Partial<LineChartDrawOptions>
): { xScale: ScaleTime<number, number> | undefined, yScale: ScaleLinear<number, number> } {
    const { width, height, leftMargin, rightMargin, yMargin, styles, yDomain, xFormat, yFormat } = mergeOptions(drawOptions);

    const allPoints = data.flat();
    const xScale = getTimeScale(allPoints, width, leftMargin, rightMargin);
    const yScale = getYScale(allPoints, height, yMargin, yDomain);

    if (xScale) {
        drawAxes(
            svg ?? null,
            xScale,
            yScale,
            leftMargin,
            rightMargin,
            yMargin,
            height,
            xFormat,
            yFormat
        );
        /* draw area for the main category */
        const solidLineIndex = Math.max(data.findIndex(x => !styles[x[0].key].dotted), 0);
        drawArea(
            svg ?? null,
            data[solidLineIndex],
            xScale,
            yScale,
            styles
        );

        drawLines(
            svg ?? null,
            data,
            xScale,
            yScale,
            styles
        );
    }

    return { xScale, yScale };
}
