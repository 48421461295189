import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ScrollReport = _resolveComponent("ScrollReport")!
  const _component_ReportMenu = _resolveComponent("ReportMenu")!
  const _component_ReportLayout = _resolveComponent("ReportLayout")!
  const _component_PopUpBox = _resolveComponent("PopUpBox")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ReportLayout, null, _createSlots({
      default: _withCtx(() => [
        (_ctx.report)
          ? (_openBlock(), _createBlock(_component_ScrollReport, {
              key: 0,
              class: "w-full",
              "toggle-popup": _ctx.togglePopup
            }, null, 8, ["toggle-popup"]))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [], 64))
      ]),
      _: 2
    }, [
      (_ctx.report)
        ? {
            name: "menu",
            fn: _withCtx(() => [
              _createVNode(_component_ReportMenu, { "toggle-popup": _ctx.togglePopup }, null, 8, ["toggle-popup"])
            ]),
            key: "0"
          }
        : undefined
    ]), 1024),
    _createVNode(_component_PopUpBox, {
      section: _ctx.popupContent,
      "show-popup": _ctx.showPopup,
      "toggle-popup": () => _ctx.showPopup = !_ctx.showPopup
    }, null, 8, ["section", "show-popup", "toggle-popup"])
  ], 64))
}