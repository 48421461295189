
import { computed, defineComponent, PropType } from 'vue';
import type { Report } from '@/model/report';
import useApp from '@/composables/state/useApp';

export default defineComponent({
    name: 'ReportTitlePage',
    props: {
        report: {
            type: Object as PropType<Report>,
            default: null,
        }
    },
    setup(props) {
        const { aoi } = useApp();
        const regionCss = computed(
            () => ((aoi.value && aoi.value.regionTypeCode) ? `bg-${aoi.value?.regionTypeCode}-main` : 'text-default-main')
        );
        const imageUrlPrefix = process.env.VUE_APP_ABMI_CMS_IMAGE_URL;
        // eslint-disable-next-line global-require
        const bannerImg = computed(() => `${imageUrlPrefix}${props.report?.sections[0]?.imageSrc || require('@/assets/img/report-title-boreal.jpg')}`);
        const imageCredit = computed(() => `${props.report?.sections[0]?.imageCredit || ''}`);

        return { regionCss, bannerImg, imageCredit };
    }
});
