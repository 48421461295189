const colors = {
    gray: {
        100: '#EDEDED',
        300: '#A0A393',
        400: '#767A77',
        450: '#555751',
        500: '#4C515A',
        700: '#2C2D2A',
    },
    green: {
        100: '#C3D869',
        300: '#A7B233',
        500: '#98A637',
        550: '#47AE5E',
        700: '#478A2B',
        900: '#007055',
        950: '#005C46'
    },
    olive: {
        100: '#DCDFD1',
        200: '#E8E9E0',
        250: '#AEB592',
        300: '#D3D5C3',
        500: '#7E855B',
    },
    yellow: {
        100: '#FFEF7E',
    },
    parkland: {
        'main': '#9BB85F',
        'mainDarker': '#859E52',
        'mainLighter': '#BDE074',
        'secondary': '#47AE5E'
    },
    default: {
        'main':'#245549',
        'mainDarker': '#193B32',
        'mainLighter': '#357D6B',
        'secondary': '#D38E4E'
    },
    boreal: {
        'main':'#245549',
        'mainDarker': '#193B32',
        'mainLighter': '#357D6B',
        'secondary': '#D38E4E'
    },
    prairie: {
        'main': '#DCAF58',
        'mainDarker': '#C9A051',
        'mainLighter': '#FFCB66',
        'secondary': '#83BFAD'
    },
    foothill: {
        'main': '#56707B',
        'mainDarker': '#445861',
        'mainLighter': '#678794',
        'secondary': '#EB8363'
    }
};

const sidebarWidth = '470px';
const regionMapHeight = '400px';

module.exports = {
    purge: [
        './src/**/*.vue',
    ],
    // darkMode: false,
    theme: {
        screens: {
            mobile: { max: '639px' },
            sm: '640px',
            md: '768px',
            lg: '1024px',
            xl: '1280px',
            '2xl': '1440px',
            '3xl': '1600px'
        },
        extend: {
            rotate: {
                270: '270deg'
            },
            boxShadow: {
                'green-callout-text': '6px 0 0 #478A2B, -6px 0 0 #478A2B',
            },
            colors: {
                ...colors,
                category: {
                    agriculture: '#E1D15D',
                    energy: '#836F9A',
                    forestry: '#77AA67',
                    'human-created-waterbodies': '#08DBDD',
                    transportation: '#4E4E4E',
                    'urban-industrial': '#EB7A7A',
                },
                taxonomy: {
                    mammals: '#D68877',
                    plants: '#75A760',
                    birds: '#A57282'
                },
                aoi: {
                    background: colors.gray[100],
                    'button-bg': colors.gray[500],
                    'button-text': 'white',
                    content: colors.gray[700],
                    highlight: colors.gray[500],
                    hover: colors.gray[300],
                    title: colors.gray[500],
                },
                'aoi-explorer': {
                    'area-selected': 'white',
                    'area-selected-bg': colors.gray[500],
                    background: colors.olive[200],
                    content: colors.gray[700],
                },
                'region-explorer': {
                    'areas-pill-bg': colors.olive[200],
                    'areas-pill': colors.gray[500],
                    'areas-pill-selected': colors.olive[300],
                    'region-selected': colors.olive[200],
                },
                'region-map': {
                    'available-info': colors.olive[300],
                    'area-fill': 'white',
                    'area-stroke': colors.gray[400],
                    'area-selected': colors.gray[500],
                    'province-stroke': colors.gray[500],
                    lines: '#CCCCCC',
                    'area-zoom-fill': colors.green[500],
                    'area-zoom-stroke': colors.green[500],
                },
                header: {
                    background: colors.gray[700],
                    title: 'white',
                    org: colors.green[500],
                    'search-result-pill': colors.olive[200],
                    'search-result-hover': colors.olive[100],
                    'search-result-hover-pill': colors.olive[300],
                    'search-input': '#EDEDED',
                    'search-submit': '#98A637'
                },
                titlepage: {
                    background: colors.gray[500],
                    title: 'white',
                    subtitle: 'white'
                },
                chapter: {
                    background: colors.gray[500],
                    title: 'white',
                },
                section: {
                    alternate: colors.gray[100],
                    title: colors.gray[700],
                },
                sidepanel: {
                    background: 'white',
                    content: colors.gray[500],
                    'content-hover': colors.olive[250],
                    hover: colors.gray[300],
                    title: 'white',
                    subtitle: colors.yellow[100],
                    'button-bg': colors.olive[500],
                },
                highlight: colors.green[700],
                dot: 'white',
                text: colors.gray[500]
            },
            fill: theme => theme('colors'),
            stroke: theme => theme('colors'),
            fontFamily: {
                montserrat: ['Montserrat', 'sans-serif'],
                domine: ['Domine', 'sans-serif'],
                paragraph: ['Domine', 'sans-serif'],
                roboto: ['Roboto', 'sans-serif'],
                serif: ['PT Serif', 'sans-serif'],
            },
            fontSize: {
                'size-aoi-explorer-area': '1rem',
                'size-paragraph': '1rem',
                'size-reportsubtitle': '1rem',
                'size-charttitle': '1rem',
                'size-headertitle': '1.5rem',
                'size-headerorg': '1rem',
                'size-headerpill': '0.6rem',
                'size-reportabovetitle': '1.2rem',
                'size-sidepaneltitle': '1.2rem',
                'size-sidepanel-category': '0.9rem',
                'size-sectiontitle': '1.875rem',
                'size-chaptertitle': '2rem',
                'size-reporttitle': '3rem',
            },
            height: {
                header: '72px',
                'header-mobile': '86px',
                'header-md': '56px',
                logo: '56px',
                'logo-small': '36px',
                'region-map': regionMapHeight,
                'report-title-page': '550px',
                'trees-height': '8rem',
            },
            inset: {
                'trees-height': '8rem',
                '-trees-height': '-7.5rem',
                '1/2': '50%'
            },
            width: {
                header: '100px',
                'area-explorer': '35%',
                logo: '62px',
                'logo-small': '39px',
                'region-map': '500px',
                sidebarLg: '350px',
                sidebar2xl: '350px',
                'sidebar-collapsed': '100px',
                'view-report-btn': '500px',
                'data-plot': '1000px'
            },
            minHeight: {
                app: 'unset',
                'chapter-title-page': '750px',
                header: '100px',
                'region-map': regionMapHeight,
            },
            minWidth: {
                'area-explorer': '330px',
            },
            spacing: {
                'report-margin': '4rem',
                sidebar: sidebarWidth,
                'trees-height': '10rem',
                'report-margin-with-trees': '8rem'
            }
        }
    },
    variants: {},
    plugins: [],
};
