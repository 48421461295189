// import { SuitabilitySection } from '@/model/ABMIReport';
import type OverallSuitabilityContent from '@/model/report/OverallSuitabilityContent';
import OverallSuitabilityModel from '@/model/OverallSuitability';
import type { OverallDataType } from '@/model/OverallSuitability';
import { TaxonomicGroupIO } from '@/api/intactness';
import { watchEffect, computed, Ref, reactive, ComputedRef } from 'vue';
import { Area } from '@/model/Area';

type SuitabilitysRefs = {
    model: OverallSuitabilityModel;
    overallData: ComputedRef<OverallDataType>;
}

export default function useSuitabilityModel(aoi: Ref<Area | undefined>, content: OverallSuitabilityContent, taxonomicGroups: Record<string, TaxonomicGroupIO> | undefined): SuitabilitysRefs {
    const model = reactive<OverallSuitabilityModel>(new OverallSuitabilityModel(content, taxonomicGroups));
    const loadOverallSuitabilityModel = async (aoiToLoad:Area|undefined) => {
        if (aoiToLoad) {
            await model.load(aoiToLoad);
        }
    };
    watchEffect(() => loadOverallSuitabilityModel(aoi.value));
    const overallData = computed(() => model.overallData);
    return {
        model,
        overallData
    };
}
