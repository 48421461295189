import { NumberValue } from 'd3-scale';
import { BaseType, Selection } from 'd3-selection';
import format, { FormatOptions } from '@dha/number-format';

export function appendIfNotExists<T extends BaseType>(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    selection: Selection<any, unknown, BaseType, unknown>,
    tag: string,
    cls: string
): Selection<T, unknown, BaseType, unknown> {
    let child = selection
        .select<T>(`${tag}.${cls}`);
    if (child.empty()) {
        child = selection
            .append<T>(tag)
            .classed(cls, true);
    }

    return child;
}

export function twoSidedTickFormat(
    n: NumberValue,
    formatOptions: FormatOptions,
    matchFormatList: number[]
): string {
    return format(n.valueOf(), {
        ...formatOptions,
        matchFormatList
    });
}
