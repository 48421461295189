import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4f1c02e5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tooltip" }
const _hoisted_2 = {
  key: 0,
  class: "title"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = {
  key: 0,
  class: "description"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.title), 1))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formattedRows, (row) => {
      return (_openBlock(), _createElementBlock("div", {
        key: row.label,
        class: "row"
      }, [
        _createElementVNode("div", null, [
          _createElementVNode("span", {
            class: "label",
            innerHTML: row.label + ': '
          }, null, 8, _hoisted_3),
          _createElementVNode("span", {
            class: "value",
            innerHTML: row.value
          }, null, 8, _hoisted_4)
        ]),
        (row.description != null)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(row.description), 1))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ]))
}