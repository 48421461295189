
import { computed, defineComponent, ref, watchEffect, PropType } from 'vue';
import observeResize from '@/composables/util/observeResize';
import draw from './draw';

export default defineComponent({
    name: 'ThermometerChart',
    props: {
        currentValue: {
            type: Number,
            default: null
        },
        width: {
            type: Number,
            default: 20
        },
        colors: {
            type: Array as PropType<string[]>,
            default: () => [
                '#478A2B',
                '#6BA150',
                '#8EB773',
                '#B0CE98',
                '#D3E6BD',
                '#DBD3E6',
                '#BFB3CF',
                '#A593BA',
                '#8B73A7',
                '#72539C'
            ]
            // default: () => [
            //     '#e89ec7',
            //     '#edafd1',
            //     '#f0c2da',
            //     '#f5d5e6',
            //     '#f7e6ef',
            //     '#fafafa',
            //     '#d8e6ba',
            //     '#b7d17d',
            //     '#93bd46',
            //     '#70a800'
            // ]
            // default: () => [
            //     '#4575b4',
            //     '#5c8bbf',
            //     '#76a5cd',
            //     '#c1d1e5',
            //     '#c5e1ee',
            //     '#f8e4a7',
            //     '#fdd086',
            //     '#f47b4f',
            //     '#e5553b',
            //     '#d73027',

            // ]
        }
    },
    setup(props) {
        const container = ref<HTMLDivElement>();
        const readingcontainer = ref<HTMLDivElement>();
        const svg = ref<SVGElement>();

        // setup resize observers/computed sizes
        const {
            dimensions
        } = observeResize(container);

        const svgHeight = computed(() => {
            const h = dimensions.value.height - 60;
            if (h > 1000) {
                return 1000;
            }
            if (h < 10) {
                return 300;
            }
            return h;
        });

        const itemHeight = svgHeight.value / props.colors.length;

        // draw on data update
        watchEffect(() => {
            draw(
                svg.value,
                readingcontainer.value,
                {
                    height: svgHeight.value,
                    itemHeight,
                    itemWidth: props.width,
                    currentValue: props.currentValue,
                    colors: props.colors
                }
            );
        }, { flush: 'post' });
        return {
            svgHeight,
            itemHeight,
            container,
            readingcontainer,
            svg
        };
    }
});
