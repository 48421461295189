import { DirectiveBinding } from 'vue';

type CollapsibleElement = HTMLElement;

type CollapsibleBinding = {
    opened: boolean;
};

/**
 * https://css-tricks.com/using-css-transitions-auto-dimensions/
 */
export const collapsible = {
    mounted: (el: CollapsibleElement, binding: DirectiveBinding<CollapsibleBinding>): void => {
        if (binding.value.opened) {
            el.classList.remove('closed');
            el.classList.add('opened');
        } else {
            // eslint-disable-next-line no-param-reassign
            el.style.height = '0px';
            el.classList.add('closed');
            el.classList.remove('opened');
        }
    },
    updated: (el: CollapsibleElement, binding: DirectiveBinding<CollapsibleBinding>): void => {
        /* eslint-disable no-param-reassign */
        const scrollHeight = el.scrollHeight;
        if (binding.value.opened && scrollHeight > 0) {
            el.classList.add('opened');
            el.classList.remove('closed');
            el.style.height = `${scrollHeight}px`;
            el.style.visibility = 'visible';

            const transitionEndHandler = () => {
                el.removeEventListener('transitionend', transitionEndHandler);
                /**
                 * height == '0px' means event listener is accidentally attached to
                 * collapse transition (i.e. when you double click rapidly), in which case we do nothing
                 */
                if (el.style.height !== '0px') {
                    el.style.height = '';
                }
            };

            el.addEventListener('transitionend', transitionEndHandler);
        } else {
            el.classList.add('closed');
            el.classList.remove('opened');

            if (el.getBoundingClientRect().height === 0) {
                return;
            }

            // disable other transitions?
            const elementTransition = el.style.transition;
            el.style.transition = '';

            requestAnimationFrame(() => {
                el.style.height = `${scrollHeight}px`;
                el.style.transition = elementTransition;

                // on the next frame (as soon as the previous style change has taken effect),
                // have the element transition to height: 0
                requestAnimationFrame(() => {
                    el.style.height = '0px';
                    el.style.visibility = 'hidden';
                });
            });
        }
        /* eslint-enable no-param-reassign */
    },
};

export default collapsible;
