
import { computed, defineComponent, inject, onMounted, onUpdated, ref } from 'vue';

export default defineComponent({
    name: 'GridLayout',
    props: {
        columns: {
            type: Number,
            default: 3
        },
        mobileColumns: {
            type: Number,
            default: 1
        },
        gridTemplateColumns: {
            type: String,
            default: null
        },
        gridTemplateRows: {
            type: String,
            default: null
        },
        flow: {
            type: String,
            default: 'row'
        }
    },
    setup(props) {
        const parent = ref<HTMLDivElement>();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const screens: any = inject('screens');

        const numItems = ref(0);
        const updateNumItems = () => {
            numItems.value = parent.value?.children.length ?? 0;
        };
        onMounted(updateNumItems);
        onUpdated(updateNumItems);
        const numColumns = computed(() => (screens.mobile ? props.mobileColumns : props.columns));
        const numRows = computed(() => Math.ceil(numItems.value / numColumns.value));
        const templateColumns = computed(() => {
            if (props.gridTemplateColumns) return props.gridTemplateColumns;
            return `repeat(${numColumns.value}, minmax(0, 1fr))`;
        });
        const templateRows = computed(() => {
            if (props.gridTemplateRows) return props.gridTemplateRows;
            return `repeat(${numRows.value}, minmax(0, 1fr))`;
        });

        const gridFlow = computed(() => (['col', 'column'].includes(props.flow) ? 'column' : 'row'));

        return {
            numItems,
            numColumns,
            numRows,
            templateColumns,
            templateRows,
            gridFlow,

            parent
        };
    },
});
