
import { defineComponent, PropType, computed } from 'vue';
import type { IntactnessContent } from '@/model/report';

import useIntactness from '@/composables/state/report/useIntactness';
import { openMapPortal } from '@/util/util';
import useApp from '@/composables/state/useApp';
import ThermometerChart from '@/components/ThermometerChart/ThermometerChart.vue';
import { ABMIMap } from '@/vendor/abmi-map';
import useABMIMap from '@/composables/state/report/useABMIMap';
import SectionHeader from './SectionHeader.vue';

export default defineComponent({
    name: 'IntactnessMappedSection',
    components: {
        ABMIMap,
        ThermometerChart,
        SectionHeader
    },
    props: {
        section: {
            type: Object as PropType<IntactnessContent>,
            default: null,
        }
    },
    setup(props) {
        const { aoi, isMobile } = useApp();
        const intactnessContent = computed(() => useIntactness(aoi, props.section).model);
        const { mapData, mapInfo, mapExternalUrl } = useABMIMap(aoi, 'intactness');
        const regionCss = computed(
            () => ((aoi.value && aoi.value.regionTypeCode) ? `bg-${aoi.value?.regionTypeCode}-main` : 'bg-default-main')
        );
        const aoiId = aoi?.value?.aoiId;
        const intactnessUrl = process.env.VUE_APP_ABMI_STATIC_INTACTNESS_MAP_URL.replace('{{aoiId}}', aoiId);
        return { regionCss, intactnessContent, intactnessUrl, isMobile, mapData, mapInfo, mapExternalUrl, openMapPortal };
    }
});
