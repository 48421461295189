import _ from 'lodash';
import format, { FormatOptions } from '@dha/number-format';
import { SuitabilityDatum, TaxonomicGroupIO } from '@/api/intactness';

type TemplateVal = number | string | null;

const regexp = /{{([\s\S]+?)}}/g;
export type TemplateProps = {[x:string]: TemplateVal};

function formatText(formatOptions?: FormatOptions) {
    return (d: TemplateVal, k: string):string => {
        let formattedVal = String(d);
        if (formatOptions) {
            if (d === null) {
                formattedVal = 'null';
            } else if (Number.isFinite(d)
                && k.toLowerCase().indexOf('year') < 0
                && k.toLowerCase().indexOf('count') < 0
                // && k.toLowerCase().indexOf('index') < 0
            ) {
                // If it is a number, use the section's formatter to format.
                // Some sections have % formatter, (intactness)
                // years and count fields do not get formatted.
                // TODO: Allow user to specify field specific formatters
                // https://app.asana.com/0/1200202261109575/1200642777657771/f
                formattedVal = format(d, formatOptions);
            }
        }
        return formattedVal;
    };
}
function formatMarkdown(formatOptions?: FormatOptions) {
    const fmtText = formatText(formatOptions);
    return (d: TemplateVal, k: string):string => `${fmtText(d, k)}`;
    // return (d: TemplateVal, k: string):string => `<!-- {{${k}: -->${fmtText(d, k)}<!-- :${k}}} -->`;
}

function templateWithFormatter(content: string, props: TemplateProps, formatter:(d: TemplateVal, k: string) => string):string {
    let compiledContent = content;
    _.templateSettings.interpolate = regexp;
    const templateKeys = _.map([...content.matchAll(regexp)], (m) => m[1]);
    const formattedVals = _.mapValues(props, formatter);
    _.forEach(templateKeys, (k) => {
        formattedVals[k] = formattedVals[k] || '...'; // `{{${k}:undefined}}`;
    });
    try {
        const compiler = _.template(content);
        compiledContent = compiler(formattedVals);
    } catch (e) {
        throw new Error(`${e.message}: ${_.keys(props)}`);
    }
    return compiledContent;
}

export function templateText(content: string, props?: TemplateProps, formatOptions?: FormatOptions):string {
    if (props) {
        let compiledContent = content;
        try {
            compiledContent = templateWithFormatter(content, props, formatText(formatOptions));
        } catch (e) {
            compiledContent = `${compiledContent} {{err: ${e.message}}}`;
        }
        return compiledContent;
    }
    return content;
}
export function templateMarkdown(content: string, props?: TemplateProps, formatOptions?: FormatOptions):string {
    if (props) {
        let compiledContent = content;
        try {
            compiledContent = templateWithFormatter(content, props, formatMarkdown(formatOptions));
        } catch (e) {
            compiledContent = `${compiledContent}<div style="color:red;">${e.message}</div>`;
        }
        return `${compiledContent.replaceAll('%.<br />', '.')}`;
        // return `<!-- Start CMS Template ${JSON.stringify(props)} -->
        //         ${compiledContent.replaceAll('%.<br />', '.')}
        //         <!-- End CMS Template -->`;
    }
    return content;
}

// Returns the common/scientific names of the species given in datum, in the order according
// to taxonomy.showNames, as name/subtitle. If a name type ('scientificName'/'commonName') is
// given in showNames but does not exist in datum, return null for that name/subtitle key.
export function getSpeciesNames(datum: SuitabilityDatum, taxonomy?: TaxonomicGroupIO)
    : { name: string | null; subtitle: string | null; } {
    const showNames = taxonomy?.showNames ?? ['commonName'];
    return {
        name: showNames[0] === 'scientificName'
            ? (datum.scientificName ?? null)
            : datum.name,
        subtitle: showNames[1] === 'scientificName'
            ? (datum.scientificName ?? null)
            : showNames[1] === 'commonName'
                ? datum.name
                : null
    };
}
