import { Ref, onMounted, onUnmounted } from 'vue';
import _ from 'lodash';

let fireScrolledTo = true;
let currentRef:HTMLElement | undefined;
export const useRefInView = (
    elRef:Ref<HTMLElement | undefined>,
    onScrolledTo: () => void,
    options = {
        top: 0,
        bottom: 250,
        debounce: 50,
    }
):{ scrollTo: (smooth:boolean
) => void } => {
    const getY = () => elRef.value?.getBoundingClientRect().y || -1;
    const getHeight = () => elRef.value?.getBoundingClientRect().height || -1;
    const inView = () => {
        const y = getY();
        const h = getHeight();
        const topInView = y > options.top && y < options.bottom;
        const coversView = y < options.top && y + h > options.bottom;
        return topInView || coversView;
    };
    const checkStop = async () => {
        const isInView = inView();
        await new Promise(resolve => setTimeout(resolve, options.debounce));
        const stillInView = inView();
        if (isInView && stillInView && elRef.value !== currentRef) {
            currentRef = elRef.value;
            if (fireScrolledTo) {
                onScrolledTo();
            } else {
                fireScrolledTo = true;
            }
        }
    };
    const debounce = _.debounce(checkStop, options.debounce);
    onMounted(() => {
        window.addEventListener('scroll', debounce);
    });
    onUnmounted(() => {
        window.removeEventListener('scroll', debounce);
    });
    const scrollTo = (smooth: boolean) => {
        const el = elRef?.value;
        if (el && !inView()) {
            // Suppress firing scrolledTo when using scrollIntoView
            fireScrolledTo = false;
            el.scrollIntoView({
                behavior: smooth ? 'smooth' : 'auto',
                inline: 'center',
                block: 'start'
            });

            const layout = document.querySelector('.report-layout');
            if (layout) {
                layout.scrollLeft = 0;
            }
        }
    };
    return { scrollTo };
};
