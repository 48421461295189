import _ from 'lodash';
import { ScaleLinear } from 'd3-scale';
import seedrandom from 'seedrandom';
import { BeeswarmDatum } from './types';

const epsilon = 1e-3;

export type BeeswarmQueueItem = BeeswarmDatum & {
    x: number;
    y: number;
}

// Fisher-Yates shuffle with any RNG function, and returning a copy
export function shuffle<T>(array: T[], rng: () => number): T[] {
    const clone = _.cloneDeep(array);
    let m = clone.length;
    let t: T;
    let i: number;

    // While there remain elements to shuffle…
    while (m) {
        // Pick a remaining element…
        const n = Math.max(0, rng());
        if (n >= 1) {
            i = --m;
        } else {
            i = Math.floor(n * m--);
        }

        // And swap it with the current element.
        t = clone[m];
        clone[m] = clone[i];
        clone[i] = t;
    }

    return clone;
}

// Does this circle (x, y, r) intersect with any other circle already placed, within the padding?
export function intersects(
    x: number,
    y: number,
    r: number,
    padding: number,
    placedQueue: BeeswarmQueueItem[]
): boolean {
    return placedQueue.some(
        placed => (placed.x - x) ** 2 + (placed.y - y) ** 2 < ((r * 2 + padding) ** 2) - epsilon
    );
}

export function generateSwarm(
    data: BeeswarmDatum[],
    xScale: ScaleLinear<number, number>,
    r: number,
    padding: number,
    rng = seedrandom('abmi')
): BeeswarmQueueItem[] {
    // Place circles in a random order
    return shuffle(data, rng)
        .reduce((placedQueue: BeeswarmQueueItem[], datum) => {
            const queueItem = {
                ...datum,
                x: xScale(datum.value),
                y: 0
            };
            // Check if the circle at the given x and y = 0 would intersect another placed circle. If not, just place it
            if (intersects(queueItem.x, queueItem.y, r, padding, placedQueue)) {
                // Otherwise, find the y closest to 0 that does not cause an intersection with any placed circle
                queueItem.y = placedQueue.reduce((accY, placed) => {
                    // Try placing the circle above each placed circle
                    const aboveY = placed.y + Math.sqrt((2 * r + padding) ** 2 - (placed.x - queueItem.x) ** 2);
                    // Only use this y value if it is also closer to zero than our best y so far
                    if (Math.abs(aboveY) < accY && !intersects(queueItem.x, aboveY, r, padding, placedQueue)) return aboveY;

                    // If we can't place above, try placing below each placed circle
                    const belowY = placed.y - Math.sqrt((2 * r + padding) ** 2 - (placed.x - queueItem.x) ** 2);
                    if (Math.abs(belowY) < accY && !intersects(queueItem.x, belowY, r, padding, placedQueue)) return belowY;

                    return accY;
                }, Infinity);
            }
            placedQueue.push(queueItem);
            return placedQueue;
        }, []);
}
