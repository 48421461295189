import axios from 'axios';
import _ from 'lodash';

if (!process.env.VUE_APP_ABMI_API_BASE_URL || !process.env.VUE_APP_ABMI_API_KEY) {
    throw new Error('ABMI API Env not set: VUE_APP_ABMI_API_BASE_URL, VUE_APP_ABMI_API_KEY');
}
const version = process.env.VUE_APP_ABMI_API_VERSION || 'current';
const abmiApiUrl = process.env.VUE_APP_ABMI_API_BASE_URL;
const headers = {
    'X-API-Key': process.env.VUE_APP_ABMI_API_KEY
};

const abmiAxios = axios.create({
    baseURL: abmiApiUrl,
    params: { version },
    headers
});

export async function fetchAreasOfInterest(): Promise<unknown> {
    const apiFct = '/lookup/menu';
    try {
        const response = await abmiAxios.get(apiFct);
        const data = response.data;
        return data;
    } catch (error) {
        console.error(error);
    }
    return null;
}

const decodeAOIName = (aoiName:string):string => aoiName.replaceAll('-', ' ').replaceAll('+', '-').replaceAll('@', '/').replaceAll('&gt;', '>')
    .replaceAll('&lt;', '<');
export async function fetachAOIByName(aoi: string): Promise<Record<string, number>> {
    const apiFct = '/aoi-region-by-name';
    try {
        const response = await abmiAxios.get(apiFct, { params: { aoi: decodeAOIName(aoi) } });
        const data = response.data;
        return data.data;
    } catch (error) {
        console.error(error);
    }
    return { aoi: 0, region: 0 };
}
export async function fetchAreas(regionId: number): Promise<unknown[]> {
    const apiFct = `/aoi-list/${regionId}`;
    try {
        const response = await abmiAxios.get(apiFct);
        const data = response.data;
        return data.data.aois;
    } catch (error) {
        console.error(error);
    }
    return [];
}
export async function fetchQueriedAreas(query: string): Promise<unknown[]> {
    const apiFct = `/query/menu?keyword=${query}`;
    try {
        const response = await abmiAxios.get(apiFct);
        const data = response.data;
        return data.data;
    } catch (error) {
        console.error(error);
    }
    return [];
}
export async function fetchTaxonomicGroups(): Promise<unknown> {
    const apiFct = '/lookup/taxonomic-group';
    try {
        const response = await abmiAxios.get(apiFct);
        const data = response.data;
        return data.data;
    } catch (error) {
        console.error(error);
    }
    return null;
}
export async function fetchIntactnessIndex(aoi: number): Promise<unknown[]> {
    const apiFct = `/intactness/summary/all/${aoi}/${version}`;
    try {
        const response = await abmiAxios.get(apiFct);
        const data = response.data;
        const mapped = _.map(data.data, (d: {intactnessIndex: number}) => ({
            ...d,
            intactnessIndex: d.intactnessIndex,
        }));
        return mapped;
    } catch (error) {
        console.error(error);
    }
    return [];
}
export async function fetchFootprintTrend(aoi: number): Promise<unknown[]> {
    const apiFct = `/human-footprint/trend/${aoi}/${version}`;
    try {
        const response = await abmiAxios.get(apiFct);
        const data = response.data;
        return data.data;
    } catch (error) {
        console.error(error);
    }
    return [];
}
export async function fetchFootprintTrendCSV(aoi: number): Promise<string> {
    const apiFct = `/download/human-footprint/trend/${aoi}/${version}`;
    try {
        const response = await abmiAxios.get(apiFct);
        const data = response.data;
        return data;
    } catch (error) {
        console.error(error);
    }
    return '';
}
export async function fetchIntactnessCSV(aoi: number): Promise<string> {
    const apiFct = `/download/intactness/${aoi}/${version}`;
    try {
        const response = await abmiAxios.get(apiFct);
        const data = response.data;
        return data;
    } catch (error) {
        console.error(error);
    }
    return '';
}
export async function fetchFootprintChange(aoi: number): Promise<unknown[]> {
    const apiFct = `/human-footprint/change/${aoi}/${version}`;
    try {
        const response = await abmiAxios.get(apiFct);
        const data = response.data;
        return data.data;
    } catch (error) {
        console.error(error);
    }
    return [];
}

export async function fetchWetlandStatus(aoi: number): Promise<unknown[]> {
    const apiFct = `/wetland/status/${aoi}`; // /${version}
    try {
        const response = await abmiAxios.get(apiFct);
        const data = response.data;
        return data.data;
    } catch (error) {
        console.error(error);
    }
    return []; // testing data { id: 1, name: 'Bog', value: 0.1, color: '#f00' }, { id: 2, name: 'Fen', value: 0.2, color: '#ff0' }];
}

export async function fetchSpeciesInfo(aoi: number, category = 'biodiversity'): Promise<unknown> {
    // const apiFct = `intactness/summary/${category}/${aoi}/${version}`;
    // try {
    //     // const response = await abmiAxios.get(apiFct);
    //     // const data = response.data.data;
    //     // const sppCallOut = data[0]?.sppCallOut;
    //     // const decreaser = sppCallOut?.decreaser?.split('/')?.[1];
    //     // const increaser = sppCallOut?.increaser?.split('/')?.[1];
    //     // const decreaserSpeciesInfo = decreaser && await fetchSpeciesContent(category, decreaser);
    //     // const increaserSpeciesInfo = increaser && await fetchSpeciesContent(category, increaser);
    //     // if (decreaserSpeciesInfo?.imageLink && decreaserSpeciesInfo?.description) {
    //     //     return decreaserSpeciesInfo;
    //     // }
    //     // if (increaserSpeciesInfo?.imageLink && increaserSpeciesInfo?.description) {
    //     //     return increaserSpeciesInfo;
    //     // }
    //     return null;
    // } catch (error) {
    //     console.error(error);
    // }
    return null;
}

export async function fetchSuitabilityData(aoi: number, category?: string): Promise<unknown> {
    let sppGroupCode = category;
    if (!sppGroupCode || sppGroupCode === 'biodiversity') {
        sppGroupCode = 'all';
    }

    const apiFct = `/intactness/detail/${sppGroupCode}/${aoi}/${version}`;
    try {
        const response = await abmiAxios.get(apiFct);
        const data = response.data.data;
        const allSpecies = _.reduce(data.speciesList, (_allSpecies, species) => {
            _.assign(_allSpecies, species);
            return _allSpecies;
        }, {});
        // Content values that needs to be reshaped
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const abmiProfileUrl = process.env.VUE_APP_ABMI_PROFILE_LINK;
        const mappedSpecies: any = _.mapValues(allSpecies, (species) => _.map(species, (s: any) => {
            const intactness = s.intacntess ?? s.intactness;
            return {
                ...s,
                id: s.tsn,
                name: s.commonName,
                scientificName: s.scientificName,
                imageSrc: `${abmiProfileUrl}${s.scientificName}`,
                value: s.deviation / 100,
                intactness: !_.isNil(intactness) ? intactness / 100 : undefined
            };
        }));
        return { guildList: data.guildList, ...mappedSpecies };
    } catch (error) {
        console.error(error);
    }
    return {};
}
export async function fetchAreaOfAnalysis(aoi: number): Promise<unknown[]> {
    const apiFct = `/aoi/background/${aoi}/${version}`;
    try {
        const response = await abmiAxios.get(apiFct);
        const data = response.data;
        return data.data;
    } catch (error) {
        console.error(error);
    }
    return [];
}
const abmiApi = {
    fetchAreaOfAnalysis,
    fetchAreasOfInterest,
    fetchAreas,
    fetchQueriedAreas,
    fetchTaxonomicGroups,
    fetchIntactnessIndex,
    fetchFootprintTrend,
    fetchFootprintTrendCSV,
    fetchFootprintChange,
    fetchWetlandStatus,
    fetchSuitabilityData,
    fetchSpeciesInfo,
    fetchIntactnessCSV,
};

export default abmiApi;
