import type dataFetchApi from '@/services/data';
import _ from 'lodash';
import * as t from 'io-ts';
import { parse } from '@dha/io-ts-parse';

// TODO: replace this type with Zod typing AND convert all id: string types to this type
export type WetlandCategory =
    'Total Wetland Inventory' |
    'Bog' |
    'Fen' |
    'Open water' |
    'Marsh' |
    'Swamp';

export const WetlandCategoryById: { [x: number]: WetlandCategory } = {
    1: 'Open water',
    2: 'Fen',
    3: 'Bog',
    4: 'Marsh',
    5: 'Swamp',
    6: 'Total Wetland Inventory',
};

export const WetlandDescription: Record<string, string> = {
    'Total Wetland Inventory': '',
    Bog: 'acidic and mineral poor peatland; fed mainly by precipitation; water levels are usually fairly stable and sit below the ground surface; can have treed, shrubby, or open vegetation structure',

    Fen: 'Peatland; varied water sources (precipitation, groundwater, and/or surface water); water levels are usually consistently at or near the surface; can have treed, shrubby, or graminoid vegetation structure',

    Marsh: 'mineral soils; varied water sources (precipitation, groundwater and/or surface water); fluctuating water levels; graminoid vegetation structure',

    Swamp: 'can be mineral wetlands or peatlands, but if they have organic soil, it is typically more decomposed than in fens or bogs; varied water sources (precipitation, groundwater, and/or surface water); water levels fluctuate near the surface; can have treed or shrubby vegetation structure',

    'Open water': 'mineral soils; varied water sources (precipitation, groundwater, and/or surface water); fluctuating water levels up to 2 m deep; submersed and/or floating aquatic vegetation structure. Also includes open water areas, like lakes, that are deeper than two metres. '

};
export type WetlandDatum = {
    id: number;
    name: string;
    color: string;
    value: number;
    description?: string;
}

type DataApi = Pick<typeof dataFetchApi, 'fetchWetlandStatus'>;
type WetlandApi = {
    fetchWetlandStatus: (aoi: number) => Promise<WetlandDatum[]>;
}

function keyValuesByCategory(values: WetlandDatum[]): WetlandDatum[] {
    return _(values).filter(v => WetlandCategoryById[v.id] !== undefined).map(v => ({
        ...v,
        key: WetlandCategoryById[v.id] || v.id,
        value: v.value / 100,
    })).value();
}

export default (data: DataApi): WetlandApi => ({
    fetchWetlandStatus: async (aoiId: number): Promise<WetlandDatum[]> => {
        const apiData = await data.fetchWetlandStatus(aoiId);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const parsedData = apiData as Array<any>;
        // TODO: Use IO-TS for parsing/mapping/validating
        const mappedData = _.map(parsedData, (d) => ({
            ...d,
            values: keyValuesByCategory(d.values),
        }));
        return mappedData as WetlandDatum[];
    },
});
