/**
 * This file will contain the API to get config.
 * Hard coded for now.
 */
/* eslint-disable global-require */
import _ from 'lodash';

const defaultPctFormat = {
    type: 'percent',
    decimals: 1
};

const defaultConfig = {
    format: defaultPctFormat
};

// Legend is for guild flags.
// The id corresponds to the id from /intactness/detail/ API
// IDs must be configured here with an image icon to show up on chart
const suitabilityConfig = {
    legend: [
        { id: 486, imageSrc: '/img/atRisk.svg' },
        { id: 570, imageSrc: '/img/invasive.svg' }
    ],
    format: {
        type: 'percent',
        decimals: 0,
        truncate: true,
        addPositiveSign: true,
    }
};
const overallSuitabilityConfig = {
    colors: {
        mammals: '#C8604A',
        vplants: '#75A760',
        birds: '#000000'
    },
    format: {
        type: 'percent',
        decimals: 1,
        truncate: true,
        addPositiveSign: false,
    }
};
const footprintFormat = {
    type: 'percent',
    decimals: 1
};
const areaConfig = {
    format: {
        type: 'default',
        decimals: 0,
        truncate: false
    }
};
const methodsConfig = {
    format: {
        type: 'default',
        decimals: 0,
        truncate: false
    }
};
/*  use mulitple text for different cases,
    when starts from 0, can't calcuate % increases

    Can we use the absolute difference in percentage points to report on trend. Examples:

Default/zero start example --> Total human footprint increased by xx percentage points, from xx% to xx% (xx ha to xx ha) between 2000 and 2019.
Translates to something like: {{categoryName}} footprint has {{increased/decreased}} by {{percentage point change}}({{startArea}} ha to {{endArea}} ha) between {{ startYear }} and {{endYear}}.

No change example --> The area of "total human footprint" did not change between 2000 and 2019, covering xx ha.
Translates to something like: The area of {{categoryName}} did not change between {{ startYear }} and {{endYear}}, covering {{endArea}} ha.

*/
const infoLabel = {
    default: {
        default: '{{ categoryName }} footprint {{ diffAdjective }} by {{ diffPct }} percentage points between {{ startYear }} and {{ endYear }}, from {{ startPct }}% to {{ endPct }}% ({{startArea}} ha to {{ endArea }} ha).',
        unchanged: 'The area of {{categoryName}} footprint did not change between {{ startYear }} and {{endYear}}, covering {{ endPct }}% ({{endArea}} ha).',
    },
    change: {
        default: 'Between {{ startYear }} and {{ endYear }}, {{ categoryName }} {{ diffAdjective }} by {{ changePct }} percentage points, from {{ startPct }}% to {{ endPct }}%.',
        unchanged: 'Between {{ startYear }} and {{ endYear }}, {{ categoryName }} stay at {{ startPct }}%.',
        zeroStart: 'Between {{ startYear }} and {{ endYear }}, {{ categoryName }} {{ diffAdjective }} from {{ startPct }}% to {{ endPct }}%.',

    }
};

const footprintConfig = {
    changeFormat: {
        type: 'default',
        decimals: 1,
        truncate: false
    },
    categories: {
        agriculture: {
            key: 'agriculture',
            icon: 'agriculture.svg',
            name: 'Agriculture',
            color: '#E1D15D',
            infoLabel,
            format: footprintFormat,
            isMainCategory: true,
            isTotalCategory: false,
            description: 'Areas of annual or perennial cultivation, including crops, tame pasture, confined feeding operations, and other high-density livestock areas.'
        },
        energy: {
            key: 'energy',
            icon: 'energy.svg',
            name: 'Energy',
            color: '#836F9A',
            infoLabel,
            format: footprintFormat,
            isMainCategory: true,
            isTotalCategory: false,
            description: `Areas where vegetation or soil has been disturbed by footprint
                types associated with the energy sector (e.g. mine sites, pipelines, seismic lines,
                well sites, wind-generation facilities). This category also includes oil and gas related industrial facilities.
            `,
        },
        forestry: {
            key: 'forestry',
            icon: 'forestry.svg',
            name: 'Forestry',
            color: '#77AA67',
            infoLabel,
            format: footprintFormat,
            isMainCategory: true,
            isTotalCategory: false,
            subcategories: ['forestry-recovered'],
            dottedLineStyle: true,
            description: `Total area in forested landscapes where timber resource extraction has occurred for industrial purposes,
                including clear-cut and partial-cut logging methods.
                Does not account for recovery of forestry footprint.`,
        },
        'human-created-waterbodies': {
            key: 'human-created-waterbodies',
            icon: 'waterbodies.svg',
            name: 'Human-Created Water Bodies',
            color: '#08DBDD',
            infoLabel,
            format: footprintFormat,
            isMainCategory: true,
            isTotalCategory: false,
            description: `Waterbodies created for a variety of purposes
            (e.g., borrow-pits, water treatment, dugouts, canals, reservoirs).`
        },
        transportation: {
            key: 'transportation',
            icon: 'transportation.svg',
            name: 'Transportation',
            color: '#4E4E4E',
            infoLabel,
            format: footprintFormat,
            isMainCategory: true,
            isTotalCategory: false,
            description: `Transportation: Railways, roadways, and trails with hard surfaces such as concrete, asphalt, or gravel;
            roads or trails without hard surfaces; and the vegetation strips alongside transportation features.`
        },
        'urban-industrial': {
            key: 'urban-industrial',
            name: 'Urban/Industrial',
            icon: 'urban.svg',
            color: '#EB7A7A',
            infoLabel,
            format: footprintFormat,
            isMainCategory: true,
            isTotalCategory: false,
            description: `Residences, buildings, and disturbed vegetation and substrate associated with urban and rural settlements
                    (e.g., housing, industrial areas, golf courses). This category does not include industrial facilities associated with the oil and gas industry;
                    these are included in the Energy footprint category.`
        },
        'forestry-recovered': {
            key: 'forestry-recovered',
            icon: 'effectiveForestry.svg',
            name: 'Forestry, Net',
            description: 'The area of forestry footprint prorated for how much it has recovered for biodiversity, based on harvest area age and stand type (deciduous or conifer).',
            color: '#77AA67', // '#A7B233',
            infoLabel,
            format: footprintFormat,
            isMainCategory: false,
            isTotalCategory: false,
            dottedLineStyle: false,
        },
        'total-human-footprint': {
            key: 'total-human-footprint',
            icon: 'totalFootprint.svg',
            name: 'Total Human Footprint',
            color: '#A7B233',
            infoLabel,
            format: footprintFormat,
            isMainCategory: false,
            isTotalCategory: true,
            description: `Summary of six human footprint categories combined: agriculture,
            energy, forestry, human-created waterbodies, transportation, and urban/industrial.`

        },

    },
    hoverHint: {
        stackBar: 'Hover over a human footprint icon for the definition of each human footprint category.',
        trendLine: 'Hover over trend line in each graph to see percent area of human footprint category by year.',
    }
};

const intactnessConfig = {
    imageSrc: '/img/intactness.png',
    explanation: '',
    // `The index declines as suitable habitat for each species
    // is decreased or increased by human footprint compared to the same area without human footprint.`,
    title: 'Intactness',
    // format: {
    //     type: 'percent',
    //     decimals: 0,
    //     truncate: true,
    //     addPositiveSign: true,
    // }
};

export const indiviudalIntactnessFormat = {
    type: 'percent',
    decimals: 0,
    truncate: true,
    addPositiveSign: true,
};

export const indiviudalIntactnessNoSignFormat = {
    ...indiviudalIntactnessFormat,
    addPositiveSign: false,
};

/**
 * Populates the config for each section key
 */
export async function fetchConfig(keys:Record<string, string>): Promise<unknown> {
    return _.mapValues(keys, (v, k) => {
        if (k === 'area') {
            return { ...defaultConfig, ...areaConfig };
        }
        if (k === 'methods') {
            return { ...defaultConfig, ...methodsConfig };
        }
        if (k === 'overallSuitability') {
            return { ...defaultConfig, ...overallSuitabilityConfig, ...intactnessConfig };
        } if (k.endsWith('Suitability')) {
            return { ...defaultConfig, ...suitabilityConfig };
        }
        if (k.endsWith('Intactness')) {
            return { ...defaultConfig, ...intactnessConfig };
        }
        // Intactness Chapters
        if (k === 'biodiversity' || k === 'mammals' || k === 'birds' || k === 'vplants') {
            return { ...defaultConfig, ...intactnessConfig };
        }
        if (k === 'footprintTrends' || k === 'footprintMapped') {
            return { ...defaultConfig, ...footprintConfig };
        }
        return defaultConfig;
    });
}
