/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios';

// Sample API call
// TODO: Replace all mocks below.
const abmiApiUrl = 'https://dev-api-alpac.abmi.ca//report';
const abmiAxios = axios.create({
    baseURL: abmiApiUrl
});

// getSpeciesIntactnessFigure
export async function getSpeciesIntactness(areaID: number): Promise<unknown> {
    const apiFct = '/getSpeciesIntactnessFigure';
    const params = {
        sppGroup: 2,
        version: 2020,
        areaID
    };
    try {
        const response = await abmiAxios.get(apiFct, { params });
        const data = response.data;
        return data;
    } catch (error) {
        console.error(error);
    }
    return null;
}

const stubAreasOfInterestIO = {
    data: [
        {
            id: 1,
            name: 'Frequently Viewed Regions',
            regions: [
                {
                    name: 'Counties & Municipalities',
                    aoiCount: 86,
                    regionId: 23
                },
                {
                    name: 'Green & White Areas',
                    aoiCount: 2,
                    regionId: 21
                },
                {
                    name: 'Land-Use Planning Regions',
                    aoiCount: 7,
                    regionId: 17
                },
                {
                    name: 'Natural Regions & Sub-Regions',
                    aoiCount: 27,
                    regionId: 16
                },
                {
                    name: 'Oil Sands Areas',
                    aoiCount: 5,
                    regionId: 18
                }
            ]
        },
        {
            id: 2,
            name: 'Parks & Protected Areas',
            regions: [
                {
                    name: 'National Parks',
                    aoiCount: 5,
                    regionId: 7843
                },
                {
                    name: 'Natural Areas',
                    aoiCount: 138,
                    regionId: 7893
                },
                {
                    name: 'Provincial Parks',
                    aoiCount: 76,
                    regionId: 8032
                },
                {
                    name: 'Provincial Parks Protected Areas',
                    aoiCount: 1,
                    regionId: 7873
                },
                {
                    name: 'Provincial Recreation Areas',
                    aoiCount: 203,
                    regionId: 8109
                },
                {
                    name: 'Wildland Provincial Parks',
                    aoiCount: 34,
                    regionId: 8317
                }
            ]
        },
        {
            id: 3,
            name: 'Additional Regions',
            regions: [
                {
                    name: 'Caribou Ranges',
                    aoiCount: 17,
                    regionId: 22
                },
                {
                    name: 'Department Of National Defence Areas',
                    aoiCount: 22,
                    regionId: 7849
                },
                {
                    name: 'Ecological Reserves',
                    aoiCount: 15,
                    regionId: 7874
                },
                {
                    name: 'First Nations Reserves & Metis Settlements',
                    aoiCount: 146,
                    regionId: 8353
                },
                {
                    name: 'Forest Management Agreement Areas',
                    aoiCount: 20,
                    regionId: 19
                },
                {
                    name: 'Heritage Rangelands',
                    aoiCount: 2,
                    regionId: 7890
                },
                {
                    name: 'Wilderness Areas',
                    aoiCount: 3,
                    regionId: 8313
                },
                {
                    name: 'Wildlife Management Units',
                    aoiCount: 188,
                    regionId: 9492
                },
                {
                    name: 'WPAC Regions',
                    aoiCount: 11,
                    regionId: 20
                }
            ]
        }
    ],
    geojsonUrlPrefix: 'https://tiles.development.maps.abmi.ca/v1/geojson/'
};
const stubTaxonomicGroupsIO = [
    {
        id: 10,
        code: 'biodiversity',
        name: 'Combined Taxonomic Groups',
        showNames: null
    },
    {
        id: 6,
        code: 'invertebra',
        name: 'Aquatic Inveterbrates',
        showNames: ['scientificName']
    },
    {
        id: 8,
        code: 'nnplants',
        name: 'Non-native Vascular Plants',
        showNames: null
    },
    {
        id: null,
        code: 'all',
        name: 'All Taxonomic Groups',
        showNames: null
    },
    {
        id: 7,
        code: 'mammals',
        name: 'Mammals',
        showNames: ['commonName']
    },
    {
        id: 9,
        code: 'amphibian',
        name: 'Amphibia',
        showNames: null
    },
    {
        id: 5,
        code: 'mosses',
        name: 'Bryophytes',
        showNames: ['scientificName']
    },
    {
        id: 1, code: 'birds', name: 'Birds', showNames: ['commonName'] },
    {
        id: 3,
        code: 'mites',
        name: 'Soil Mites',
        showNames: ['scientificName']
    },
    { id: 20, code: 'habitats', name: 'Habitats', showNames: null },
    {
        id: 2,
        code: 'vplants',
        name: 'Vascular Plants',
        showNames: ['commonName', 'scientificName']
    },
    {
        id: 4,
        code: 'lichens',
        name: 'Lichens',
        showNames: ['scientificName']
    }
];
const testRegionAreasDatum = [
    {
        aoiId: 100,
        aoiName: 'Stub 1',
    },
    {
        aoiId: 101,
        aoiName: 'Stub 2',
        sections: [
            {
                id: 1,
                title: 'Human Footprint',
                isAvailable: true
            },
            {
                id: 2,
                key: 'intactness',
                isAvailable: true
            }
        ]
    }
];
const aoiList: Record<number, {aois: unknown[], geojsonUrl: string}> = {
    23: {
        aois: [
            {
                aoiId: 120,
                aoiName: 'Athabasca County',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 121,
                aoiName: 'Banff I.D. No. 9',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 123,
                aoiName: 'Beaver County',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 125,
                aoiName: 'Big Lakes County',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 126,
                aoiName: 'Birch Hills County',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 128,
                aoiName: 'Brazeau County',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 129,
                aoiName: 'Calgary',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 130,
                aoiName: 'Camrose County',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 131,
                aoiName: 'Cardston County',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 132,
                aoiName: 'Clear Hills County',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 133,
                aoiName: 'Clearwater County',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 122,
                aoiName: 'County of Barrhead No. 11',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 141,
                aoiName: 'County of Forty Mile No. 8',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 142,
                aoiName: 'County of Grande Prairie No. 1',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 156,
                aoiName: 'County of Minburn No. 27',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 160,
                aoiName: 'County of Newell',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 161,
                aoiName: 'County of Northern Lights',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 164,
                aoiName: 'County of Paintearth No. 18',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 182,
                aoiName: 'County of Stettler No. 6',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 183,
                aoiName: 'County of St. Paul No. 19',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 188,
                aoiName: 'County of Two Hills No. 21',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 189,
                aoiName: 'County of Vermilion River',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 192,
                aoiName: 'County of Warner No. 5',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 195,
                aoiName: 'County of Wetaskiwin No. 10',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 135,
                aoiName: 'Cypress County',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 3702,
                aoiName: 'Drumheller',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: false
                    }
                ]
            },
            {
                aoiId: 136,
                aoiName: 'Edmonton',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 137,
                aoiName: 'Elk Island I.D. No. 13',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: false
                    }
                ]
            },
            {
                aoiId: 139,
                aoiName: 'Flagstaff County',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 140,
                aoiName: 'Foothills County',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 3700,
                aoiName: 'Fort Saskatchewan',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: false
                    }
                ]
            },
            {
                aoiId: 144,
                aoiName: 'I.D. No. 349',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 145,
                aoiName: 'Jasper I.D. No. 12',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 146,
                aoiName: 'Kananaskis I.D.',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 147,
                aoiName: 'Kneehill County',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 148,
                aoiName: 'Lac La Biche County',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 149,
                aoiName: 'Lacombe County',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 150,
                aoiName: 'Lac Ste. Anne County',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 151,
                aoiName: 'Lamont County',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 152,
                aoiName: 'Leduc County',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 154,
                aoiName: 'Lethbridge County',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 155,
                aoiName: 'Mackenzie County',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 119,
                aoiName: 'M.D. of Acadia No. 34',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 124,
                aoiName: 'M.D. of Bighorn No. 8',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 127,
                aoiName: 'M.D. of Bonnyville No. 87',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 138,
                aoiName: 'M.D. of Fairview No. 136',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 143,
                aoiName: 'M.D. of Greenview No. 16',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 153,
                aoiName: 'M.D. of Lesser Slave River No. 124',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 163,
                aoiName: 'M.D. of Opportunity No. 17',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 166,
                aoiName: 'M.D. of Peace No. 135',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 167,
                aoiName: 'M.D. of Pincher Creek No. 9',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 169,
                aoiName: 'M.D. of Provost No. 52',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 170,
                aoiName: 'M.D. of Ranchland No. 66',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 176,
                aoiName: 'M.D. of Smoky River No. 130',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 180,
                aoiName: 'M.D. of Spirit River No. 133',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 186,
                aoiName: 'M.D. of Taber',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 191,
                aoiName: 'M.D. of Wainwright No. 61',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 198,
                aoiName: 'M.D. of Willow Creek No. 26',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 157,
                aoiName: 'Mountain View County',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 158,
                aoiName: 'Municipality of Crowsnest Pass',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: false
                    }
                ]
            },
            {
                aoiId: 159,
                aoiName: 'Municipality of Jasper',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 162,
                aoiName: 'Northern Sunrise County',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 165,
                aoiName: 'Parkland County',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 168,
                aoiName: 'Ponoka County',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 171,
                aoiName: 'Red Deer County',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 172,
                aoiName: 'Regional Municipality of Wood Buffalo',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 173,
                aoiName: 'Rocky View County',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 174,
                aoiName: 'Saddle Hills County',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 175,
                aoiName: 'Smoky Lake County',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 177,
                aoiName: 'Special Areas No. 2',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 178,
                aoiName: 'Special Areas No. 3',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 179,
                aoiName: 'Special Areas No. 4',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 3701,
                aoiName: 'St. Albert',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: false
                    }
                ]
            },
            {
                aoiId: 181,
                aoiName: 'Starland County',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 184,
                aoiName: 'Strathcona County',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 185,
                aoiName: 'Sturgeon County',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 187,
                aoiName: 'Thorhild County',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 190,
                aoiName: 'Vulcan County',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 193,
                aoiName: 'Waterton I.D. No. 4',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 194,
                aoiName: 'Westlock County',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 196,
                aoiName: 'Wheatland County',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 197,
                aoiName: 'Willmore Wilderness I.D. No. 25',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 199,
                aoiName: 'Wood Buffalo I.D. No. 24',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 200,
                aoiName: 'Woodlands County',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            },
            {
                aoiId: 201,
                aoiName: 'Yellowhead County',
                sections: [
                    {
                        id: 1,
                        key: 'humanFootprint',
                        isAvailable: true
                    },
                    {
                        id: 2,
                        key: 'intactness',
                        isAvailable: true
                    }
                ]
            }
        ],
        geojsonUrl: 'https://tiles.development.maps.abmi.ca/v1/geojson/23'
    }
};
const footprintDataCSV = '';
const footprintData = [
    {
        year: 1999,
        values: [
            {
                id: 2,
                name: 'Agriculture',
                value: 0.202899425385075,
                lowerConfidenceInterval: 0.20175642,
                upperConfidenceInterval: 0.2039993345
            },
            {
                id: 3,
                name: 'Energy',
                value: 1.27545897739962,
                lowerConfidenceInterval: 1.229701337,
                upperConfidenceInterval: 1.3272094290000001
            },
            {
                id: 4,
                name: 'Forestry',
                value: 1.97218450122434,
                lowerConfidenceInterval: 1.821758645,
                upperConfidenceInterval: 2.112104131
            },
            {
                id: 5,
                name: 'Human-created Water Bodies',
                value: 0.212266671836091,
                lowerConfidenceInterval: 0.191458444,
                upperConfidenceInterval: 0.226490656
            },
            {
                id: 6,
                name: 'Transportation',
                value: 1.33712101164025,
                lowerConfidenceInterval: 1.3186476110000001,
                upperConfidenceInterval: 1.353902656
            },
            {
                id: 7,
                name: 'Urban/Industrial',
                value: 0.758143949634477,
                lowerConfidenceInterval: 0.722162004,
                upperConfidenceInterval: 0.794509881
            }
        ]
    },
    {
        year: 2004,
        values: [
            {
                id: 'agriculture',
                name: 'Agriculture',
                value: 0.204896354694308,
                lowerConfidenceInterval: 0.2039800709,
                upperConfidenceInterval: 0.20577013900000002
            },
            {
                id: 'energy',
                name: 'Energy',
                value: 1.3784411194042102,
                lowerConfidenceInterval: 1.3345710770000001,
                upperConfidenceInterval: 1.420269725
            },
            {
                id: 'forestry',
                name: 'Forestry',
                value: 2.6575214124905103,
                lowerConfidenceInterval: 2.524552602,
                upperConfidenceInterval: 2.783803336
            },
            {
                id: 'human-created-waterbodies',
                name: 'Human-created Water Bodies',
                value: 0.22558102633984303,
                lowerConfidenceInterval: 0.211285596,
                upperConfidenceInterval: 0.234454661
            },
            {
                id: 'transportation',
                name: 'Transportation',
                value: 1.37464546324367,
                lowerConfidenceInterval: 1.357840372,
                upperConfidenceInterval: 1.391449353
            },
            {
                id: 'urban-industrial',
                name: 'Urban/Industrial',
                value: 0.813170331630577,
                lowerConfidenceInterval: 0.782052264,
                upperConfidenceInterval: 0.845460976
            }
        ]
    },
    {
        year: 2005,
        values: [
            {
                id: 'agriculture',
                name: 'Agriculture',
                value: 0.20533501235409102,
                lowerConfidenceInterval: 0.20449001349999998,
                upperConfidenceInterval: 0.206118532
            },
            {
                id: 'energy',
                name: 'Energy',
                value: 1.4124681489779,
                lowerConfidenceInterval: 1.365887963,
                upperConfidenceInterval: 1.4568785099999999
            },
            {
                id: 'forestry',
                name: 'Forestry',
                value: 2.7759354889790103,
                lowerConfidenceInterval: 2.631330837,
                upperConfidenceInterval: 2.920494623
            },
            {
                id: 'human-created-waterbodies',
                name: 'Human-created Water Bodies',
                value: 0.226323165287174,
                lowerConfidenceInterval: 0.213893876,
                upperConfidenceInterval: 0.236195376
            },
            {
                id: 'transportation',
                name: 'Transportation',
                value: 1.38566203881818,
                lowerConfidenceInterval: 1.367094896,
                upperConfidenceInterval: 1.40082006
            },
            {
                id: 'urban-industrial',
                name: 'Urban/Industrial',
                value: 0.829619693351837,
                lowerConfidenceInterval: 0.797647761,
                upperConfidenceInterval: 0.86268978
            }
        ]
    },
    {
        year: 2006,
        values: [
            {
                id: 'agriculture',
                name: 'Agriculture',
                value: 0.20552286957575902,
                lowerConfidenceInterval: 0.2046771251,
                upperConfidenceInterval: 0.20630719330000002
            },
            {
                id: 'energy',
                name: 'Energy',
                value: 1.43187163798262,
                lowerConfidenceInterval: 1.3869177049999999,
                upperConfidenceInterval: 1.476316521
            },
            {
                id: 'forestry',
                name: 'Forestry',
                value: 2.9683672450949498,
                lowerConfidenceInterval: 2.835020833,
                upperConfidenceInterval: 3.094539145
            },
            {
                id: 'human-created-waterbodies',
                name: 'Human-created Water Bodies',
                value: 0.227410240940146,
                lowerConfidenceInterval: 0.214592256,
                upperConfidenceInterval: 0.236235404
            },
            {
                id: 'transportation',
                name: 'Transportation',
                value: 1.39640929702581,
                lowerConfidenceInterval: 1.38025378,
                upperConfidenceInterval: 1.410339446
            },
            {
                id: 'urban-industrial',
                name: 'Urban/Industrial',
                value: 0.852553573808568,
                lowerConfidenceInterval: 0.818122523,
                upperConfidenceInterval: 0.890424662
            }
        ]
    },
    {
        year: 2007,
        values: [
            {
                id: 'agriculture',
                name: 'Agriculture',
                value: 0.205676222666843,
                lowerConfidenceInterval: 0.2048616376,
                upperConfidenceInterval: 0.2064084749
            },
            {
                id: 'energy',
                name: 'Energy',
                value: 1.4669433750616101,
                lowerConfidenceInterval: 1.422684535,
                upperConfidenceInterval: 1.5097124799999998
            },
            {
                id: 'forestry',
                name: 'Forestry',
                value: 3.17755003472174,
                lowerConfidenceInterval: 3.042890891,
                upperConfidenceInterval: 3.28887925
            },
            {
                id: 'human-created-waterbodies',
                name: 'Human-created Water Bodies',
                value: 0.22806987511578,
                lowerConfidenceInterval: 0.216020494,
                upperConfidenceInterval: 0.237577571
            },
            {
                id: 'transportation',
                name: 'Transportation',
                value: 1.4062025529354101,
                lowerConfidenceInterval: 1.389953805,
                upperConfidenceInterval: 1.419858061
            },
            {
                id: 'urban-industrial',
                name: 'Urban/Industrial',
                value: 0.8618957797472569,
                lowerConfidenceInterval: 0.8270825459999999,
                upperConfidenceInterval: 0.905155203
            }
        ]
    },
    {
        year: 2008,
        values: [
            {
                id: 'agriculture',
                name: 'Agriculture',
                value: 0.205583627149093,
                lowerConfidenceInterval: 0.2048296548,
                upperConfidenceInterval: 0.2062997849
            },
            {
                id: 'energy',
                name: 'Energy',
                value: 1.5081350822931501,
                lowerConfidenceInterval: 1.464960408,
                upperConfidenceInterval: 1.550146593
            },
            {
                id: 'forestry',
                name: 'Forestry',
                value: 3.2868118608389396,
                lowerConfidenceInterval: 3.1779468240000004,
                upperConfidenceInterval: 3.393884647
            },
            {
                id: 'human-created-waterbodies',
                name: 'Human-created Water Bodies',
                value: 0.229675958130961,
                lowerConfidenceInterval: 0.21719391699999997,
                upperConfidenceInterval: 0.238358621
            },
            {
                id: 'transportation',
                name: 'Transportation',
                value: 1.41232706320894,
                lowerConfidenceInterval: 1.395683001,
                upperConfidenceInterval: 1.425675757
            },
            {
                id: 'urban-industrial',
                name: 'Urban/Industrial',
                value: 0.874559255317052,
                lowerConfidenceInterval: 0.843957636,
                upperConfidenceInterval: 0.902428192
            }
        ]
    },
    {
        year: 2009,
        values: [
            {
                id: 'agriculture',
                name: 'Agriculture',
                value: 0.205714147946734,
                lowerConfidenceInterval: 0.20498467680000002,
                upperConfidenceInterval: 0.2064267608
            },
            {
                id: 'energy',
                name: 'Energy',
                value: 1.52198238576471,
                lowerConfidenceInterval: 1.477347418,
                upperConfidenceInterval: 1.558908499
            },
            {
                id: 'forestry',
                name: 'Forestry',
                value: 3.35698174176128,
                lowerConfidenceInterval: 3.24432016,
                upperConfidenceInterval: 3.4654629199999995
            },
            {
                id: 'human-created-waterbodies',
                name: 'Human-created Water Bodies',
                value: 0.230755405580462,
                lowerConfidenceInterval: 0.218683348,
                upperConfidenceInterval: 0.239759036
            },
            {
                id: 'transportation',
                name: 'Transportation',
                value: 1.41622415418131,
                lowerConfidenceInterval: 1.399914365,
                upperConfidenceInterval: 1.4300248339999998
            },
            {
                id: 'urban-industrial',
                name: 'Urban/Industrial',
                value: 0.8782042036791081,
                lowerConfidenceInterval: 0.848308657,
                upperConfidenceInterval: 0.909868619
            }
        ]
    },
    {
        year: 2010,
        values: [
            {
                id: 'agriculture',
                name: 'Agriculture',
                value: 0.205785454717536,
                lowerConfidenceInterval: 0.20506284789999998,
                upperConfidenceInterval: 0.2064766243
            },
            {
                id: 'energy',
                name: 'Energy',
                value: 1.54202393022878,
                lowerConfidenceInterval: 1.503870811,
                upperConfidenceInterval: 1.576879138
            },
            {
                id: 'forestry',
                name: 'Forestry',
                value: 3.47903779301066,
                lowerConfidenceInterval: 3.3727579109999996,
                upperConfidenceInterval: 3.586845079
            },
            {
                id: 'human-created-waterbodies',
                name: 'Human-created Water Bodies',
                value: 0.231308919728302,
                lowerConfidenceInterval: 0.220199826,
                upperConfidenceInterval: 0.23924356
            },
            {
                id: 'transportation',
                name: 'Transportation',
                value: 1.42306730981767,
                lowerConfidenceInterval: 1.4045619970000001,
                upperConfidenceInterval: 1.436861277
            },
            {
                id: 'urban-industrial',
                name: 'Urban/Industrial',
                value: 0.888491404141249,
                lowerConfidenceInterval: 0.860122023,
                upperConfidenceInterval: 0.917488503
            }
        ]
    },
    {
        year: 2011,
        values: [
            {
                id: 'agriculture',
                name: 'Agriculture',
                value: 0.20622391002910098,
                lowerConfidenceInterval: 0.20558327710000002,
                upperConfidenceInterval: 0.2068805292
            },
            {
                id: 'energy',
                name: 'Energy',
                value: 1.59908243400233,
                lowerConfidenceInterval: 1.5617944020000002,
                upperConfidenceInterval: 1.632618531
            },
            {
                id: 'forestry',
                name: 'Forestry',
                value: 3.59222175409241,
                lowerConfidenceInterval: 3.491866233,
                upperConfidenceInterval: 3.695030787
            },
            {
                id: 'human-created-waterbodies',
                name: 'Human-created Water Bodies',
                value: 0.23670258899899698,
                lowerConfidenceInterval: 0.22546828,
                upperConfidenceInterval: 0.24387476699999997
            },
            {
                id: 'transportation',
                name: 'Transportation',
                value: 1.44579652216982,
                lowerConfidenceInterval: 1.4276784780000001,
                upperConfidenceInterval: 1.459942552
            },
            {
                id: 'urban-industrial',
                name: 'Urban/Industrial',
                value: 0.925107424064036,
                lowerConfidenceInterval: 0.897258008,
                upperConfidenceInterval: 0.956605011
            }
        ]
    },
    {
        year: 2012,
        values: [
            {
                id: 'agriculture',
                name: 'Agriculture',
                value: 0.207239552511649,
                lowerConfidenceInterval: 0.206679915,
                upperConfidenceInterval: 0.2077791604
            },
            {
                id: 'energy',
                name: 'Energy',
                value: 1.65825539887668,
                lowerConfidenceInterval: 1.621848142,
                upperConfidenceInterval: 1.687388807
            },
            {
                id: 'forestry',
                name: 'Forestry',
                value: 3.7235560414093795,
                lowerConfidenceInterval: 3.623406735,
                upperConfidenceInterval: 3.827348197
            },
            {
                id: 'human-created-waterbodies',
                name: 'Human-created Water Bodies',
                value: 0.242321261962858,
                lowerConfidenceInterval: 0.233146326,
                upperConfidenceInterval: 0.248406147
            },
            {
                id: 'transportation',
                name: 'Transportation',
                value: 1.45761709224744,
                lowerConfidenceInterval: 1.441533449,
                upperConfidenceInterval: 1.472096402
            },
            {
                id: 'urban-industrial',
                name: 'Urban/Industrial',
                value: 0.979099580531308,
                lowerConfidenceInterval: 0.948089466,
                upperConfidenceInterval: 1.009415539
            }
        ]
    },
    {
        year: 2013,
        values: [
            {
                id: 'agriculture',
                name: 'Agriculture',
                value: 0.207520219174503,
                lowerConfidenceInterval: 0.206976015,
                upperConfidenceInterval: 0.2079961301
            },
            {
                id: 'energy',
                name: 'Energy',
                value: 1.70279688866432,
                lowerConfidenceInterval: 1.6644249280000002,
                upperConfidenceInterval: 1.729491288
            },
            {
                id: 'forestry',
                name: 'Forestry',
                value: 3.88878607734504,
                lowerConfidenceInterval: 3.794818405,
                upperConfidenceInterval: 3.9783408310000004
            },
            {
                id: 'human-created-waterbodies',
                name: 'Human-created Water Bodies',
                value: 0.247486320935932,
                lowerConfidenceInterval: 0.23950928699999997,
                upperConfidenceInterval: 0.252639293
            },
            {
                id: 'transportation',
                name: 'Transportation',
                value: 1.48253491074358,
                lowerConfidenceInterval: 1.4663934,
                upperConfidenceInterval: 1.494770204
            },
            {
                id: 'urban-industrial',
                name: 'Urban/Industrial',
                value: 1.0119508450213099,
                lowerConfidenceInterval: 0.982941065,
                upperConfidenceInterval: 1.040669319
            }
        ]
    },
    {
        year: 2014,
        values: [
            {
                id: 'agriculture',
                name: 'Agriculture',
                value: 0.20766201920753102,
                lowerConfidenceInterval: 0.2072669191,
                upperConfidenceInterval: 0.2080784574
            },
            {
                id: 'energy',
                name: 'Energy',
                value: 1.74690135398191,
                lowerConfidenceInterval: 1.724270746,
                upperConfidenceInterval: 1.767671391
            },
            {
                id: 'forestry',
                name: 'Forestry',
                value: 3.97246647750493,
                lowerConfidenceInterval: 3.887760263,
                upperConfidenceInterval: 4.060879193
            },
            {
                id: 'human-created-waterbodies',
                name: 'Human-created Water Bodies',
                value: 0.248203193612212,
                lowerConfidenceInterval: 0.240172677,
                upperConfidenceInterval: 0.252823004
            },
            {
                id: 'transportation',
                name: 'Transportation',
                value: 1.4913019060218899,
                lowerConfidenceInterval: 1.4742491419999998,
                upperConfidenceInterval: 1.504128107
            },
            {
                id: 'urban-industrial',
                name: 'Urban/Industrial',
                value: 1.02593078149422,
                lowerConfidenceInterval: 0.996908466,
                upperConfidenceInterval: 1.052185364
            }
        ]
    },
    {
        year: 2015,
        values: [
            {
                id: 'agriculture',
                name: 'Agriculture',
                value: 0.20817954771476402,
                lowerConfidenceInterval: 0.2077926009,
                upperConfidenceInterval: 0.2085444578
            },
            {
                id: 'energy',
                name: 'Energy',
                value: 1.77912263801156,
                lowerConfidenceInterval: 1.756904771,
                upperConfidenceInterval: 1.798974556
            },
            {
                id: 'forestry',
                name: 'Forestry',
                value: 4.17754852162187,
                lowerConfidenceInterval: 4.115354867,
                upperConfidenceInterval: 4.238641717
            },
            {
                id: 'human-created-waterbodies',
                name: 'Human-created Water Bodies',
                value: 0.25297657005331296,
                lowerConfidenceInterval: 0.24624878,
                upperConfidenceInterval: 0.256591962
            },
            {
                id: 'transportation',
                name: 'Transportation',
                value: 1.4970597105612,
                lowerConfidenceInterval: 1.4807554149999999,
                upperConfidenceInterval: 1.509086287
            },
            {
                id: 'urban-industrial',
                name: 'Urban/Industrial',
                value: 1.05901649328099,
                lowerConfidenceInterval: 1.0330933019999999,
                upperConfidenceInterval: 1.086364219
            }
        ]
    },
    {
        year: 2016,
        values: [
            {
                id: 'agriculture',
                name: 'Agriculture',
                value: 0.208894176975536,
                lowerConfidenceInterval: 0.20861246600000002,
                upperConfidenceInterval: 0.2091848638
            },
            {
                id: 'energy',
                name: 'Energy',
                value: 1.81818047959915,
                lowerConfidenceInterval: 1.809911845,
                upperConfidenceInterval: 1.8251630459999998
            },
            {
                id: 'forestry',
                name: 'Forestry',
                value: 4.3257621696545005,
                lowerConfidenceInterval: 4.278123348,
                upperConfidenceInterval: 4.366860104
            },
            {
                id: 'human-created-waterbodies',
                name: 'Human-created Water Bodies',
                value: 0.25873994973499496,
                lowerConfidenceInterval: 0.252633773,
                upperConfidenceInterval: 0.261720733
            },
            {
                id: 'transportation',
                name: 'Transportation',
                value: 1.4991896189681799,
                lowerConfidenceInterval: 1.4836739000000002,
                upperConfidenceInterval: 1.509301705
            },
            {
                id: 'urban-industrial',
                name: 'Urban/Industrial',
                value: 1.04847657488611,
                lowerConfidenceInterval: 1.030010416,
                upperConfidenceInterval: 1.065836577
            }
        ]
    },
    {
        year: 2017,
        values: [
            {
                id: 'agriculture',
                name: 'Agriculture',
                value: 0.209847694896233,
                lowerConfidenceInterval: 0.20984769490000002,
                upperConfidenceInterval: 0.20984769490000002
            },
            {
                id: 'energy',
                name: 'Energy',
                value: 1.84219813545607,
                lowerConfidenceInterval: 1.842198135,
                upperConfidenceInterval: 1.842198135
            },
            {
                id: 'forestry',
                name: 'Forestry',
                value: 4.5135290204115004,
                lowerConfidenceInterval: 4.51352902,
                upperConfidenceInterval: 4.51352902
            },
            {
                id: 'human-created-waterbodies',
                name: 'Human-created Water Bodies',
                value: 0.262794576706689,
                lowerConfidenceInterval: 0.26279457699999995,
                upperConfidenceInterval: 0.26279457699999995
            },
            {
                id: 'transportation',
                name: 'Transportation',
                value: 1.51927114345897,
                lowerConfidenceInterval: 1.519271143,
                upperConfidenceInterval: 1.519271143
            },
            {
                id: 'urban-industrial',
                name: 'Urban/Industrial',
                value: 1.04192142192096,
                lowerConfidenceInterval: 1.0419214219999999,
                upperConfidenceInterval: 1.0419214219999999
            }
        ]
    }
];

const intactnessDataCSV = '';
const suitabilityMammals = [
    {
        id: 1,
        name: 'Lynx',
        guilds: [485],

        imageSrc: 'http://abmi.ca/FileDownloadServlet?dir=WEB_GRAPH&filename=/profiles/Lynx-canadensis-small.jpg',
        value: -0.6,
        intactness: 0.99
    },
    {
        id: 12,
        name: 'Coyote',
        guilds: [485],

        imageSrc: 'http://abmi.ca/FileDownloadServlet?dir=WEB_GRAPH&filename=/profiles/Canis-latrans-small.jpg',
        value: 3.2,
        intactness: 0.99
    },
    {
        id: 13,
        name: 'Fisher',
        guilds: [485],

        imageSrc: 'http://abmi.ca/FileDownloadServlet?dir=WEB_GRAPH&filename=/profiles/Pekania-pennanti-small.jpg',
        value: -3.0,
        intactness: 0.99
    },
    {
        id: 14,
        name: 'Wolf',
        guilds: [485],

        imageSrc: 'http://abmi.ca/FileDownloadServlet?dir=WEB_GRAPH&filename=/profiles/Canis-lupus-small.jpg',
        value: 0.5,
        intactness: 0.99
    },
    {
        id: 15,
        name: 'Marten',
        guilds: [570],

        imageSrc: 'http://abmi.ca/FileDownloadServlet?dir=WEB_GRAPH&filename=/profiles/Martes-americana-small.jpg',
        value: -4.7,
        intactness: 0.99
    },
    {
        id: 16,
        name: 'Moose',
        guilds: [485],

        imageSrc: 'http://abmi.ca/FileDownloadServlet?dir=WEB_GRAPH&filename=/profiles/Alces-alces-small.jpg',
        value: -1.6,
        intactness: 0.99
    },
    {
        id: 17,
        name: 'Mule deer',
        guilds: [485],

        imageSrc: 'http://abmi.ca/FileDownloadServlet?dir=WEB_GRAPH&filename=/profiles/Odocoileus-hemionus-small.jpg',
        value: -3.9,
        intactness: 0.99
    },
    {
        id: 18,
        name: 'Red fox',
        guilds: [485],

        imageSrc: 'http://abmi.ca/FileDownloadServlet?dir=WEB_GRAPH&filename=/profiles/Vulpes-vulpes-small.jpg',
        value: 0.094,
        intactness: 0.99
    },
    {
        id: 19,
        name: 'Snowshoe hare',
        guilds: [485],

        imageSrc: 'http://abmi.ca/FileDownloadServlet?dir=WEB_GRAPH&filename=/profiles/Lepus-americanus-small.jpg',
        value: -1.0,
        intactness: 0.99
    },
    {
        id: 20,
        name: 'White-tailed deer',
        guilds: [485],

        imageSrc: 'http://abmi.ca/FileDownloadServlet?dir=WEB_GRAPH&filename=/profiles/Odocoileus-virginianus-small.jpg',
        value: -4.5,
        intactness: 0.99
    },
    {
        id: 21,
        name: 'Wolverine',
        guilds: [570],

        imageSrc: 'http://abmi.ca/FileDownloadServlet?dir=WEB_GRAPH&filename=/profiles/Gulo-gulo-small.jpg',
        value: -5.0,
        intactness: 0.99
    },
];

async function getStubbedAreas(regionId: number): Promise<unknown []> {
    const stubbed = aoiList[regionId];
    return (stubbed) ? stubbed.aois : testRegionAreasDatum;
}

async function getStubbedQueriedAreas(query: string): Promise<unknown []> {
    const stubbed = aoiList[0].aois;
    return stubbed;
}

export const suitabilityData = {
    guildList: [
        {
            id: 486,
            name: 'SARA Schedule'
        }, {
            id: 570,
            name: 'AEP'
        }, {
            id: 485,
            name: 'General Status'
        }],
    mammals: suitabilityMammals,
    vplants: [
        {
            id: 676,
            name: 'Spreading Sweet Cicely',
            scientificName: 'Osmorhiza depauperata',
            imageSrc: '//abmi.ca/FileDownloadServlet?dir=SPP_IMAGE&filename=Fragaria-vesca/virginiana-small.jpg',
            value: -0.25769021969999995,
            intactness: 0.99,
            guilds: [485],
        },
        {
            id: 678,
            name: 'White Grained Mountain Rice Grass',
            scientificName: 'Oryzopsis asperifolia',
            imageSrc: '//abmi.ca/FileDownloadServlet?dir=SPP_IMAGE&filename=Fragaria-vesca/virginiana-small.jpg',
            value: -0.15369281670000007,
            intactness: 0.99,
            guilds: [485],
        },
        {
            id: 677,
            name: 'Purple Oat Grass',
            scientificName: 'Schizachne purpurascens',
            imageSrc: '//abmi.ca/FileDownloadServlet?dir=SPP_IMAGE&filename=Fragaria-vesca/virginiana-small.jpg',
            value: -0.14122172390000004,
            intactness: 0.99,
            guilds: [485],
        },
        {
            id: 680,
            name: 'Spotted Coralroot',
            scientificName: 'Corallorhiza maculata',
            imageSrc: '//abmi.ca/FileDownloadServlet?dir=SPP_IMAGE&filename=Fragaria-vesca/virginiana-small.jpg',
            value: -0.1292854414,
            intactness: 0.99,
            guilds: [485],
        },
        {
            id: 679,
            name: 'Pale Coralroot',
            scientificName: 'Corallorhiza trifida',
            imageSrc: '//abmi.ca/FileDownloadServlet?dir=SPP_IMAGE&filename=Fragaria-vesca/virginiana-small.jpg',
            value: -0.12867378149999995,
            intactness: 0.99,
            guilds: [485],
        },
        {
            id: 683,
            name: 'Blunt Leaved Bog Orchid',
            scientificName: 'Platanthera obtusata',
            imageSrc: '//abmi.ca/FileDownloadServlet?dir=SPP_IMAGE&filename=Fragaria-vesca/virginiana-small.jpg',
            value: -0.10791790629999994,
            intactness: 0.99,
            guilds: [485],
        },
        {
            id: 681,
            name: 'Virginia Grape Fern',
            scientificName: 'Botrychium virginianum',
            imageSrc: '//abmi.ca/FileDownloadServlet?dir=SPP_IMAGE&filename=Fragaria-vesca/virginiana-small.jpg',
            value: -0.10677028770000006,
            intactness: 0.99,
            guilds: [570],
        },
        {
            id: 684,
            name: 'Fairybells',
            scientificName: 'Prosartes trachycarpa',
            imageSrc: '//abmi.ca/FileDownloadServlet?dir=SPP_IMAGE&filename=Fragaria-vesca/virginiana-small.jpg',
            value: -0.10016844059999996,
            intactness: 0.99,
            guilds: [570],
        },
        {
            id: 685,
            name: 'Oak Fern',
            scientificName: 'Gymnocarpium dryopteris',
            imageSrc: '//abmi.ca/FileDownloadServlet?dir=SPP_IMAGE&filename=Fragaria-vesca/virginiana-small.jpg',
            value: -0.09382484750000003,
            intactness: 0.99,
            guilds: [570],
        },
        {
            id: 682,
            name: 'Cow Parsnip',
            scientificName: 'Heracleum sphondylium',
            imageSrc: '//abmi.ca/FileDownloadServlet?dir=SPP_IMAGE&filename=Fragaria-vesca/virginiana-small.jpg',
            value: -0.08995491079999994,
            intactness: 0.99,
            guilds: [570],
        },
        {
            id: 686,
            name: 'Water Smartweed',
            scientificName: 'Persicaria amphibia',
            imageSrc: '//abmi.ca/FileDownloadServlet?dir=SPP_IMAGE&filename=Fragaria-vesca/virginiana-small.jpg',
            value: -0.08897491439999997,
            intactness: 0.99,
            guilds: [570],
        },
        {
            id: 687,
            name: 'Western Wood Lily',
            scientificName: 'Lilium philadelphicum',
            imageSrc: '//abmi.ca/FileDownloadServlet?dir=SPP_IMAGE&filename=Fragaria-vesca/virginiana-small.jpg',
            value: -0.08515124740000005,
            intactness: 0.99,
            guilds: [570],
        },
        {
            id: 690,
            name: 'Ground Cedar',
            scientificName: 'Diphasiastrum complanatum',
            imageSrc: '//abmi.ca/FileDownloadServlet?dir=SPP_IMAGE&filename=Fragaria-vesca/virginiana-small.jpg',
            value: -0.08407915419999995,
            intactness: 0.99,
            guilds: [570],
        },
        {
            id: 689,
            name: 'Beaked Hazelnut',
            scientificName: 'Corylus cornuta',
            imageSrc: '//abmi.ca/FileDownloadServlet?dir=SPP_IMAGE&filename=Fragaria-vesca/virginiana-small.jpg',
            value: -0.08114665090000002,
            intactness: 0.99,
            guilds: [570],
        },
        {
            id: 688,
            name: 'Balsam Fir',
            scientificName: 'Abies balsamea',
            imageSrc: '//abmi.ca/FileDownloadServlet?dir=SPP_IMAGE&filename=Fragaria-vesca/virginiana-small.jpg',
            value: -0.07791217419999995,
            intactness: 0.99,
            guilds: [570],
        },
        {
            id: 691,
            name: 'High Bush Cranberry',
            scientificName: 'Viburnum opulus',
            imageSrc: '//abmi.ca/FileDownloadServlet?dir=SPP_IMAGE&filename=Fragaria-vesca/virginiana-small.jpg',
            value: -0.06590722810000002,
            intactness: 0.99,
            guilds: [570],
        },
        {
            id: 695,
            name: 'Spotted Touch Me Not',
            scientificName: 'Impatiens capensis/noli-tangere',
            imageSrc: '//abmi.ca/FileDownloadServlet?dir=SPP_IMAGE&filename=Fragaria-vesca/virginiana-small.jpg',
            value: -0.06129346830000003,
            intactness: 0.99,
            guilds: [570],
        },
        {
            id: 693,
            name: 'Red Osier Dogwood',
            scientificName: 'Cornus sericea',
            imageSrc: '//abmi.ca/FileDownloadServlet?dir=SPP_IMAGE&filename=Fragaria-vesca/virginiana-small.jpg',
            value: -5.552289270000003,
            intactness: 0.99,
            guilds: [570],
        },
        {
            id: 698,
            name: 'Common Pink Wintergreen',
            scientificName: 'Pyrola asarifolia',
            imageSrc: '//abmi.ca/FileDownloadServlet?dir=SPP_IMAGE&filename=Fragaria-vesca/virginiana-small.jpg',
            value: -4.282173389999997,
            intactness: 0.99,
            guilds: [570],
        },
        {
            id: 699,
            name: 'Canada Buffaloberry',
            scientificName: 'Shepherdia canadensis',
            imageSrc: '//abmi.ca/FileDownloadServlet?dir=SPP_IMAGE&filename=Fragaria-vesca/virginiana-small.jpg',
            value: -3.9491588000000064,
            intactness: 0.99,
            guilds: [570],
        },
        {
            id: 701,
            name: 'Kidney Leaved Violet',
            scientificName: 'Viola renifolia',
            imageSrc: '//abmi.ca/FileDownloadServlet?dir=SPP_IMAGE&filename=Fragaria-vesca/virginiana-small.jpg',
            value: -3.631926390000004,
            intactness: 0.99,
            guilds: [570],
        },
        {
            id: 702,
            name: 'Bitter Cress',
            scientificName: 'Cardamine pensylvanica',
            imageSrc: '//abmi.ca/FileDownloadServlet?dir=SPP_IMAGE&filename=Fragaria-vesca/virginiana-small.jpg',
            value: -3.592480649999999,
            intactness: 0.99,
            guilds: [570],
        },
        {
            id: 694,
            name: "Macoun's Buttercup",
            scientificName: 'Ranunculus macounii',
            imageSrc: '//abmi.ca/FileDownloadServlet?dir=SPP_IMAGE&filename=Fragaria-vesca/virginiana-small.jpg',
            value: -3.159086619999996,
            intactness: 0.99,
            guilds: [570],
        },
        {
            id: 700,
            name: "Dewey's Sedge",
            scientificName: 'Carex deweyana',
            imageSrc: '//abmi.ca/FileDownloadServlet?dir=SPP_IMAGE&filename=Fragaria-vesca/virginiana-small.jpg',
            value: -2.8628994599999943,
            intactness: 0.99,
            guilds: [570],
        },
        {
            id: 704,
            name: "Bishop's Cap",
            scientificName: 'Mitella nuda',
            imageSrc: '//abmi.ca/FileDownloadServlet?dir=SPP_IMAGE&filename=Fragaria-vesca/virginiana-small.jpg',
            value: -2.732210690000002,
            intactness: 0.99,
            guilds: [570],
        },
        {
            id: 692,
            name: 'Narrow Spinulose Shield Fern',
            scientificName: 'Dryopteris carthusiana',
            imageSrc: '//abmi.ca/FileDownloadServlet?dir=SPP_IMAGE&filename=Fragaria-vesca/virginiana-small.jpg',
            value: -2.194446389999996,
            intactness: 0.99,
            guilds: [570],
        },
        {
            id: 696,
            name: 'Western Canada Violet',
            scientificName: 'Viola canadensis',
            imageSrc: '//abmi.ca/FileDownloadServlet?dir=SPP_IMAGE&filename=Fragaria-vesca/virginiana-small.jpg',
            value: -0.77923199999999325,
            intactness: 0.99,
            guilds: [570],
        },
        {
            id: 706,
            name: 'Red and white baneberry',
            scientificName: 'Actaea rubra',
            imageSrc: '//abmi.ca/FileDownloadServlet?dir=SPP_IMAGE&filename=Fragaria-vesca/virginiana-small.jpg',
            value: -1.3584292999999975,
            intactness: 0.99,
            guilds: [570],
        },
        {
            id: 707,
            name: 'Sweet Scented Bedstraw',
            scientificName: 'Galium triflorum',
            imageSrc: '//abmi.ca/FileDownloadServlet?dir=SPP_IMAGE&filename=Fragaria-vesca/virginiana-small.jpg',
            value: -0.38298134999999434,
            intactness: 0.99,
            guilds: [570],
        },
        {
            id: 697,
            name: 'Common Nettle',
            scientificName: 'Urtica dioica',
            imageSrc: '//abmi.ca/FileDownloadServlet?dir=SPP_IMAGE&filename=Fragaria-vesca/virginiana-small.jpg',
            value: -0.5406917199999981,
            intactness: 0.99,
            guilds: [570],
        },
        {
            id: 705,
            name: 'Bunchberry',
            scientificName: 'Cornus canadensis',
            imageSrc: '//abmi.ca/FileDownloadServlet?dir=SPP_IMAGE&filename=Fragaria-vesca/virginiana-small.jpg',
            value: -0.7237109400000037,
            intactness: 0.99,
            guilds: [570],
        },
        {
            id: 703,
            name: 'Hairy Wild Rye',
            scientificName: 'Leymus innovatus',
            imageSrc: '//abmi.ca/FileDownloadServlet?dir=SPP_IMAGE&filename=Fragaria-vesca/virginiana-small.jpg',
            value: -0.4934317200000038,
            intactness: 0.99,
            guilds: [570],
        },
        {
            id: 708,
            name: 'Low Bush Cranberry',
            scientificName: 'Viburnum edule',
            imageSrc: '//abmi.ca/FileDownloadServlet?dir=SPP_IMAGE&filename=Fragaria-vesca/virginiana-small.jpg',
            value: -0.11909049999999865,
            intactness: 0.99,
            guilds: [570],
        }
    ]
};

export const footprintChangeData = [
    {
        endPct: 11.5,
        diffPct: 0.7,
        endArea: 4382268.51562785,
        endYear: 2018,
        diffArea: 266746.77921213,
        startPct: 10.8,
        startArea: 4115521.73641572,
        startYear: 1999,
        categoryId: 2,
        categoryName: 'Agriculture'
    },
    {
        endPct: 3.78,
        diffPct: 2.38,
        endArea: 1440432.607745502,
        endYear: 2018,
        diffArea: 906939.049321242,
        startPct: 1.4,
        startArea: 533493.55842426,
        startYear: 1999,
        categoryId: 4,
        categoryName: 'Forestry'
    },
    {
        endPct: 0.11,
        diffPct: 0.01,
        endArea: 41917.351019049,
        endYear: 2018,
        diffArea: 3810.668274459,
        startPct: 0.1,
        startArea: 38106.68274459,
        startYear: 1999,
        categoryId: 5,
        categoryName: 'Human-created Water Bodies'
    },
    {
        endPct: 1.8,
        diffPct: 0.5,
        endArea: 685920.28940262,
        endYear: 2018,
        diffArea: 190533.41372295,
        startPct: 1.3,
        startArea: 495386.87567967,
        startYear: 1999,
        categoryId: 3,
        categoryName: 'Energy'
    },
    {
        endPct: 0.94,
        diffPct: 0.14,
        endArea: 358202.817799146,
        endYear: 2018,
        diffArea: 53349.355842426,
        startPct: 0.8,
        startArea: 304853.46195672,
        startYear: 1999,
        categoryId: 6,
        categoryName: 'Transportation'
    },
    {
        endPct: 0.7,
        diffPct: 0.2,
        endArea: 266746.77921213,
        endYear: 2018,
        diffArea: 76213.36548918,
        startPct: 0.5,
        startArea: 190533.41372295,
        startYear: 1999,
        categoryId: 7,
        categoryName: 'Urban/Industrial'
    },
    {
        endPct: 18.84,
        diffPct: 3.94,
        endArea: 7179299.029080756,
        endYear: 2018,
        diffArea: 1501403.300136846,
        startPct: 14.9,
        startArea: 5677895.72894391,
        startYear: 1999,
        categoryId: 1,
        categoryName: 'Total Human Footprint'
    }
];

const speciesInfo = {
    name: 'Boreal Chickadee',
    imageText: 'call out block overlaps with image',
    imageSrc: 'https://images.unsplash.com/photo-1568162603664-fcd658421851?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=973&q=80',
    description: 'Lorem Ipsum Species Desc'
};

const wetlandStatus = [{}];
export const fetchIntactnessIndex = async (aoi: number):Promise<unknown[]> => ([{
    value: 0.99,
    year: 1999
}]);
export const fetchAreaOfAnalysis = async (aoi: number): Promise<unknown> => ({
    name: 'ANC Timber Ltd.',
    areaName: 'ANC Timber Ltd.',
    note: 'the actual fields still need to be confirmed.',
    areaHa: 373698.430420881,
    areaSqkm: 3736.98430420881,
    sampleYear: '2003 - 2019',
    totalSiteCount: 11,
    protectedAreaHa: null,
    protectedAreaPct: null,
    sampledSiteCount: 5,
    protectedAreaSqkm: null,
    regionType: '',
    regionTypeCode: ''
});

export const fetchFootprintChange = async (aoi: number): Promise<unknown> => footprintChangeData;
export const fetchAreasOfInterest = async (): Promise<unknown> => stubAreasOfInterestIO;
export const fetchTaxonomicGroups = async (): Promise<unknown> => stubTaxonomicGroupsIO;
export const fetchAreas = getStubbedAreas;
export const fetchQueriedAreas = getStubbedQueriedAreas;
export const fetchFootprintTrend = async (aoi: number): Promise<Array<unknown>> => footprintData;
export const fetchFootprintTrendCSV = async (aoi: number): Promise<string> => footprintDataCSV;
export const fetchSuitabilityData = async (aoi: number, category?: string): Promise<unknown> => suitabilityData;
export const fetchSpeciesInfo = async (aoi: number, category?: string): Promise<unknown> => ((aoi) ? speciesInfo : null);
export const fetchIntactnessCSV = async (aoi: number): Promise<string> => intactnessDataCSV;
export const fetchWetlandStatus = async (aoi: number): Promise<unknown[]> => wetlandStatus;

const stubbedData = {
    fetchSpeciesInfo,
    fetchAreaOfAnalysis,
    fetchAreasOfInterest,
    fetchTaxonomicGroups,
    fetchAreas,
    fetchQueriedAreas,
    fetchFootprintTrend,
    fetchFootprintTrendCSV,
    fetchFootprintChange,
    fetchSuitabilityData,
    fetchIntactnessIndex,
    fetchWetlandStatus,
    fetchIntactnessCSV,
};

export default stubbedData;
