
import { defineComponent } from 'vue';
import ReportSidebar from '@/modules/onlinereport/ReportSidebar.vue';

export default defineComponent({
    name: 'AppLayout',
    components: {
        ReportSidebar
    },
    setup() {
    }
});
