
import { computed, defineComponent, ref, watch, inject } from 'vue';
import useApp from '@/composables/state/useApp';
import _ from 'lodash';
import { Area } from '@/model/Area';

type Range = {
    key: string,
    range: string,
    areas: Partial<Area>[],
}

type Chunk = {
    letters: string[],
    areas: Partial<Area>[],
}

function getAreasByLetter(isSubregioned = false, regionAreas: Area[] = []) {
    if (isSubregioned) {
        return _(regionAreas)
            .sortBy((i) => i.subRegion?.initial || i.subRegion?.aoiName.toLowerCase())
            .groupBy((j) => j.subRegion?.initial || j.subRegion?.aoiName.toLowerCase())
            .value();
    }
    return _(regionAreas)
        .sortBy((a) => a.initial || a.aoiName[0].toLowerCase())
        .groupBy((b) => b.initial || b.aoiName[0].toLowerCase())
        .value();
}

export default defineComponent({
    name: 'AreaExplorer',
    setup() {
        const { state, region, area, selectAreaById, regionAreas, isAreaExplorerOpen, toggleAreaExplorer, toggleMenu } = useApp();

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const screens: any = inject('screens');

        const isSubregioned = computed(() => regionAreas.value?.some((i) => i.subRegion) || false);

        const ranges = computed((): Range[] => {
            const maxPerRange = 10;
            const minPerPage = 2;

            const areasByLetter = getAreasByLetter(isSubregioned.value, regionAreas.value);

            return _.reduce(areasByLetter, (c2: Chunk[], areas: Area[], letter: string) => {
                const newChunks = [...c2];
                let chunkIndex = newChunks.length ? newChunks.length - 1 : 0;
                if (
                    !newChunks[chunkIndex] || isSubregioned.value
                    || ((newChunks[chunkIndex].areas.length + areas.length) > maxPerRange && areas.length > minPerPage)
                    || (_.last(_.keys(areasByLetter)) === letter && areas.length > minPerPage)
                ) {
                    newChunks.push({
                        letters: [],
                        areas: []
                    });
                    chunkIndex = newChunks.length - 1;
                }

                const newAreas: Partial<Area>[] = _(areas).map((a: Area) => {
                    const newArea = { ...a };
                    const idx = newArea.aoiName.indexOf('-');
                    if (isSubregioned.value && idx > 0) {
                        newArea.aoiName = newArea.aoiName.substring(idx + 2).trim();
                    }

                    return newArea;
                }).sortBy((b) => {
                    if (isSubregioned.value && b.aoiName.toLowerCase() === letter) {
                        return -1;
                    }
                    return b.aoiName;
                })
                    .value();

                newChunks[chunkIndex].letters.push(letter);
                newChunks[chunkIndex].areas = newChunks[chunkIndex].areas.concat(newAreas);

                return newChunks;
            }, [])
                .map((chunk: Chunk) => ({
                    key: _.uniqueId(),
                    range: isSubregioned.value ? chunk.letters[0] : _.uniq([_.first(chunk.letters), _.last(chunk.letters)]).join('-'),
                    areas: chunk.areas,
                }));
        });

        // defer title change until after list is done loading
        const listTitle = ref('');
        watch(
            ranges,
            () => {
                listTitle.value = region.value?.name ?? '';
            }
        );

        const selectedRange = ref('');
        const setSelectedRange = (r:string) => {
            if (selectedRange.value !== r) {
                selectedRange.value = r;
            }
        };

        const initSelectedRange = () => {
            if (!ranges.value?.length) {
                return;
            }

            const foundRange = ranges.value.find((i) => i.areas.find((j) => j.aoiId === area.value?.aoiId));
            setSelectedRange(foundRange?.key || ranges.value[0].key);

            /* select only area when the region only has one area to avoid extra clicking */
            if (regionAreas.value?.length === 1) {
                const firstArea = regionAreas.value?.[0];
                selectAreaById(firstArea.aoiId, firstArea.aoiName);
            }
        };

        initSelectedRange();
        watch([region, area, ranges], initSelectedRange);

        const areaClicked = (id: number | undefined, name: string | undefined) => {
            if (!screens.lg) {
                toggleAreaExplorer(false);
                toggleMenu(false);
            }

            if (id !== undefined) {
                selectAreaById(id, name);
            }
        };

        const backClicked = () => {
            selectAreaById(0, '');
            toggleAreaExplorer(false);
            toggleMenu(true);
        };

        return {
            state,
            region,
            listTitle,
            isSubregioned,
            areas: regionAreas,
            area,
            ranges,
            selectedRange,
            setSelectedRange,
            isAreaExplorerOpen,
            areaClicked,
            backClicked
        };
    },
});
