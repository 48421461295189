import { computed, ComputedRef, reactive, Ref, watchEffect } from 'vue';
import { IntactnessContent } from '@/model/report';
import { Area } from '@/model/Area';
import IntactnessModel from '@/model/Intactness';
import { IntactnessDatum } from '@/api/intactness';
import useIntactnessLoader from '../loaders/useIntactnessLoader';

type IntactnessRefs = {
    explanation: ComputedRef<string>;
    title: ComputedRef<string>;
    intactnessIndex: ComputedRef<number | undefined>;
    model: IntactnessModel;
}

/**
 * Builds the Model object from the given content using data for the given area.
 * @param aoi The area selected to load data for
 * @param sectionIo The section report content
 * @returns A reactive IntactnessModel object for the vue template to use.
 */
export default function useIntactness(aoi: Ref<Area | undefined>, content: IntactnessContent):IntactnessRefs {
    const model = reactive<IntactnessModel>(new IntactnessModel(content));
    const data = useIntactnessLoader(aoi);
    const loadIntactnessModel = async (dataToLoad: (IntactnessDatum|undefined) []) => {
        if (dataToLoad && aoi.value) {
            const currentGroup = dataToLoad.find(x => x?.sppGroup === content.category);
            if (currentGroup != null) {
                model.load(currentGroup, aoi.value);
            }
        }
    };
    watchEffect(() => loadIntactnessModel(data.value));
    const explanation = computed(() => model.explanation);
    const title = computed(() => model.title);
    const intactnessIndex = computed(() => model.intactnessIndex);

    return {
        explanation,
        title,
        intactnessIndex,
        model
    };
}
