import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-05a22a30"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "cardWrap" }
const _hoisted_2 = { class: "cardBox" }
const _hoisted_3 = { class: "text" }
const _hoisted_4 = { class: "scientific" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "cardImage",
        style: _normalizeStyle({backgroundImage: `url('${_ctx.imageSrc}')`}),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openBBProfileUrl(_ctx.name ?? '')))
      }, null, 4),
      _createElementVNode("div", {
        class: _normalizeClass(["photo-credit", [_ctx.regionCss]])
      }, _toDisplayString(_ctx.photoCredit), 3),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h4", null, _toDisplayString(_ctx.cname), 1),
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.name), 1),
        _createElementVNode("div", {
          class: "highlight",
          innerHTML: _ctx.imageText
        }, null, 8, _hoisted_5),
        _createElementVNode("div", {
          class: "desc cms-text",
          innerHTML: _ctx.description
        }, null, 8, _hoisted_6)
      ])
    ])
  ]))
}