
import { computed, defineComponent, PropType } from 'vue';
import { WetlandMappedContent } from '@/model/report';
import useWetlandStatus from '@/composables/state/report/useWetlandStatus';
import useABMIMap from '@/composables/state/report/useABMIMap';
import { ABMIMap } from '@/vendor/abmi-map';
import { openMapPortal } from '@/util/util';
import useApp from '@/composables/state/useApp';
import _ from 'lodash';
import { WetlandCategoryById } from '@/api/wetland';
import SectionHeader from './SectionHeader.vue';

export default defineComponent({
    name: 'WetlandMappedSection',
    components: {
        ABMIMap,
        SectionHeader
    },
    props: {
        section: {
            type: Object as PropType<WetlandMappedContent>,
            required: true,
        }
    },
    setup(props) {
        const { aoi, isMobile } = useApp();
        const { mapData, mapInfo, mapExternalUrl } = useABMIMap(aoi, 'wetland');

        const wetlandStatus = computed(() => useWetlandStatus(aoi, props.section));

        const TOTAL_WETLAND_CATEGORY_ID = 6;
        const legend = computed(() => {
            if (!mapInfo.value?.mapLayers) {
                return [];
            }
            const legendDataList = [...wetlandStatus.value.wetlandDataList ?? []];
            const totalLayer = mapInfo.value?.mapLayers.find(x => x.categoryId === 6);
            legendDataList.unshift({ id: totalLayer?.categoryId ?? 6, name: WetlandCategoryById[totalLayer?.categoryId ?? 6], color: '#fff', value: 0 });
            // const legendList = mapInfo.value?.mapLayers.map(x => (x.categoryId && legendDataList[x.categoryId] ? legendDataList[x.categoryId] : { id: x.categoryId, title: x.customMapName }));

            return legendDataList;
        });

        const selectedCat = computed(() => {
            if (!mapInfo.value?.mapLayers) {
                return -1;
            }

            const showLayers = _.filter(mapInfo.value?.mapLayers, (layer) => layer.toShow);
            // console.log('layer.toShow', showLayers);
            if (showLayers.length === 1) {
                return showLayers[0].categoryId;
            }
            if (showLayers.length > 1) {
                return TOTAL_WETLAND_CATEGORY_ID;
            }
            return -1;
        });
        // 6 is total category, we don't display total wetland map, just show all sublayers
        const selectCategory = (catId: number) => {
            _.forEach(mapInfo.value?.mapLayers, (layer) => {
                // eslint-disable-next-line no-param-reassign
                layer.toShow = catId === TOTAL_WETLAND_CATEGORY_ID || layer.categoryId === catId;
            });
        };
        // Always sets the category back to total wetland on new load, id = 6
        selectCategory(TOTAL_WETLAND_CATEGORY_ID);

        const regionCss = computed(
            () => ((aoi.value && aoi.value.regionTypeCode) ? `bg-${aoi.value?.regionTypeCode}-main` : 'bg-default-main')
        );
        return {
            mapData,
            mapInfo,
            legend,
            selectedCat,
            selectCategory,
            openMapPortal,
            mapExternalUrl,
            regionCss,
            wetlandStatus,
            isMobile
        };
    }
});
