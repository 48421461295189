
import useApp from '@/composables/state/useApp';
import {
    defineComponent, PropType,
} from 'vue';
// <!-- :src="require(assetFolder + item.src)" -->
// import useApp from '@/composables/state/useApp';

export default defineComponent({
    name: 'AcknowledgementsSection',
    props: {
        sponsor: {
            type: Object as PropType<Record<string, unknown>>,
            default: null,
        }
    },
    setup() {
        const { aoi } = useApp();
        const assetFolder = '@/assets/partner-logos/';
        return { aoi, assetFolder };
    }
});
