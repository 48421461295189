
import _ from 'lodash';
import { computed, defineComponent, inject, PropType, ref } from 'vue';
import getTailwindColor from '@/getTailwindColor';
import useWetlandStatus from '@/composables/state/report/useWetlandStatus';
import WetlandStatusContent from '@/model/report/WetlandStatusContent';
import StackedBarChart from '@/components/StackedBarChart/StackedBarChart.vue';
import PieChart from '@/components/PieChart/PieChart.vue';
import useApp from '@/composables/state/useApp';
import SectionHeader from './SectionHeader.vue';

// reorder data items for inserting into grid in column order instead of row order
function reorder<T>(arr: T[], cols: number) {
    const rows = Math.ceil(arr.length / cols);
    const out = [];
    for (const i of _.range(0, arr.length)) {
        const idx = (cols * (i % (arr.length / cols))) + Math.floor(i / rows);
        out.push(arr[idx]);
    }
    return out;
}

export default defineComponent({
    name: 'WetlandStatusSection',
    components: {
        SectionHeader,
        StackedBarChart,
        PieChart
    },
    props: {
        section: {
            type: Object as PropType<WetlandStatusContent>,
            required: true,
        }
    },
    setup(props) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any

        const templateItems = ref(2); // 2 divs - titles and chart

        const { aoi } = useApp();
        const model = computed(() => useWetlandStatus(aoi, props.section)).value;
        // const model = useWetlandStatus(aoi, props.section);
        const wetlandDataList = computed(() => model.wetlandDataList);
        const wetlandTotal = computed(() => model.wetlandTotal);
        const stackFormat = computed(() => model.format);
        const pieChartData = computed(() => model.wetlandTotal && { color: '#158FAB', value: model.wetlandTotal });

        return {
            sectionContent: model,
            templateItems,

            aoi,

            wetlandDataList,
            wetlandTotal,
            pieChartData,
            // styles,
            stackFormat,

            // categories,
        };
    }
});
