import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-45cd740c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "num-areas" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.allRegions, (region, index) => {
    return (_openBlock(), _createElementBlock("div", {
      key: index,
      class: _normalizeClass(["region pl-4 pr-4 2xl:pl-10 2xl:pr-5", {selected: region === _ctx.selectedRegion}]),
      onClick: ($event: any) => (_ctx.regionClicked(region))
    }, [
      _createElementVNode("span", null, _toDisplayString(region.name), 1),
      _createElementVNode("span", _hoisted_2, _toDisplayString(region.aoiCount), 1)
    ], 10, _hoisted_1))
  }), 128))
}