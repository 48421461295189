import { ref, Ref, watchEffect } from 'vue';
import { Area } from '@/model/Area';
import { fetchIntactnessIndex } from '@/api';
import { IntactnessDatum } from '@/api/intactness';

type NewType = IntactnessDatum;

const loaders:{[aoiId:number]: Promise<NewType []> } = {};
const intactnessData = ref<(IntactnessDatum | undefined)[]>([]);
export default function useIntactnessLoader(aoi: Ref<Area | undefined>) {
    /* this variable can't be gloabal, otherwise, all intactness shared same data */

    const loadData = async (aoiToLoad:Area|undefined) => {
        if (aoiToLoad) {
            if (!loaders[aoiToLoad.id]) {
                loaders[aoiToLoad.id] = fetchIntactnessIndex(aoiToLoad.id);
                // const data = await loaders[aoiToLoad.id];
                // intactnessData.value = data;
            }
            /* nee dto call a different data, otherwise no changes when cached values found */
            const data = await loaders[aoiToLoad.id];
            intactnessData.value = data;
        }
    };
    watchEffect(() => loadData(aoi.value));
    return (intactnessData);
}
