import { computed, ComputedRef, reactive } from 'vue';
import { RegionsCategory } from '@/model/Area';
import useApp from '@/composables/state/useApp';

export type RegionCategoriesState = {
    selectedCategoryId: number | undefined;
}

export const regionCategoriesState: RegionCategoriesState = reactive({
    selectedCategoryId: 0,
});

type RegionCategoriesRefs = {
    categories: ComputedRef<RegionsCategory[] | undefined>;
    selectedCategory: ComputedRef<RegionsCategory | undefined>;
    setSelectedCategory: (selectedCategoryId: number | undefined) => void
}
export default function useRegionCategories():RegionCategoriesRefs {
    const { aois } = useApp();
    const state: RegionCategoriesState = regionCategoriesState;

    // Provides the list of Categories of Regions
    const categories = computed<RegionsCategory[] | undefined >(() => aois.value?.data);

    const selectedCategory = computed(() => categories.value?.find((c) => c.id === state.selectedCategoryId));

    const setSelectedCategory = (selectedCategoryId: number | undefined) => {
        state.selectedCategoryId = selectedCategoryId;
    };

    return {
        categories,
        selectedCategory,
        setSelectedCategory,
    };
}
