
import { computed, defineComponent, inject } from 'vue';
import useApp from '@/composables/state/useApp';
import SidePanel from '@/components/SidePanel.vue';
import TermsLinks from '@/modules/general/TermsLinks.vue';

export default defineComponent({
    name: 'AppLayout',
    components: {
        SidePanel,
        TermsLinks
    },
    setup() {
        const { area, isMenuOpen, isAreaExplorerOpen, toggleMenu, toggleAreaExplorer } = useApp();

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const screens: any = inject('screens');
        const lg = computed(() => screens.w >= 1024);
        const onHamburgerClicked = () => {
            if (isMenuOpen.value || isAreaExplorerOpen.value) {
                toggleMenu(false);
                toggleAreaExplorer(false);
            } else {
                toggleMenu(true);
                if (area.value) {
                    toggleAreaExplorer(true);
                }
            }
        };

        return {
            lg,
            isMenuOpen,
            isAreaExplorerOpen,
            onHamburgerClicked
        };
    }
});
