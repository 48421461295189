
import { computed, defineComponent, PropType } from 'vue';
import formatter, { FormatOptions } from '@dha/number-format';

export type TooltipRow = {
    label: string;
    value?: number;
    format?: FormatOptions;
    description?: string;
}

export default defineComponent({
    name: 'Tooltip',
    props: {
        title: {
            type: String,
            default: ''
        },
        rows: {
            type: Array as PropType<TooltipRow[]>,
            default: () => []
        }
    },
    setup(props) {
        const formattedRows = computed(
            () => props.rows.map(({ label, value, description, format }) => ({
                label,
                description,
                value: value != null && format ? formatter(value, format) : value
            }))
        );

        return { formattedRows };
    },
});
