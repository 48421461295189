
import { computed, defineComponent } from 'vue';
import useApp from '@/composables/state/useApp';

export default defineComponent({
    name: 'ChapterBottomPage',
    props: {
        link: {
            type: String,
            default: null,
        }
    },
    setup(props) {
        const { aoi } = useApp();
        const regionCss = computed(
            () => ((aoi.value && aoi.value.regionTypeCode) ? `text-${aoi.value?.regionTypeCode}-main` : 'text-default-main')
        );
        const moveToTop = () => {
            const topElement = document.querySelector(props.link ?? '#area');
            topElement?.scrollIntoView({
                behavior: 'auto',
                inline: 'center',
                block: 'start'
            });
        };

        window.onscroll = function () {
            const pageOffset = document.documentElement.scrollTop || document.body.scrollTop;
            const btn = document.getElementById('scrollToTop');
            if (btn) btn.style.visibility = pageOffset > 800 ? 'visible' : 'hidden';
        };

        return { moveToTop, regionCss };
    }

});
