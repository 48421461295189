import { createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "featherlight",
  style: {"display":"block"}
}
const _hoisted_2 = { class: "featherlight-content" }
const _hoisted_3 = { class: "flex relative justify-center text-2xl" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = {
  id: "info_popup",
  class: "section featherlight-inner"
}
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h2", {
            class: "mb-3",
            innerHTML: _ctx.content?.title
          }, null, 8, _hoisted_4),
          _createElementVNode("button", {
            class: "featherlight-close-icon featherlight-close",
            "aria-label": "Close",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.togglePopup()))
          }, " ✕ ")
        ]),
        _createElementVNode("section", _hoisted_5, [
          _createElementVNode("div", {
            class: "container has-text-centered term cms-text",
            innerHTML: _ctx.content?.description
          }, null, 8, _hoisted_6)
        ])
      ])
    ])
  ], 512)), [
    [_vShow, _ctx.showPopup]
  ])
}