
import { defineComponent } from 'vue';
import useApp from '@/composables/state/useApp';
import useReport, { useReportRouter } from '@/composables/state/report/useReport';
import ReportLayout from '@/layouts/ReportLayout.vue';
import ScrollReport from '@/modules/onlinereport/ScrollReport.vue';
import ReportMenu from '@/modules/onlinereport/ReportMenu.vue';
import PopUpBox from '@/components/PopUpBox.vue';

export default defineComponent({
    name: 'OnlineReport',
    components: {
        ReportLayout,
        ScrollReport,
        ReportMenu,
        PopUpBox
    },
    setup() {
        const { aoi, showPopup, togglePopup, popupContent } = useApp();
        const { report, state } = useReport(aoi);
        // const showPopup:Ref<boolean> = ref(false);
        // const togglePopup = () => {
        //     showPopup.value = !showPopup.value;
        // };
        useReportRouter();
        return { report, state, aoi, togglePopup, popupContent, showPopup, };
    }
});
