import { Report, Section, Chapter } from '@/model/report/Content';
import { contentKeys } from '@/services/content/index';
import type { SectionConfigsIO, ReportIO, ContentIO, ContentKeys } from '@/api/report';
import { config as apiConfig, getContent } from '@/api';

import type { Area } from '@/model/Area';
import { Taxonomy, taxonomies } from '@/api/intactness';
import _ from 'lodash';
import AreaOfAnalysisContent from './AreaOfAnalysisContent';
import SuitabilityContent from './SuitabilityContent';
import OverallSuitabilityContent from './OverallSuitabilityContent';
import FootprintStatusContent from './FootprintStatusContent';
import FootprintTrendsContent from './FootprintTrendsContent';
import FootprintMappedContent from './FootprintMappedContent';
import IntactnessContent from './IntactnessContent';
import IntactnessChapter from './IntactnessChapter';
import SpeciesCalloutContent from './SpeciesCalloutContent';
import WetlandStatusContent from './WetlandStatusContent';
import WetlandMappedContent from './WetlandMappedContent';

export {
    AreaOfAnalysisContent,
    SuitabilityContent,
    OverallSuitabilityContent,
    FootprintStatusContent,
    FootprintTrendsContent,
    FootprintMappedContent,
    IntactnessContent,
    SpeciesCalloutContent,
    Report, Section, Chapter,
    IntactnessChapter,
    WetlandMappedContent,
};
/* this is getting cms content and assign to each chatper */
function createContent(content: ReportIO, methodContents: ContentIO[], config: SectionConfigsIO, aoi: Area) {
    const taxonomiesContent:Record<Taxonomy, ContentIO> = _.pick(content, taxonomies);
    const isIntactnessAvailable = _.keyBy(aoi.sections, 'key').intactness?.isAvailable;

    let dataChapters: Chapter[] = [];
    if (isIntactnessAvailable) {
        const sppHighlightCount = _.keyBy(aoi.sppHighlightCount, 'taxonomy');
        dataChapters = _.map(taxonomies, (taxonomy:Taxonomy) => {
            const chapter = new IntactnessChapter(content[taxonomy], config[taxonomy], taxonomy);
            const intactnessKey:ContentKeys = `${taxonomy}Intactness`;
            const suitabilityKey:ContentKeys = `${taxonomy}Suitability`;
            const speciesKey:ContentKeys = `${taxonomy}Species`;
            chapter.sections = [
                new IntactnessContent(content[intactnessKey], config[intactnessKey], taxonomy),
                new SuitabilityContent(content[suitabilityKey], config[suitabilityKey], taxonomy),
            ];
            if ((sppHighlightCount[taxonomy]?.count ?? 0) > 0) {
                chapter.sections.push(new SpeciesCalloutContent(content[speciesKey], config[speciesKey], taxonomy));
            }
            return chapter;
        });
        const overallBioChapter = new IntactnessChapter(content.introductionIntactness, config.introductionIntactness, 'biodiversity');
        overallBioChapter.sections = [
            new IntactnessContent(content.overallIntactness, config.overallIntactness, 'biodiversity'),
            new IntactnessContent(content.overallIntactnessMapped, config.overallIntactnessMapped, 'biodiversity'),
            new OverallSuitabilityContent(content.overallSuitability, config.overallSuitability, taxonomiesContent)
        ];
        dataChapters.unshift(overallBioChapter);
    }

    const areaOfAnalysis = new AreaOfAnalysisContent(content.area, config.area);
    /* all with wetland chapter */
    const wetlandChapter = new Chapter(content.wetland, config.wetland);
    wetlandChapter.sections = [
        new WetlandStatusContent(content.wetlandStatus, config.wetlandStatus)];
    wetlandChapter.sections.push(
        new WetlandMappedContent(content.wetlandMapped, config.wetlandMapped)
    );

    dataChapters.unshift(wetlandChapter);

    /* some with with hf chapter */
    const isFootprintAvailable = _.keyBy(aoi.sections, 'key').footprint?.isAvailable;

    if (isFootprintAvailable) {
        const footprintChapter = new Chapter(content.footprint, config.footprint);
        footprintChapter.sections = [
            new FootprintStatusContent(content.footprintStatus, config.footprintTrends)];
        footprintChapter.sections.push(
            new FootprintMappedContent(content.footprintMapped, config.footprintMapped)
        );
        if (aoi.hasHfTrend) {
            footprintChapter.sections.push(
                new FootprintTrendsContent(content.footprintTrends, config.footprintTrends, content.footprintTrends)
            );
        }
        dataChapters.unshift(footprintChapter);
    }
    /* make method chapter */
    const methodChapter = new Chapter(content.methods, config.methods);
    methodChapter.sections = methodContents.map((contentRow, index) => new Section({ ...contentRow, bottomDivider: (index < methodContents.length - 1) }, config.methods));

    return [
        // new Section(content.area, config.area),
        areaOfAnalysis,
        ...dataChapters,
        methodChapter,
        // new Section(content.methods, config.methods),
        new Chapter(content.reference, config.reference),
        new Chapter(content.citation, config.citation),
    ];
}

/* use this to cache region type cms, otherwise will take 5 seconds to load */
const contentLoaders:{[regionType:string]: any} = {};
/**
 * @param aoi The area to get the report for.
 * @returns A Promise for the Report object with content filled in for the given AOI
 */

export async function createReport(aoi: Area, mockContent?: ReportIO, mockCfg?: SectionConfigsIO): Promise<Report> {
    const regionType = aoi.regionTypeCode || 'default';
    let _config;
    let
        _content:ReportIO;

    let _methodContent:ContentIO[] = [];
    if (contentLoaders && contentLoaders[regionType]) {
        _content = contentLoaders[regionType].content;
        _config = contentLoaders[regionType].config;
        _methodContent = contentLoaders[regionType].methodContent;
    } else {
        _config = mockCfg || await apiConfig;

        _content = mockContent || await getContent(aoi.regionTypeCode || 'default');
        /* debug to check content source */
        // Object.keys(contentKeys).forEach((item) => {
        //     const key:ContentKeys = item as ContentKeys;
        //     // _content[key].title += ` - test ${regionType}`;
        // });
        // from data create content with same prefix, [ 'methods' ]
        _methodContent = Object.keys(_content).filter(key => key.startsWith('subMethod')).map(key => _content[key] as ContentIO);

        contentLoaders[regionType] = { config: _config, content: _content, methodContent: _methodContent };
    }

    const region = aoi.region;
    const regionSuffix = region ? `in ${region.name}` : '';
    const report = new Report(_content.report.title || 'ABMI', aoi.aoiName, regionSuffix, aoi.regionType);
    report.sections = createContent(_content, _methodContent, _config, aoi);

    return report;
}
