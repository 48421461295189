import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeStyle as _normalizeStyle } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_row_flag_name_icon = _resolveComponent("row-flag-name-icon")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "rows",
    class: "rows",
    style: _normalizeStyle({
            'grid-template-columns': `20px min-content ${_ctx.rowHeight}px ${_ctx.rowHeight}px`,
            'grid-auto-rows': `${_ctx.rowHeight}px`,
            'grid-gap': `${_ctx.rowGap}px`
        })
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredData, (d) => {
      return (_openBlock(), _createBlock(_component_row_flag_name_icon, {
        key: d.name,
        datum: d,
        flags: _ctx.flags,
        format: _ctx.format
      }, null, 8, ["datum", "flags", "format"]))
    }), 128)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.allData.filter(() => !_ctx.chartExpanded), (d) => {
      return (_openBlock(), _createBlock(_component_row_flag_name_icon, {
        key: `sizing-${d.name}-${d.subtitle}`,
        datum: d,
        hide: true,
        format: _ctx.format
      }, null, 8, ["datum", "format"]))
    }), 128))
  ], 4))
}