/**
 * Data Model for Suitability
 */
import { NumberValue } from 'd3-scale';
import format from '@dha/number-format';
import { IntactnessDatum } from '@/api/intactness';
import { Area } from './Area';
import IntactnessContent from './report/IntactnessContent';
import { TemplateProps } from './helpers';
import SectionContentModel from './SectionContent';

/**
  * Wraps Content and Data and provides interface used by UI
  */
export default class IntactnessModel extends SectionContentModel {
    data?: IntactnessDatum;
    content: IntactnessContent;
    aoi?: Area;
    constructor(content: IntactnessContent) {
        super(content);
        this.content = content;
    }
    get explanation(): string {
        return this.content.config.explanation;
    }
    get intactnessIndex(): number | undefined {
        return this.data?.intactnessIndex;
    }
    get currentYear(): number | undefined {
        return this.data?.year;
    }
    get format(): (n: NumberValue) => string {
        return n => (format(n.valueOf(), this.content.config.format));
    }
    get templateObj(): Record<string, string | number | undefined> {
        return {
            year: this.data?.year,
            sppCount: this.data?.sppCount,
            sppGroup: this.data?.sppGroup,
            sppGroupId: this.data?.sppGroupId,
            intactnessYear: this.data?.intactnessYear,
            intactnessIndex: this.data?.intactnessIndex,
        };
    // sppCount: t.number,
    // sppGroup: t.string,
    // sppGroupId: t.number,
    // intactnessYear: t.number,
    // intactnessIndex: t.number, this.data.intact
        // }
    }
    async load(data: IntactnessDatum, aoi: Area): Promise<void> {
        this.data = data;
        this.aoi = aoi;
    }
    get templateProps(): TemplateProps {
        return {
            ...this.templateObj,
            areaName: this.aoi?.name || null
        };
    }
}
