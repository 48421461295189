import { ContentIO, FootprintConfigIO } from '@/api/report';

import { Section } from './Content';

export default class FootprintMappedContent extends Section {
    config: FootprintConfigIO;
    constructor(contentIo: ContentIO, config: FootprintConfigIO) {
        super(contentIo, config);
        this.config = config;
    }
}
