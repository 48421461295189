import { IntactnessCategory, Taxonomy } from '@/api/intactness';

import { ContentIO, SuitabilityConfigIO, LegendItemIO } from '@/api/report';

import { Section } from './Content';

export default class SuitabilityContent extends Section {
    taxonomy:Taxonomy;
    category:IntactnessCategory;
    config:SuitabilityConfigIO;
    constructor(contentIo:ContentIO, config:SuitabilityConfigIO, taxonomy: Taxonomy) {
        super(contentIo, config);
        this.taxonomy = taxonomy;
        this.config = config;
        this.category = taxonomy;
    }
    get legend():LegendItemIO[] {
        return this.config.legend;
    }
}
