import axios from 'axios';
import _ from 'lodash';
import { SpeciesInfoIO } from '@/api/intactness';

if (!process.env.VUE_APP_ABMI_SPP_CMS_URL) {
    throw new Error('ABMI CMS Env not set: VUE_APP_ABMI_SPP_CMS_URL');
}

const abmiCmsUrl = process.env.VUE_APP_ABMI_SPP_CMS_URL;

const abmiCmsAxios = axios.create();

export async function fetchSpeciesContent(scientificName: string, lang?: string | null): Promise<SpeciesInfoIO | null> {
    try {
        const sppNameContentUrl = `${abmiCmsUrl}${scientificName}&lang=${lang}`;
        const response = await abmiCmsAxios.get(sppNameContentUrl);
        const data = response.data;
        if (!data) {
            console.info(`${scientificName} not found in CMS`);
            return null;
        }
        const { HighLight1: title, HighLight2Header: imageText, HighLight2Text: description, scientificName: name, commonName: cname, photoCredit } = data;
        return {
            ...{ title, imageText, description, name, cname, photoCredit },
            imageSrc: `${scientificName}`
        };
    } catch (error) {
        console.warn(error);
    }
    return null;
}
