import _ from 'lodash';
import { ScaleTime } from 'd3-scale';
import { LineChartDatum } from './types';

export function getHoverPoints(
    data: LineChartDatum[][],
    left: number,
    xScale: ScaleTime<number, number>
): LineChartDatum[] | undefined {
    const xTime = xScale.invert(left).getTime();
    const dates = data.map(d => d[0].date.getTime());
    const lastYearBeforeIdx = _.findLastIndex(dates, time => time <= xTime);

    if (lastYearBeforeIdx < 0) {
        return undefined;
    }
    if (lastYearBeforeIdx === dates.length - 1) {
        return data[lastYearBeforeIdx];
    }

    const firstDiff = xTime - dates[lastYearBeforeIdx];
    const secondDiff = dates[lastYearBeforeIdx + 1] - xTime;
    if (firstDiff < secondDiff) {
        return data[lastYearBeforeIdx];
    }
    return data[lastYearBeforeIdx + 1];
}
