
import { defineComponent, PropType, ref, watch, nextTick, onMounted } from 'vue';
import { useRefInView } from '@/composables/util/refScroll';
import type { Section } from '@/model/report';
import useReport from '@/composables/state/report/useReport';
import useApp from '@/composables/state/useApp';
import reportModuleSelect from './reportModuleSelect';

const domLoaded = ref(false);
window.addEventListener('load', () => {
    domLoaded.value = true;
});

export default defineComponent({
    name: 'ScrollableSection',
    props: {
        section: {
            type: Object as PropType<Section>,
            default: null,
        }
    },
    setup(props) {
        const { aoi } = useApp();
        const { section, selectSection } = useReport(aoi);
        const scrollEl = ref<HTMLDivElement>();

        const refInView = useRefInView(scrollEl, () => {
            if (props.section !== section.value) {
                selectSection(props.section.id);
            }
        });

        const scrollWhenLoaded = async (smooth: boolean) => {
            if (domLoaded.value === true && props.section === section.value) {
                await nextTick();
                refInView.scrollTo(smooth);
            }
        };
        watch(
            [domLoaded, section],
            async (newLoaded, oldLoaded) => {
                scrollWhenLoaded(newLoaded[1] === oldLoaded[1]);
            }
        );
        onMounted(() => scrollWhenLoaded(false));

        return { moduleForSection: reportModuleSelect, scrollEl };
    },
});
