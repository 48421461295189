
import { defineComponent, PropType } from 'vue';
import type { Section } from '@/model/report';
import SectionHeader from './SectionHeader.vue';

export default defineComponent({
    name: 'BaseSection',
    components: {
        SectionHeader
    },
    props: {
        section: {
            type: Object as PropType<Section>,
            default: null,
        },
        isChapterTitle: {
            type: Boolean,
            default: false,
        }
    },
});
