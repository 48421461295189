
import { computed, defineComponent, PropType } from 'vue';
import { FootprintTrendsContent } from '@/model/report';
import useABMIMap from '@/composables/state/report/useABMIMap';
import { ABMIMap } from '@/vendor/abmi-map';
import { openMapPortal } from '@/util/util';
import useApp from '@/composables/state/useApp';
import _ from 'lodash';
import useFootprintTrends from '@/composables/state/report/useFootprintTrends';
import SectionHeader from './SectionHeader.vue';

export default defineComponent({
    name: 'FootprintMappedSection',
    components: {
        ABMIMap,
        SectionHeader
    },
    props: {
        section: {
            type: Object as PropType<FootprintTrendsContent>,
            required: true,
        }
    },
    setup(props) {
        const { aoi, isMobile } = useApp();
        const { mapData, mapInfo, mapExternalUrl } = useABMIMap(aoi, 'human-footprint');
        // const footprintStatus = computed(() => useFootprintStatus(aoi, props.section));
        const footprintStatus = computed(() => useFootprintTrends(aoi, props.section).model);

        /* full list of legend defined by js constant, however the labels maybe chagned, so update labels from data */
        const legend = computed(() => {
            const footprintCategories = props.section.config.categories;
            const footprintData = _.keyBy(footprintStatus.value.latestData, 'key');
            // eslint-disable-next-line no-console
            const mapCategories = _(footprintCategories)
                .pickBy((c) => c?.isMainCategory || c?.isTotalCategory)
                .values()
                .map((c) => ({
                    ...c,
                    name: footprintData[c?.key || '']?.title ?? c?.name,
                    pct: footprintData[c?.key || '']?.value
                }))
                .value();

            const sortedCategories = _.orderBy(mapCategories, (c) => (c?.id !== 1 ? c?.pct ?? 0 : 100), 'desc'); // total map id = 1 shows first
            return sortedCategories;
            // return [];
        });

        const selectedCat = computed(() => {
            if (!mapInfo.value?.mapLayers) {
                return -1;
            }
            const showLayers = _.filter(mapInfo.value?.mapLayers, (layer) => layer.toShow);
            if (showLayers.length === 1) {
                return showLayers[0].categoryId;
            } if (showLayers.length === mapInfo.value?.mapLayers.length) {
                return 1;
            }
            return -1;
        });
        const selectCategory = (catId: number) => {
            _.forEach(mapInfo.value?.mapLayers, (layer) => {
                // eslint-disable-next-line no-param-reassign
                layer.toShow = catId === 1 || layer.categoryId === catId;
            });
        };
        // Always sets the category back to total footprint on new load
        selectCategory(1);

        const regionCss = computed(
            () => ((aoi.value && aoi.value.regionTypeCode) ? `bg-${aoi.value?.regionTypeCode}-main` : 'bg-default-main')
        );
        return {
            mapData,
            mapInfo,
            legend,
            selectedCat,
            selectCategory,
            openMapPortal,
            regionCss,
            footprintStatus,
            isMobile,
            mapExternalUrl
        };
    }
});
