import { onBeforeUnmount, onMounted, Ref, shallowRef, triggerRef, watch } from 'vue';
import ResizeObserver from 'resize-observer-polyfill';

type ResizeParams = {
    dimensions: Ref<{
        width: number,
        height: number
    }>;
    resizeObserver: ResizeObserver | null;
}

export default function setupResizeObserver(container: Ref<Element | undefined>): ResizeParams {
    const dimensions = shallowRef({
        width: 0,
        height: 0
    });

    let resizeObserver: ResizeObserver | null = null;
    onMounted(() => {
        resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
            const { contentRect } = entries[0];
            let shouldUpdate = false;
            if (contentRect.width !== dimensions.value.width) {
                dimensions.value.width = contentRect.width;
                shouldUpdate = true;
            }
            if (contentRect.height !== dimensions.value.height) {
                dimensions.value.height = contentRect.height;
                shouldUpdate = true;
            }
            if (shouldUpdate) triggerRef(dimensions);
        });

        if (container.value) {
            resizeObserver.observe(container.value);
        }
    });
    watch(() => container.value, (newEl, oldEl) => {
        if (oldEl) {
            resizeObserver?.unobserve(oldEl);
        }
        if (newEl) {
            resizeObserver?.observe(newEl);
        }
    });
    onBeforeUnmount(() => resizeObserver?.disconnect());

    return {
        dimensions,
        resizeObserver
    };
}
