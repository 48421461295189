
import { defineComponent, inject, computed } from 'vue';
import { enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import useApp from '@/composables/state/useApp';
import useReport from '@/composables/state/report/useReport';

export default defineComponent({
    name: 'ReportSidebar',
    setup() {
        const { aoi, isMenuOpen, toggleMenu, appInfo } = useApp();
        const { report } = useReport(aoi);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const screens: any = inject('screens');
        const lg = computed(() => screens.w >= 1024);
        const toggleMenuClick = () => {
            if (screens.w < 1024) {
                const scrollableMenu = document.querySelector('.menu.scrolling-touch');
                if (scrollableMenu) {
                    if (isMenuOpen.value) {
                        enableBodyScroll(scrollableMenu);
                    }
                }
            }

            toggleMenu(undefined);
        };

        return {
            report,
            lg,
            isMenuOpen,
            toggleMenuClick,
            appInfo
        };
    },
    beforeUnmount() {
        const { aoi } = useApp();
        const { selectSection } = useReport(aoi);

        // remove section selection when report is closed
        clearAllBodyScrollLocks();
        selectSection('');
    },
});
