import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2c801850"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "overall-suitability-section" }
const _hoisted_2 = { class: "overflow-x-auto w-full" }
const _hoisted_3 = { class: "content-container downloadbutton-container" }
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionHeader = _resolveComponent("SectionHeader")!
  const _component_tooltip = _resolveComponent("tooltip")!
  const _component_beeswarm = _resolveComponent("beeswarm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SectionHeader, {
      class: "content-container",
      section: _ctx.suitabilityContent
    }, null, 8, ["section"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_beeswarm, {
        class: "content-container py-chart-margin w-data-plot",
        data: _ctx.beeswarmData,
        "negative-text": '⟵ decrease in suitability',
        "positive-text": 'increase in suitability ⟶',
        "click-label-function": _ctx.clickLabelFunction,
        "x-margin": 20,
        "custom-tooltip-func": _ctx.customTooltipFunc,
        format: _ctx.indiviudalIntactnessFormat,
        onAction: _ctx.onSearch
      }, {
        tooltip: _withCtx((tooltipProps) => [
          _createVNode(_component_tooltip, _normalizeProps(_guardReactiveProps(tooltipProps)), null, 16)
        ]),
        _: 1
      }, 8, ["data", "click-label-function", "custom-tooltip-func", "format", "onAction"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.downloadCSVData && _ctx.downloadCSVData(...args)))
      }, [
        _createElementVNode("img", {
          src: require(`@/assets/icons/download-csv.svg`)
        }, null, 8, _hoisted_4),
        _createTextVNode(" Download Data ")
      ])
    ])
  ]))
}