import { ContentIO, WetlandConfigIO } from '@/api/report';

import { Section } from './Content';

export default class WetlandStatusContent extends Section {
    config: WetlandConfigIO;
    // trendsTitle: string;
    constructor(contentIo: ContentIO, config: WetlandConfigIO, trendsContentIo?: ContentIO) {
        super(contentIo, config);
        this.config = config;
        // this.title = trendsContentIo?.title || trendsContentIo?.id || '-';
    }
}
