import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-07a2d183"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "intactness-section content-container" }
const _hoisted_2 = { class: "flex flex-col lg:w-2/5 mt-8 lg:mt-0" }
const _hoisted_3 = { class: "flex flex-row items-center" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "mx-auto" }
const _hoisted_6 = {
  key: 0,
  class: "intact-explanation"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionHeader = _resolveComponent("SectionHeader")!
  const _component_thermometer_chart = _resolveComponent("thermometer-chart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SectionHeader, {
      section: _ctx.model,
      "show-alternative-description": _ctx.intactnessIndex > 0.75,
      "show-description": !(_ctx.intactnessIndex > 0.75),
      class: "lg:w-3/5 lg:pr-5"
    }, null, 8, ["section", "show-alternative-description", "show-description"]),
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          class: "object-contain lg:ml-4",
          src: require(`@/assets/img/thermometer-chart/thermometer_${_ctx.section.category}.svg`)
        }, null, 8, _hoisted_4),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_thermometer_chart, {
            "current-value": _ctx.intactnessIndex * 100
          }, null, 8, ["current-value"])
        ])
      ]),
      false
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.explanation), 1))
        : _createCommentVNode("", true)
    ], 512), [
      [_vShow, _ctx.intactnessIndex !=null]
    ])
  ]))
}