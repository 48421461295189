
import { defineComponent, PropType, computed } from 'vue';
import type { SpeciesCalloutContent } from '@/model/report';
import useApp from '@/composables/state/useApp';
import useSpeciesCallout from '@/composables/state/report/useSpeciesCallout';
import SectionHeader from './SectionHeader.vue';
import SpeciesCalloutSlider from './SpeciesCalloutSlider.vue';

export default defineComponent({
    name: 'SpeciesCalloutSection',
    components: {
        SectionHeader,
        SpeciesCalloutSlider
    },
    props: {
        section: {
            type: Object as PropType<SpeciesCalloutContent>,
            default: null,
        }
    },
    setup(props) {
        const { aoi } = useApp();
        // const speciesCallout = ref();
        // const intactness = ref();
        /* no need computed ? */
        // // const speciesCallout = computed(() => {
        // //     const { speciesCallout: _speciesCallout } = useSpeciesCallout(aoi, props.section);
        // //     return _speciesCallout;
        // // });
        // // const speciesCallout = computed(() => {

        // const loadSpp = () => {
        //     const { speciesCallout: s, intactness: i } = useSpeciesCallout(aoi, props.section);
        //     speciesCallout.value = s;
        //     intactness.value = i;
        //     console.log('speciesCallout=', speciesCallout, intactness);
        // };
        // loadSpp();
        // watchEffect(() => loadSpp());

        const speciesCallout = computed(() => { const { speciesCallout: spp } = useSpeciesCallout(aoi, props.section); return spp; });

        return { speciesCallout };
    }
});
