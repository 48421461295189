import { Section } from './report';
import { templateMarkdown, templateText, TemplateProps } from './helpers';

/**
 * Provides a base class for templating props from a Section Content.
 */
export default abstract class SectionContentModel {
    content: Section;
    constructor(content: Section) {
        this.content = content;
    }
    get title():string {
        return templateText(this.content.title, this.templateProps, this.content.config.format);
    }
    get description():string | undefined {
        return this.content.description && templateMarkdown(this.content.description, this.templateProps, this.content.config.format);
    }
    get descriptionAlternative():string | undefined {
        return this.content.descriptionAlternative && templateMarkdown(this.content.descriptionAlternative, this.templateProps, this.content.config.format);
    }
    get imageSrc():string | undefined {
        return this.content.imageSrc;
    }
    get imageCredit():string | undefined {
        return this.content.imageCredit;
    }
    abstract get templateProps(): TemplateProps;
}
