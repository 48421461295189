
import { defineComponent, PropType } from 'vue';
import format, { FormatOptions } from '@dha/number-format';
import { FlagIcon, RowFlagIconDatum } from './types';

export default defineComponent({
    name: 'RowFlagNameIcon',
    props: {
        datum: {
            type: Object as PropType<RowFlagIconDatum>,
            required: true
        },
        hide: {
            type: Boolean,
            default: false
        },
        flags: {
            type: Array as PropType<FlagIcon[]>,
            default: () => []
        },
        format: {
            type: Object as PropType<FormatOptions>,
            default: null
        },
    },
    setup(props) {
        const formattedDatumValue = format(1 - Math.abs(props.datum.value), props.format);
        return {
            formattedDatumValue,
            flag: props.flags.find(f => props.datum.flags[f.id])
        };
    }
});
