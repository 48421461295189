
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import { defineComponent, PropType, computed } from 'vue';
import type { IntactnessContent } from '@/model/report';
import ThermometerChart from '@/components/ThermometerChart/ThermometerChart.vue';
import useApp from '@/composables/state/useApp';
import useIntactness from '@/composables/state/report/useIntactness';
import SectionHeader from './SectionHeader.vue';

export default defineComponent({
    name: 'IntactnessSection',
    components: {
        SectionHeader,
        ThermometerChart
    },
    props: {
        section: {
            type: Object as PropType<IntactnessContent>,
            default: null,
        }
    },
    setup(props) {
        const { aoi } = useApp();

        const model = computed(() => useIntactness(aoi, props.section).model);
        const explanation = computed(() => model.value.explanation);
        const title = computed(() => model.value.title);
        const intactnessIndex = computed(() => model.value.intactnessIndex ?? 0);

        return {
            model,
            explanation,
            title,
            intactnessIndex
        };
    }
});
