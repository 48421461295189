import _ from 'lodash';
import { fetchIntactnessCSV, fetchSuitabilityData } from '@/api';
import format, { FormatOptions } from '@dha/number-format';
import { SuitabilityDatum, Taxonomy, taxonomies, TaxonomicGroupIO } from '@/api/intactness';
import OverallSuitabilityContent from '@/model/report/OverallSuitabilityContent';
import { Area } from './Area';
import { getSpeciesNames, TemplateProps } from './helpers';
import SectionContentModel from './SectionContent';

export type GroupWithTitles = {
    id: string;
    title: string;
    subtitle: string;
    color: string;
    data: {
        name: string;
        value: number;
    }[];
}

// TODO: get this from config strings
const SUBTITLE_START = 'Average intactness';

function average(
    data: { name: string; value: number; }[],
    formatOptions: FormatOptions | undefined
) {
    const avg = _.sumBy(data, 'intactness') / data.length;
    return format(avg, formatOptions);
}

export type OverallDataType = (GroupWithTitles | undefined)[] | undefined;

export default class OverallSuitabilityModel extends SectionContentModel {
    data?: Record<Taxonomy, SuitabilityDatum[] | undefined>;
    aoi?: Area;
    taxonomicGroups?: Record<string, TaxonomicGroupIO>;
    // format?: FormatOptions;
    content: OverallSuitabilityContent;
    areaName?: string;
    constructor(content: OverallSuitabilityContent, taxonomicGroups: Record<string, TaxonomicGroupIO> | undefined) {
        super(content);
        this.content = content;
        this.taxonomicGroups = taxonomicGroups;
    }
    async load(aoi: Area):Promise<void> {
        (async () => {
            const suitabilityData = await fetchSuitabilityData(aoi.id);
            this.data = suitabilityData.data;
        })();
        this.areaName = aoi.name;
        this.aoi = aoi;
    }
    get templateProps(): TemplateProps {
        return {
            areaName: this.areaName ?? null,
        };
    }
    get colors():Record<Taxonomy, string> {
        return this.content.config.colors;
    }
    get format(): FormatOptions | undefined {
        return this.content.config.format;
    }
    get taxonomyNames():Record<Taxonomy, string> {
        return _.mapValues(this.content.taxonomies, (c) => c.name || c.title || c.id);
    }
    get overallData(): OverallDataType {
        if (!this.data) {
            return undefined;
        }
        const allData = _(this.data)
            .pick(taxonomies)
            .entries();
        return allData.map(([k, values]) => {
            const dataKey = k as Taxonomy;
            const taxonomicGroup = this.taxonomicGroups?.[dataKey];
            const valuesWithSpeciesTitle = values?.map(v => {
                const names = getSpeciesNames(v, taxonomicGroup);
                return {
                    ...v,
                    name: names.name ?? names.subtitle ?? '',
                };
            }) ?? [];
            return {
                id: dataKey,
                title: this.taxonomyNames[dataKey],
                subtitle: (values?.length) ? `${SUBTITLE_START}: ${average(valuesWithSpeciesTitle, this.format)}` : '--',
                color: this.colors[dataKey],
                data: (values?.length)
                    ? valuesWithSpeciesTitle
                    : []
            };
        })
            .filter(d => d.data.length > 0)
            .value();
    }
    async downloadCSV(): Promise<string> {
        if (!this.aoi?.aoiId) { return ''; }
        const csvData = await fetchIntactnessCSV(this.aoi?.aoiId);
        return csvData;
    }
}
