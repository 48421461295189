
import useApp from '@/composables/state/useApp';
import {
    computed,
    defineComponent,
} from 'vue';

// import useApp from '@/composables/state/useApp';

export default defineComponent({
    name: 'FooterSection',

    setup() {
        const { aoi, isMenuOpen } = useApp();
        const { appInfo } = useApp();

        const cmsText = computed(() => ({
            leftFooter: appInfo.value?.cmsInfo?.leftFooter,
            rightFooter: appInfo.value?.cmsInfo?.rightFooter,
            bottomFooter: appInfo.value?.cmsInfo?.bottomFooter,
        }));
        return { aoi, isMenuOpen, appInfo, cmsText };
    }
});
