import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-416beb25"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "base-section1" }
const _hoisted_2 = { class: "section-header content-container flex flex-col lg:flex-row" }
const _hoisted_3 = { class: "content-container flex flex-col lg:flex-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionHeader = _resolveComponent("SectionHeader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", null, _toDisplayString(_ctx.methodsContent.title), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_SectionHeader, {
        class: "flex-1 lg:mr-8",
        section: _ctx.methodsContent,
        "show-title": false,
        "show-read-more": (_ctx.methodsContent?.description?.length ?? 19 ) > 500,
        "show-bottom-divider": _ctx.methodsContent.content.bottomDivider
      }, null, 8, ["section", "show-read-more", "show-bottom-divider"])
    ])
  ]))
}