import { select } from 'd3-selection';
import { arc, pie, PieArcDatum } from 'd3-shape';
import formatter, { FormatOptions } from '@dha/number-format';
import { appendIfNotExists } from '../helpers';

export type PieChartDrawOptions = {
    width: number;
    height: number;
    radius: number;
    strokeColor: string;
    strokeWidth: number;
    readingBgColor: string;
}

export type PieChartDatum = {
    color: string,
    value: number
}

function drawPieChart(svg: Element | null, data: PieChartDatum, drawOptions: PieChartDrawOptions): void {
    const { width, height, radius, strokeColor, strokeWidth } = drawOptions;

    // calculate remainder and add it to dta array
    const dataArr = [data];
    const remainder = 1 - data.value;
    dataArr.push({ color: 'transparent', value: remainder } as PieChartDatum);

    // generate pie chart
    const _pie = pie<PieChartDatum>().value((d) => d.value);

    const path = arc<PieArcDatum<PieChartDatum>>().innerRadius(0).outerRadius(radius);

    const mainSVG = select(svg ?? null)
        .attr('width', width)
        .attr('height', height);

    const arcsGroup = appendIfNotExists<SVGGElement>(mainSVG, 'g', 'arcs-group')
        .attr('transform', `translate(${width / 2},${height / 2})`)
        .selectAll<SVGPathElement, PieArcDatum<PieChartDatum>[]>('arc')
        .data(_pie(dataArr));

    arcsGroup.enter().remove()
        .append('g')
        .attr('class', 'arc')
        .append('path')
        .attr('d', path)
        .attr('fill', (d, i) => dataArr[i].color)
        .attr('stroke-width', strokeWidth)
        .attr('stroke', strokeColor);
}

function drawReading(svg: Element | null, drawOptions: PieChartDrawOptions, data: PieChartDatum, format?: FormatOptions): void {
    const rectWidth = 80;
    const rectHeight = 40;
    const { width, height, readingBgColor } = drawOptions;
    const readingContainer = appendIfNotExists<SVGGElement>(select(svg ?? null), 'g', 'reading-container');

    readingContainer
        .append('rect')
        .attr('transform', `translate(${-rectWidth / 2},${-rectHeight / 2})`)
        .attr('width', rectWidth)
        .attr('height', rectHeight)
        .attr('fill', readingBgColor)
        .attr('fill-opacity', '50%')
        .attr('x', (width / 2))
        .attr('y', (height / 2));

    readingContainer
        .append('text')
        .attr('x', '50%')
        .attr('y', '50%')
        .attr('class', 'pie-chart-reading')
        .attr('dominant-baseline', 'middle')
        .attr('text-anchor', 'middle')
        .text(formatter(data.value, format));
}

export default function draw(
    svg: Element | null | undefined,
    drawOptions: PieChartDrawOptions,
    data?: PieChartDatum,
    format?: FormatOptions
): void {
    if (!data) { return; }

    drawPieChart(svg ?? null, data, drawOptions);
    drawReading(svg ?? null, drawOptions, data, format);
}
