import { computed, Ref, ref, watchEffect, ComputedRef, watch } from 'vue';
import { loadDataFromUrl, loadMapList, getBBox } from '@/vendor/abmi-map';
import _ from 'lodash';

type MapInfo = {
    config: {
        showLegend?: boolean;
        showMapControl?: boolean;
        mapCategory: string;
        allowHideLayer: boolean;
    };
    mapCategory: string;
    mapLayers: {
        categoryId?: number;
        mapId: string;
        customMapName: string | null;
        toShow: boolean;
    }[];
};

type AoiDataData = {
    id: number;
    name: string;
    geom: unknown; // geojson
    settings: {
        border: boolean;
        color: string;
        fill: boolean;
        mask: boolean;
        opacity: number;
    }
}
type BBox = [number, number, number, number] | [number, number, number, number, number, number];
type AoiData = {
    aoiId: string;
    data: AoiDataData;
    bbox: BBox
}
export type MapAoi = {
    aoiId: number;
}
const mapInfos = ref<{[catId:string]: MapInfo}>();
let mapInfosLoader: Promise<void>;
const mapData = ref<AoiData>();
const mapDataLoaders:{[aoiId:number]: Promise<AoiData>} = {};
const getMapData = async (aoiId: string) => {
    // geojson data
    const data = await loadDataFromUrl(
        aoiId,
        true
    );
    const mapDataVal = {
        aoiId,
        data,
        bbox: getBBox(data.geom)
    };
    return mapDataVal;
};

export default function useABMIMap(aoi: Ref<MapAoi | undefined>, mapCategory?:string):
{ mapData: typeof mapData, mapInfo: ComputedRef<MapInfo | undefined>, mapExternalUrl: ComputedRef<string | undefined> } {
    if (!mapInfosLoader) {
        mapInfosLoader = (async () => {
            const mapList = await loadMapList();
            // console.log({ mapList });
            const info = _.keyBy(mapList, 'mapCategory');
            // // console.log({ mapInfos });
            // // Ensure footprint defaults to all shown
            const footprintInfo = info['human-footprint'];
            _.forEach(footprintInfo?.mapLayers, (layer) => {
                // eslint-disable-next-line no-param-reassign
                layer.toShow = true;
            });
            mapInfos.value = info;
        })();
    }

    // watch(() => aoi.value, async (newVal, oldVal) => {
    //     if (newVal == null) {
    //         mapData.value = undefined;
    //     } else if (newVal !== oldVal) {
    //         console.log('doing loader');
    //         if (!mapDataLoaders[newVal.aoiId]) {
    //             mapDataLoaders[newVal.aoiId] = getMapData(String(newVal.aoiId));
    //         }
    //         mapData.value = await mapDataLoaders[newVal.aoiId];
    //     }
    // });
    watchEffect(async () => {
        if (aoi.value) {
            mapData.value = undefined;
            if (!mapDataLoaders[aoi.value.aoiId]) {
                mapDataLoaders[aoi.value.aoiId] = getMapData(String(aoi.value.aoiId));
            }
            mapData.value = await mapDataLoaders[aoi.value.aoiId];
        }
    });
    // watch(aoi, (aoiVal) => {

    // })

    // If the component asks for a single category, set the mapInfo
    const mapInfo = computed(() => {
        if (!mapCategory || !mapInfos.value) {
            return undefined;
        }
        const mapInfoForCat = { ...mapInfos.value?.[mapCategory] };
        mapInfoForCat.config = {
            ...mapInfoForCat.config,
            showLegend: false
        };
        return mapInfoForCat;
    });

    /** get mapping portal link */
    const mapExternalUrl = computed(() => {
        if (!mapCategory || !mapInfos.value) {
            return undefined;
        }
        const mapInfoForCat = mapInfos.value?.[mapCategory];
        const aoiParam = `&aoi=${aoi?.value?.aoiId}`;
        const datasetList = mapInfoForCat.mapLayers?.reduce((prev: any, cur: { mapId: any; }) => `${prev + cur.mapId},`, '');
        const datasetParam = `&dataset=${datasetList}`;
        const maskParam = `&mask=${aoi?.value?.aoiId}`;
        return process.env.VUE_APP_MP_URL + aoiParam + datasetParam + maskParam;
    });
    return { mapData, mapInfo, mapExternalUrl };
}
