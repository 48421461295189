import * as t from 'io-ts';
import { parse } from '@dha/io-ts-parse';
import _ from 'lodash';
import type dataFetchApi from '@/services/data';
import { SppHighlightCountType } from '@/model/Area';

const RegionTS = t.type({
    regionId: t.number,
    name: t.string,
    aoiCount: t.number
});
export type RegionIO = t.TypeOf<typeof RegionTS>;

const RegionsCategoryTS = t.type({
    id: t.number,
    name: t.string,
    regions: t.array(RegionTS)
});
export type RegionsCategoryIO = t.TypeOf<typeof RegionsCategoryTS>;

const AreasOfInterestTS = t.type({
    geojsonUrlPrefix: t.string,
    data: t.array(RegionsCategoryTS)
});
export type AreasOfInterestIO = t.TypeOf<typeof AreasOfInterestTS>;

const AreaSectionTS = t.type({
    id: t.number,
    key: t.string,
    isAvailable: t.boolean
});

export type AreaSectionIO = t.TypeOf<typeof AreaSectionTS>;

const UpperCharTS = t.keyof({
    A: null,
    B: null,
    C: null,
    D: null,
    E: null,
    F: null,
    G: null,
    H: null,
    I: null,
    J: null,
    K: null,
    L: null,
    M: null,
    N: null,
    O: null,
    P: null,
    Q: null,
    R: null,
    S: null,
    T: null,
    U: null,
    V: null,
    W: null,
    X: null,
    Y: null,
    Z: null
});
export type UpperChar = t.TypeOf<typeof UpperCharTS>;
// export type UpperChar = 'A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G' | 'H' | 'I'
//  | 'J' | 'K' | 'L' | 'M' | 'N' | 'O' | 'P' | 'Q' | 'R' | 'S' | 'T' | 'U'
//  | 'V' | 'W' | 'X' | 'Y' | 'Z';

const AreaTS = t.type({
    aoiId: t.number,
    // region: t.union([RegionTS, t.undefined]),
    aoiName: t.string,
    regionType: t.union([t.string, t.null, t.undefined]),
    regionTypeCode: t.union([t.string, t.null, t.undefined]),
    sections: t.array(AreaSectionTS),
    initial: t.union([UpperCharTS, t.null])
});
const SubRegionsTS = t.partial({
    subregions: t.array(AreaTS)
});
const QueriedAreaTS = t.type({
    aoiId: t.number,
    aoiName: t.string,
    categoryId: t.number,
    categoryName: t.string,
    regionId: t.number,
    regionName: t.string
});
export type QueriedAreaIO = t.TypeOf<typeof QueriedAreaTS>

// If area has 'subregions' it is a subregion area
const SubRegionAreaTS = t.intersection([AreaTS, SubRegionsTS]);

export type AreaWithSubRegionsIO = t.TypeOf<typeof SubRegionAreaTS>;

export type AreaIO = t.TypeOf<typeof AreaTS> & {
    subRegion?: AreaIO | undefined,
    region: RegionIO,
    sppHighlightCount?: SppHighlightCountType[],
};

/**
 * AreaOfAnalysis contains an unknown list of key values that come from the API
 * that can be substituted into the entire report
 */
export type AreaOfAnalysisIO = {
    [x:string]: number | string | null;
};

const AreasAndSubRegionsTS = t.array(SubRegionAreaTS);

type DataApi = Pick<typeof dataFetchApi, 'fetchAreas' | 'fetchAreasOfInterest' | 'fetchQueriedAreas' | 'fetchAreaOfAnalysis'>;

type AoiApi = {
    fetchAreasOfInterest: () => Promise<AreasOfInterestIO>;
    fetchAreas: (region: RegionIO) => Promise<AreaIO []>;
    fetchQueriedAreas: (query: string) => Promise<QueriedAreaIO []>;
    fetchAreaOfAnalysis: (aoiId: number) => Promise<AreaOfAnalysisIO>;
}

export default (data: DataApi):AoiApi => ({
    fetchAreaOfAnalysis: async (aoiId: number): Promise<AreaOfAnalysisIO> => {
        const apiData = await data.fetchAreaOfAnalysis(aoiId);
        const parsedData = apiData as AreaOfAnalysisIO;
        return parsedData;
    },
    fetchAreasOfInterest: async (): Promise<AreasOfInterestIO> => {
        const apiData = await data.fetchAreasOfInterest();
        // const parsedData = parse(AreasOfInterestTS, apiData);
        return apiData as AreasOfInterestIO;
    },
    fetchAreas: async (regionIO: RegionIO): Promise<AreaIO []> => {
        const apiData = await data.fetchAreas(regionIO.regionId);
        const parsedData = parse(AreasAndSubRegionsTS, apiData);
        // Maps each area to a region.
        // Also, if there are subregions, maps the subregion as well.
        const allAreas = _.map(parsedData, (area) => {
            const areaWithRegion = {
                ...area,
                region: regionIO
            };
            if (area.subregions) {
                const subAreas = _.map(area.subregions, (subArea) => ({
                    ...subArea,
                    regionType: area.regionType,
                    regionTypeCode: area.regionTypeCode,
                    region: regionIO,
                    subRegion: areaWithRegion
                }));
                return [
                    {
                        ...areaWithRegion,
                        subRegion: areaWithRegion
                    },
                    ...subAreas
                ];
            }
            return areaWithRegion;
        });
        let allFlattened:Array<AreaIO> = [];
        _.forEach(allAreas, (areas) => {
            allFlattened = _.concat(allFlattened, areas);
        });
        return allFlattened;
    },
    fetchQueriedAreas: async (query: string): Promise<QueriedAreaIO []> => {
        const apiData = await data.fetchQueriedAreas(query);
        // results come back with name and one of menuId (categoryId), regionId and aoiId.
        // As well as a path array that contains the category then regions it's in, in that order
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const parsedData: QueriedAreaIO[] = apiData.map((d: any) => ({
            categoryId: d.menuId || d.path[0]?.menuId, // item is a category, or get the categoryId from the path array
            categoryName: d.menuId ? d.name : d.path[0]?.name,
            regionId: d.regionId || d.path.findLast((x: any) => x.regionId != null)?.regionId, // item is a region, or get the regionId from the path array
            regionName: d.regionId ? d.name : d.path[d.path.length - 1]?.name,
            aoiId: d.aoiId || undefined, // item is an area, or leave it undefined
            aoiName: d.aoiId ? d.name : undefined,
        }));
        return parsedData;
    },
});
