import { ContentIO, ConfigIO, ContentKeys } from '@/api/report';
import _ from 'lodash';

// Base Content element.  Everything in the document needs an id.
export class Element implements ContentIO {
    id : ContentKeys;
    config: ConfigIO;
    constructor(contentIo:ContentIO, config: ConfigIO) {
        Object.assign(this, contentIo);
        this.id = contentIo?.id;
        this.config = config;
    }
}

// Sections show up in TOC, Menu, etc, must have title, can have descriptions
export class Section extends Element {
    title: string;
    hideFromMenu?: boolean;
    description: string | undefined;
    descriptionAlternative: string | undefined;
    imageSrc?: string;
    imageCredit?: string;
    bottomDivider?: boolean;
    constructor(contentIo:ContentIO, config:ConfigIO) {
        super(contentIo, config);
        this.title = contentIo?.title || this.id;
        Object.assign(this, contentIo);
    }
}

export class Chapter extends Section {
    sections? : Section[] = [];
    constructor(contentIo:ContentIO, config:ConfigIO) {
        super(contentIo, config);
        this.imageSrc = contentIo?.imageSrc;
    }
}

export class Report {
    title: string;
    area?: string;
    region?: string;
    regionType?: string;
    sections: Section[] = [];
    terms?: Section;

    constructor(title: string, area?: string, region?: string, regionType?: string) {
        this.title = title;
        this.area = area;
        this.region = region;
        this.regionType = regionType || '';
    }
    get subtitle(): string | undefined {
        return this.region;
    }
    getSectionById(id: string): Section | undefined {
        if (!id || !this.sections) {
            return undefined;
        }
        return _.keyBy(this.sequenced, 'id')[id];
    }
    // Sequences the Top and 2nd level sections in order
    get sequenced(): Section[] {
        const sortedSections: Array<Section | Section[]> = [];
        _.forEach(this.sections, (section) => {
            sortedSections.push(section);
            if (section instanceof Chapter) {
                const ch = section as Chapter;
                if (ch.sections) {
                    sortedSections.push(ch.sections);
                }
            }
        });
        return _.flatten(sortedSections);
    }
}
