import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-32d76aea"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_species_callout_individual = _resolveComponent("species-callout-individual")!
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!

  return (_ctx.isShowSlider)
    ? (_openBlock(), _createBlock(_component_swiper, {
        key: 0,
        "slides-per-view": 3,
        "space-between": 50,
        "center-insufficient-slides": true,
        "hash-navigation": {
            watchState: true,
        },
        pagination: {
            clickable: true,
        },
        navigation: true,
        modules: _ctx.modules
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.speciesInfo, (slide, index) => {
            return (_openBlock(), _createBlock(_component_swiper_slide, { key: index }, {
              default: _withCtx(() => [
                _createVNode(_component_species_callout_individual, {
                  "species-callout": slide,
                  category: _ctx.category
                }, null, 8, ["species-callout", "category"])
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }, 8, ["modules"]))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.speciesInfo, (slide, index) => {
          return (_openBlock(), _createBlock(_component_species_callout_individual, {
            key: index,
            "species-callout": slide,
            category: _ctx.category
          }, null, 8, ["species-callout", "category"]))
        }), 128))
      ]))
}