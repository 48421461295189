import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6dd369c2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "base-section" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionHeader = _resolveComponent("SectionHeader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SectionHeader, {
      class: "content-container",
      section: _ctx.section,
      "is-chapter-title": _ctx.isChapterTitle
    }, null, 8, ["section", "is-chapter-title"])
  ]))
}