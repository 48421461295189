import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5f98e044"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "base-chapter" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChapterTitlePage = _resolveComponent("ChapterTitlePage")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ChapterTitlePage, {
      section: _ctx.section,
      "is-chapter-title": true
    }, null, 8, ["section"])
  ]))
}