
import _ from 'lodash';
import { select } from 'd3-selection';
import { computed, defineComponent, PropType, ref, watch, watchEffect } from 'vue';
import { FormatOptions } from '@dha/number-format';
import Search from '@/components/Search/Search.vue';
import observeResize from '@/composables/util/observeResize';
import chevron from './assets/chevron-down-small.svg';
import { draw, DEFAULT_DRAW_OPTIONS, highlightRows } from './draw';
import { getNLowestAndHighest, mustInclude } from './helpers';
import { BaseValue, IconLegendValue } from './types';

const EXPANDER_SPACE = 40;

export default defineComponent({
    name: 'DotPlot',
    components: {
        Search,
    },
    props: {
        data: {
            type: Array as PropType<BaseValue[]>,
            default: () => []
        },
        filterGuildId: {
            type: Number,
            default: null
        },
        rowHeight: {
            type: Number,
            default: DEFAULT_DRAW_OPTIONS.rowHeight
        },
        rowGap: {
            type: Number,
            default: DEFAULT_DRAW_OPTIONS.rowGap
        },
        maxCollapsedRows: {
            type: Number,
            default: 145
        },
        maxExpandedRows: {
            type: Number,
            default: 38
        },
        negativeColor: {
            type: String,
            default: DEFAULT_DRAW_OPTIONS.negativeColor
        },
        positiveColor: {
            type: String,
            default: DEFAULT_DRAW_OPTIONS.positiveColor
        },
        negativeText: {
            type: String,
            default: DEFAULT_DRAW_OPTIONS.negativeText
        },
        positiveText: {
            type: String,
            default: DEFAULT_DRAW_OPTIONS.positiveText
        },
        valueHeader: {
            type: String,
            default: DEFAULT_DRAW_OPTIONS.valueHeader
        },
        searchPlaceholder: {
            type: String,
            default: 'Search'
        },
        expandText: {
            type: String,
            default: 'Expand'
        },
        collapseText: {
            type: String,
            default: 'Collapse'
        },
        legend: {
            type: Array as PropType<IconLegendValue[]>,
            default: () => []
        },
        format: {
            type: Object as PropType<FormatOptions>,
            default: null
        },
        searched: {
            type: Object as PropType<BaseValue>,
            default: null
        },
        onClickEvent: {
            type: Function,
            default: () => {}
        }
    },
    emits: ['search', 'updateGuild'],
    setup(props, { emit }) {
        const axis = ref<SVGElement>();
        const svgContainer = ref<HTMLDivElement>();
        const svg = ref<SVGElement>();
        const scroll = ref<HTMLDivElement>();
        const search = ref<typeof Search>();
        const expanded = ref(props.data.length < props.maxExpandedRows);
        const count = ref(0);
        const onClickExpander = () => {
            expanded.value = !expanded.value;
            if (!expanded.value) {
                /* eslint-disable-next-line no-unused-expressions */
                scroll.value?.scrollTo(0, 0);
            }
            count.value += 1;
        };

        // setup resize observers/computed sizes
        const {
            dimensions: svgContainerDimensions
        } = observeResize(svgContainer);

        const svgWidth = computed(() => svgContainerDimensions.value.width);
        // the number of rows rendered (whether or not they're on screen)
        const numRows = computed(() => (expanded.value ? props.data.length : props.maxCollapsedRows));
        // the height of all rows put together (whether or not they're on screen)
        const rowsHeight = computed(() => props.data.length * (props.rowHeight + props.rowGap));
        // the height actually allotted to rows on screen
        const svgHeight = computed(() => {
            const rowsShowing = expanded.value
                ? Math.min(Math.max(1, props.data.length), props.maxExpandedRows)
                : Math.min(Math.max(1, props.data.length), props.maxCollapsedRows);
            const extraSpace = expanded.value ? 0 : EXPANDER_SPACE;
            return rowsShowing * (props.rowHeight + props.rowGap) + extraSpace;
        });
        const rowGapTranslate = `translate(0, ${props.rowGap / 2}px)`;

        // setup row slot resize
        const rowsWidth = ref(0);
        const onRowsResize = (rowsDimensions: { width: number; height: number; }) => {
            rowsWidth.value = rowsDimensions.width;
        };

        // set up data/search bar
        const sortedData = computed(() => _.sortBy(props.data, 'value'));
        const onSearch = (value: string) => {
            emit('search', sortedData.value, value);
        };
        const filterGuild = (value: number | null) => {
            scroll.value?.scrollIntoView({ behavior: 'smooth' });
            emit('updateGuild', value);
        };
        const selectedData = computed(() => {
            const selected = getNLowestAndHighest(sortedData.value, numRows.value);
            if (props.searched) {
                return mustInclude(selected, props.searched);
            }
            return selected;
        });
        // the height of all rendered rows put together (whether or not they're on screen)
        const selectedRowsHeight = computed(() => selectedData.value.length * (props.rowHeight + props.rowGap));
        watch(() => props.data.length, () => {
            expanded.value = props.data.length <= props.maxExpandedRows;
        });
        // scroll to searched item
        watch(() => props.searched, value => {
            if (value && scroll.value) {
                const rowEl = select(scroll.value)
                    .selectAll<HTMLDivElement, null>('div.row-overlay')
                    .filter((d, i, els) => select(els[i]).attr('data-name') === value.name).nodes()[0];
                if (rowEl) {
                    const scrollBox = scroll.value.getBoundingClientRect();
                    // keep scroll Y inside allowable bounds since browsers apparently don't
                    const clampedScroll = Math.max(
                        0,
                        Math.min(
                            rowEl.offsetTop - scrollBox.height / 2, // actual target Y value
                            selectedRowsHeight.value - scrollBox.height // largest possible scroll value
                        )
                    );
                    scroll.value.scrollTo({
                        top: clampedScroll,
                        behavior: 'smooth'
                    });
                }
            }
        });

        // draw on data update
        watchEffect(() => {
            draw(
                svg.value,
                axis.value,
                selectedData.value,
                {
                    rowGap: props.rowGap,
                    rowHeight: props.rowHeight,
                    axisX: rowsWidth.value,
                    width: svgWidth.value,
                    height: expanded.value ? rowsHeight.value : svgHeight.value,
                    negativeColor: props.negativeColor,
                    positiveColor: props.positiveColor,
                    negativeText: props.negativeText,
                    positiveText: props.positiveText,
                    valueHeader: props.valueHeader,
                    format: props.format
                }
            );
        }, { flush: 'post' });

        // setup row hover
        const hoveredDatum = ref<BaseValue>();
        const onMouseOver = (d: BaseValue) => {
            hoveredDatum.value = d;
        };
        const onMouseLeave = () => {
            hoveredDatum.value = undefined;
        };

        const onClick = (d:any) => {
            // eslint-disable-next-line no-console
            props.onClickEvent(d.scientificName);
        };
        watchEffect(() => {
            highlightRows(
                svg.value,
                [hoveredDatum.value?.name, props.searched?.name]
            );
        }, { flush: 'post' });

        const focusSearch = () => {
            search.value?.focus();
        };

        return {
            // template refs
            axis,
            svgContainer,
            svg,
            scroll,

            // expander
            expanded,
            onClickExpander,

            // resize
            svgWidth,
            svgHeight,
            rowsWidth,
            rowGapTranslate,
            onRowsResize,

            // data
            sortedData,
            selectedData,

            // search
            search,
            onSearch,
            focusSearch,
            filterGuild,

            // hover
            hoveredDatum,
            onMouseOver,
            onMouseLeave,
            onClick,

            count,
        };
    },
    data() {
        return {
            chevron,
            spacerHeight: DEFAULT_DRAW_OPTIONS.axisSpace,
            expanderSpace: EXPANDER_SPACE
        };
    }
});
