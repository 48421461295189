import { Area, Region, ABMIGeo } from '@/model/Area';
import { ref, watchEffect, reactive, Ref, computed } from 'vue';
import { json } from 'd3-fetch';

import useApp from '@/composables/state/useApp';

export class RegionMap {
    areaMap?:ABMIGeo;
    subregionMap?:ABMIGeo;
    regionMap?:ABMIGeo;
    region: Region;
    subregion?: Area;
    area?: Area;
    constructor(region: Region, area?:Area) {
        this.region = region;
        this.area = area;
    }
    async loadRegion(): Promise<void> {
        try {
            this.regionMap = await json(this.region.geojsonUrl);
            this.loadArea(undefined);
        } catch (e) {
            console.warn(`Error fetching geojson for region ${this.region.regionId}-${this.region.name}${this.region.geojsonUrl}`, e.message);
            console.warn(e);
        }
    }
    async loadArea(area: Area | undefined): Promise<void> {
        this.area = area;
        try {
            if (this.area) {
                this.areaMap = await json(this.area.geojsonUrl);
            } else {
                this.areaMap = undefined;
            }
        } catch (e) {
            console.warn(`Error fetching geojson for aoi ${this.area?.id}-${this.area?.name}${this.area?.geojsonUrl}`, e.message);
            console.warn(e);
        }
    }
    async loadSubregion(area: Area | undefined): Promise<void> {
        this.subregion = area?.subRegion;
        try {
            if (this.subregion) {
                this.subregionMap = await json(this.subregion?.subregionGeojsonUrl);
            } else {
                this.subregionMap = undefined;
            }
        } catch (e) {
            console.warn(`Error fetching geojson for aoi ${this.subregion?.id}-${this.subregion?.name}${this.subregion?.subregionGeojsonUrl}`, e.message);
            console.warn(e);
        }
    }
}
class RegionMaps {
    static maps: Record<number, RegionMap> = {};
    static async getRegionMap(region: Region | undefined):Promise<RegionMap | undefined> {
        if (!region?.regionId) {
            return undefined;
        }
        if (RegionMaps.maps[region.regionId]) {
            return RegionMaps.maps[region.regionId];
        }
        const map = new RegionMap(region);

        RegionMaps.maps[region.regionId] = map;
        return map;
    }
}
type ProvinceMapRefs = {provinceMap:Ref<RegionMap | undefined>}
export function useProvinceMap():ProvinceMapRefs {
    const { aois } = useApp();
    const provinceMap = ref<RegionMap>();
    const province = computed<Region | undefined>(() => aois.value?.getRegion(2));

    const loadProvinceFeatures = async () => {
        const map = await RegionMaps.getRegionMap(province.value);
        if (map) {
            provinceMap.value = reactive(map);
            await provinceMap.value.loadRegion();
        } else {
            provinceMap.value = undefined;
        }
    };
    watchEffect(() => loadProvinceFeatures());

    return { provinceMap };
}

type RegionMapRefs = {region:Ref<Region | undefined>, regionMap: Ref<RegionMap | undefined>}

export default function useRegionMap(region: Ref<Region | undefined>, area: Ref<Area | undefined>):RegionMapRefs {
    const regionMap = ref<RegionMap>();
    const loadAreaFeature = async (regionMapToLoad: RegionMap | undefined, areaToLoad: Area | undefined) => {
        if (regionMapToLoad) {
            regionMapToLoad.loadArea(areaToLoad);
        }
    };
    const loadSubregionFeatures = async (regionMapToLoad: RegionMap | undefined, subregionToLoad: Area | undefined) => {
        if (regionMapToLoad) {
            regionMapToLoad.loadSubregion(subregionToLoad);
        }
    };
    const loadRegionFeatures = async (regionToLoad: Region | undefined) => {
        const map = await RegionMaps.getRegionMap(regionToLoad);
        if (map) {
            regionMap.value = reactive(map);
            regionMap.value.loadRegion();
        } else {
            regionMap.value = undefined;
        }
    };
    watchEffect(() => loadRegionFeatures(region.value));
    watchEffect(() => loadAreaFeature(regionMap.value, area.value));
    watchEffect(() => loadSubregionFeatures(regionMap.value, area.value));

    return {
        region,
        regionMap
    };
}
