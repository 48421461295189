/**
 * Interface to teh API to fetch Data and Content.
 * Injected with a data service, the data is fetched from teh service, validates and transformed to
 * API data types.
 */
import fetchData from '@/services/data';

import AoiApi from '@/api/aoi';
import FootprintApi from '@/api/footprint';
import IntactnessApi from '@/api/intactness';
import ReportApi from '@/api/report';
import WetlandApi from '@/api/wetland';

type API =
    ReturnType<typeof AoiApi> &
    ReturnType<typeof FootprintApi> &
    ReturnType<typeof WetlandApi> &
    ReturnType<typeof IntactnessApi> &
    ReturnType<typeof ReportApi>;

export function create(data = fetchData): API {
    // Content comes from Report API, called once
    const { config, getContent } = ReportApi();
    // All other APIs are data api async functions.
    const { fetchAreasOfInterest, fetchAreas, fetchQueriedAreas, fetchAreaOfAnalysis } = AoiApi(data);

    const { fetchFootprintTrend, fetchFootprintTrendCSV, fetchFootprintChange } = FootprintApi(data);
    const { fetchWetlandStatus } = WetlandApi(data);
    const { fetchSuitabilityData, fetchIntactnessIndex, fetchSpeciesInfo, fetchTaxonomicGroups, fetchIntactnessCSV } = IntactnessApi(data);
    // Content retrieved upfront as singleton.

    return {
        fetchSpeciesInfo,
        fetchFootprintChange,
        fetchWetlandStatus,
        fetchAreaOfAnalysis,
        fetchAreasOfInterest,
        fetchTaxonomicGroups,
        fetchAreas,
        fetchQueriedAreas,
        fetchSuitabilityData,
        fetchFootprintTrend,
        fetchFootprintTrendCSV,
        fetchIntactnessIndex,
        fetchIntactnessCSV,
        getContent,
        config
    };
}

const defaultApi = create();

const {
    fetchSpeciesInfo,
    fetchFootprintChange,
    fetchWetlandStatus,
    fetchAreaOfAnalysis,
    fetchAreasOfInterest,
    fetchTaxonomicGroups,
    fetchAreas,
    fetchQueriedAreas,
    fetchSuitabilityData,
    fetchFootprintTrend,
    fetchFootprintTrendCSV,
    fetchIntactnessIndex,
    fetchIntactnessCSV,
    getContent,
    config
} = defaultApi;
export {
    fetchSpeciesInfo,
    fetchFootprintChange,
    fetchWetlandStatus,
    fetchAreaOfAnalysis,
    fetchAreasOfInterest,
    fetchTaxonomicGroups,
    fetchAreas,
    fetchQueriedAreas,
    fetchSuitabilityData,
    fetchFootprintTrend,
    fetchFootprintTrendCSV,
    fetchIntactnessCSV,
    fetchIntactnessIndex,
    getContent,
    config
};
export default defaultApi;
