
/*
use this to close menu
@click="setSelectedCategory( (selectedCategory && category.id === selectedCategory.id) ? 0 : category.id)"

However need to consider it sub menu close. so leave it for now.
*/
import { defineComponent, watch } from 'vue';
import useApp from '@/composables/state/useApp';
import useRegionCategories from '@/composables/state/areas/useRegionCategories';
import { Region, RegionsCategory } from '@/model/Area';

export default defineComponent({
    name: 'RegionCategories',
    setup() {
        const { region, selectRegion, toggleAreaExplorer, toggleMenu } = useApp();
        const { categories, selectedCategory, setSelectedCategory } = useRegionCategories();

        /*
            ABMI: when initalize, display the first category
            however when users start to manually open and close the category, don't display the default first category
            instead, don't display any category
        */
        const initSelectedCategory = (newVal?:any, oldVal?:any) => {
            if (!categories.value?.length) {
                return;
            }

            const foundCategory = categories.value?.find((i) => i.regions.find((j) => j.regionId === region.value?.regionId));
            if ((!newVal || !newVal[0]) && (!oldVal || !oldVal[0])) { // when both new region and old region are null, at initial state
                setSelectedCategory(foundCategory?.id || categories.value[0].category.id);
            } else {
                setSelectedCategory(foundCategory?.id || undefined);
            }
        };

        initSelectedCategory();
        watch([region, categories], initSelectedCategory);

        const regionClicked = (selectedRegion: Region) => {
            toggleMenu(false);
            toggleAreaExplorer(true);
            selectRegion(selectedRegion);
        };

        const setSelectedCategoryTogggle = (selectCategoryId: number) => {
            // when click an already opend region, close it
            if (selectCategoryId === selectedCategory.value?.id) {
                setSelectedCategory(undefined);
                toggleAreaExplorer(false);
                selectRegion(undefined);
            } else {
                setSelectedCategory(selectCategoryId);
            }
        };
        return {
            selectedRegion: region,
            categories,
            selectedCategory,
            setSelectedCategoryTogggle,
            regionClicked
        };
    },
});
