
import { defineComponent, inject, computed } from 'vue';
import { ContentKeys } from '@/api/report';
// import reportState from '@/composables/state/report/OldReportState';
import ReportMenu from '@/modules/onlinereport/ReportMenu.vue';
import useApp from '@/composables/state/useApp';
import useReport from '@/composables/state/report/useReport';
import type { Section } from '@/model/report';
import _ from 'lodash';
import { taxonomies } from '@/api/intactness';
import ScrollableSection from './ScrollableSection.vue';
import ReportTitlePage from '../report/ReportTitlePage.vue';
import ChapterBottomSection from '../report/ChapterBottomSection.vue';
import FooterSection from '../report/FooterSection.vue';

export default defineComponent({
    name: 'ScrollReport',
    components: {
        ScrollableSection,
        ReportTitlePage,
        ReportMenu,
        ChapterBottomSection,
        FooterSection
    },
    props: {
        togglePopup: {
            type: Function, default: null
        },
    },
    setup() {
        const { aoi, isMenuOpen } = useApp();
        const { report } = useReport(aoi);

        /**
         * This is where silhouettes are set.
         * The specific classes are in ./ScrollableSection.vue
         *
         * banner background color is based on the previous section color
         */
        const sectionCssClassArray:Array<[ContentKeys, string]> = [
            ['area', 'area divide banner region-curve-top region-type white'],
            ['footprint', 'divide banner region-type curves top image-bg white'],
            ['footprintStatus', 'divide curves top image-bg white'],
            ['footprintMapped', 'divide curves top grey mt-10'],
            ['footprintTrends', 'divide curves top white'],
            ['wetland', 'banner region-type divide curves top image-bg'],
            ['wetlandStatus', 'divide image-bg white'],
            ['wetlandMapped', 'divide image-bg white'],
            ['introductionIntactness', 'report-section banner region-type divide curves top image-bg white'],
            ['overallIntactness', 'divide curves top white'],
            ['overallIntactnessMapped', 'divide curves top grey'],
            ['overallSuitability', 'divide curves top white'],
            ['birds', 'divide banner region-type curves top image-bg white'],
            ['birdsIntactness', 'divide white'],
            ['birdsSuitability', 'divide curves top grey'],
            ['birdsSpecies', 'divide curves top white'],
            ['mammals', 'divide banner region-type curves top image-bg white'],
            ['mammalsIntactness', 'divide white'],
            ['mammalsSuitability', 'divide curves top grey'],
            ['mammalsSpecies', 'divide curves top white'],
            ['vplants', 'divide banner region-type curves top image-bg white'],
            ['vplantsIntactness', 'divide white'],
            ['vplantsSuitability', 'divide curves top grey'],
            ['vplantsSpecies', 'divide curves top white'],
            ['methods', 'method divide curves top grey'], /* add region type for hyperlink colors */
            ['reference', 'divide curves top white pb-inside-10 pt-10'],
            ['citation', 'divide curves top grey pt-10'],
        ];
        /* when no species highlight section exists, chagne the bg color of the following section, otherwise the curves don't show well */
        const sppHighlightCount = _.keyBy(aoi.value?.sppHighlightCount, 'taxonomy');
        ([...taxonomies, 'methods']).forEach((taxonomy) => {
            if ((sppHighlightCount[taxonomy]?.count ?? 0) === 0) {
                let sectionIndex = sectionCssClassArray.findIndex(css => css[0] === `${taxonomy}Species`);

                if (sectionIndex !== -1) {
                    if (taxonomy === 'vplants') { /* this should be for the one after vascular platn, methods */
                        for (; sectionIndex < sectionCssClassArray.length - 1; sectionIndex++) { // switch colors for all section followed.
                            if (sectionCssClassArray[sectionIndex + 1][1].includes('white')) {
                                sectionCssClassArray[sectionIndex + 1][1] = sectionCssClassArray[sectionIndex + 1][1].replace('white', 'grey');
                            } else {
                                sectionCssClassArray[sectionIndex + 1][1] = sectionCssClassArray[sectionIndex + 1][1].replace('grey', 'white');
                            }
                        }
                    } else { // for mammal, vp, just change the image banner top to flat
                        sectionCssClassArray[sectionIndex + 1][1] = sectionCssClassArray[sectionIndex + 1][1].replace('top', '');
                    }
                }
            }
        });

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const screens: any = inject('screens');
        const lg = computed(() => screens.w >= 1024);
        return { report, aoi, lg, isMenuOpen, sectionCssClassArray };
    },
    methods: {
        getSectionClasses(section: Section) {
            if (section.id.startsWith('subMethod')) { // not ideal, but jsut hard coded here.
                /* set submethod bg color to match following method section */
                const sectionIndex = this.sectionCssClassArray.findIndex(css => css[0] === 'methods');
                const isWhite = this.sectionCssClassArray[sectionIndex][1].includes('white');
                return `report-section method divide ${isWhite ? 'white' : 'grey'} -mt-8`;
            }
            const details = this.sectionCssClassArray.find((x) => x[0] === section.id);
            if (details) {
                if (section.id === 'wetland') {
                    const className = !this.aoi?.hasHfTrend ? ' grey' : ' white';
                    if (!details[1].includes(className)) {
                        details[1] += className;
                    }
                }
                return details[1].replace('region-type', this.aoi?.regionTypeCode ?? 'default');
            }
            return null;
        },
    }
});
