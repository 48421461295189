import { IntactnessCategory } from '@/api/intactness';

import { ContentIO, IntactnessConfigIO } from '@/api/report';

import { Section } from './Content';

export default class IntactnessContent extends Section {
    category:IntactnessCategory;
    config:IntactnessConfigIO;
    constructor(contentIo:ContentIO, config:IntactnessConfigIO, category:IntactnessCategory) {
        super(contentIo, config);
        this.category = category;
        this.config = config;
    }
}
